import React from "react";
import Modal from "react-modal";
import "../../styles/modal.less";
import deprecated from "../deprecated";

const ThanksModal = ({ thanksModalIsOpen, closeThanksModal, pricing_thanks }) => (
    <Modal
        isOpen={thanksModalIsOpen}
        onRequestClose={closeThanksModal}
        contentLabel="Example Modal"
        className="modal"
        overlayClassName="overlay"
    >
        <button data-qa="thanks-modal-close-icon" className="modal__close" type="button" onClick={closeThanksModal}>
            X
        </button>
        <div data-qa="thanks-modal" className="pricing__thanks">
            {pricing_thanks}
        </div>
    </Modal>
);

export default deprecated(ThanksModal);
