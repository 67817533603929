import React from "react";
import PropTypes from "prop-types";
import { componentServices } from "@resourcehub/resourcehub-services";
import { fieldTypes } from "../../component-proptypes";

const FirstName = ({ dataQa, error, data, handleInputChange, label }) => (
    <div className={componentServices.createFieldClasses(error, data)}>
        <input
            data-qa={dataQa}
            id="given-name"
            onChange={handleInputChange}
            value={data}
            autoComplete="given-name"
            type="text"
            name="first_name"
            required
            maxLength="255"
        />
        <label data-qa={`${dataQa}-label`} htmlFor="given-name">
            {label}
        </label>
    </div>
);

FirstName.propTypes = {
    ...fieldTypes,
    label: PropTypes.string.isRequired
};

export default FirstName;
