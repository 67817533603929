import React from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";

const BodyWrapperStyled = styled.div`
    label: BodyWrapperStyled;

    align-self: center;
    font-family: effra, sans-serif;
    letter-spacing: 0.2px;
    position: relative;

    & p {
        padding-bottom: 20px;
    }
    & em {
        font-style: italic;
    }
    & strong {
        font-weight: bolder;
    }
    & a {
        color: #0098db;
    }
    & ul {
        padding-bottom: 20px;
        li {
            margin-left: 25px;
            list-style-type: disc;
        }
    }

    ${({ theme }) => {
        switch (theme) {
            case "black":
                return `
                    color: #262626;
                    a {
                        color: #0098db; 
                    }
                `;
            case "white":
                return `
                    color: #ffffff;
                    a {
                        color: #ffffff; 
                    }
                `;
        }
    }};

    ${({ align }) => {
        switch (align) {
            case "left":
                return "text-align: left;";
            case "center":
                return "text-align: center;";
            case "right":
                return "text-align: right;";
        }
    }};

    ${({ type }) => {
        switch (type) {
            case "card":
                return `
                    font-size: 12pt; 
                    font-weight: lighter;
                    line-height: 24px;
                    padding: 5px;
                `;
            case "page":
                return `
                    font-size: 12pt; 
                    font-weight: normal;
                    line-height: 32px;
                    padding: 20px 0;
                `;
        }
    }};
`;

const BodyWrapper = ({ align, dataQa, content, dangerous, theme, type }) => {
    if (dangerous) {
        return (
            <BodyWrapperStyled
                data-qa={"content-section-body-" + dataQa}
                align={align}
                type={type}
                theme={theme}
                dangerouslySetInnerHTML={{ __html: content }}
            />
        );
    }
    return (
        <BodyWrapperStyled align={align} data-qa={"content-section-body-" + dataQa} type={type} theme={theme}>
            {content}
        </BodyWrapperStyled>
    );
};

export const BodyWrapperPropTypes = {
    align: PropTypes.oneOf(["left", "center", "right"]),
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    dangerous: PropTypes.bool,
    theme: PropTypes.oneOf(["black", "white"]),
    type: PropTypes.oneOf(["page", "card"])
};
BodyWrapper.propTypes = BodyWrapperPropTypes;
BodyWrapper.defaultProps = {
    align: "left",
    dangerous: false,
    theme: "black",
    type: "page"
};
export default BodyWrapper;
