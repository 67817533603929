import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import Img from "gatsby-image";
import { images } from "@resourcehub/resourcehub-assets";
import Modal from "./ModalWrapper";
import deprecated from "../deprecated";

const VideoContainer = styled.div`
    width: 100%;
    height: 100%;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const VideoImage = styled(Img)`
    width: 100%;
    box-shadow: 0 3px 10px 0 rgba(33, 33, 33, 0.5);
`;

const VideoButton = styled.div`
    height: 100px;
    width: 100px;
    position: absolute;
`;

const Video = ({ title, videoSrc, thumbnailSrc, copyData = {}, vertical = "", domain = "" }) => (
    <Modal
        buttonClasses="button__wrapper"
        buttonBody={
            <VideoContainer>
                <VideoImage
                    fluid={thumbnailSrc.childImageSharp.fluid}
                    alt="video screenshot"
                    imgStyle={{ width: "auto" }}
                />
                <VideoButton style={{ backgroundImage: `url(${images.playBtnPng})` }} />
            </VideoContainer>
        }
        copyData={copyData}
        vertical={vertical}
        domain={domain}
        modalBody={<iframe title={title} src={videoSrc} />}
    />
);

export const VideoPropTypes = {
    copyData: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    vertical: PropTypes.string.isRequired,
    domain: PropTypes.string.isRequired,
    videoSrc: PropTypes.string.isRequired,
    thumbnailSrc: PropTypes.shape({
        childImageSharp: PropTypes.shape({
            fluid: PropTypes.object.isRequired
        })
    }).isRequired
};

Video.propTypes = VideoPropTypes;

Video.defaultProps = {
    copyData: {},
    vertical: "",
    domain: ""
};

export default deprecated(Video);
