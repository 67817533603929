import React from "react";
import PropTypes from "prop-types";
import { componentServices } from "@resourcehub/resourcehub-services";
import { fieldTypes } from "../../component-proptypes";

const Comments = ({ dataQa, error, data, handleInputChange, label }) => (
    <div className={componentServices.createFieldClasses(error, data)}>
        <input
            data-qa={dataQa}
            id="howHearOther"
            onChange={handleInputChange}
            value={data}
            type="text"
            name="anythingtoadd"
            maxLength="255"
        />
        <label data-qa={`${dataQa}-label`} htmlFor="anythingtoadd">
            {label}
        </label>
    </div>
);

Comments.propTypes = {
    ...fieldTypes,
    label: PropTypes.string.isRequired
};

export default Comments;
