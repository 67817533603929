import React from "react";
import { connect } from "react-redux";
import deprecated from "../deprecated";

const Lock = ({ is_gated, hasSubmitted }) => {
    if (is_gated && !hasSubmitted) {
        return (
            <span className="card__locked">
                <svg height="15" viewBox="0 0 10 15" width="10" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="m9.1835443 5.85995806h-.44232911v-1.77518031c-.00005063-2.25237992-1.67832911-4.08477775-3.74131646-4.08477775-2.06288607 0-3.74116455 1.83239783-3.74116455 4.08477775v1.77518031h-.44227848c-.45093671 0-.8164557.39908526-.8164557.8914323v7.35717734c0 .492347.36551899.8914323.8164557.8914323h8.3670886c.45093671 0 .8164557-.3990853.8164557-.8914323v-7.35717734c0-.49234704-.36556962-.8914323-.8164557-.8914323zm-3.39032911 4.65673174v1.4612303c0 .4783053-.35513924.8660576-.79321519.8660576s-.79321519-.3877523-.79321519-.8660576v-1.4612303c-.29797468-.2564561-.48951899-.65377228-.48951899-1.10017946 0-.77351446.57427848-1.40052998 1.28273418-1.40052998s1.28273418.62701552 1.28273418 1.40052998c0 .44640718-.19154431.84372336-.48951899 1.10017946zm.92263291-4.73965568h-3.43179747v-1.69225637c0-1.03306638.76972152-1.87347282 1.71589874-1.87347282.94617721 0 1.71594936.84040644 1.71594936 1.87347282v1.69225637z"
                        fill="#fff"
                    />
                </svg>
            </span>
        );
    }
    return null;
};

export default deprecated(
    connect(({ hasSubmitted }) => ({
        hasSubmitted
    }))(Lock)
);
