import React from "react";
import PropTypes from "prop-types";
import { pageSectionBorders } from "@resourcehub/resourcehub-assets";
import GateForm from "../ContactFormNew/GateForm";
import ThanksModal from "../Modal/ThanksModal";
import { domainType, verticalType } from "../../component-proptypes";
import "../../styles/modal.less";
import deprecated from "../deprecated";

class PricingSection extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            modalIsOpen: false
        };
    }

    afterSubmit = () => {
        const { afterSubmit } = this.props;
        if (afterSubmit) {
            afterSubmit();
        } else {
            this.setState({
                modalIsOpen: true
            });
        }
    };

    closeModal = () => {
        this.setState({ modalIsOpen: false });
    };

    render() {
        const {
            pricing_title,
            pricing_subtitle,
            pricing_thanks,
            domain,
            vertical,
            copyData,
            hideTopBorder,
            page = "webpage"
        } = this.props;
        const { modalIsOpen } = this.state;

        return (
            <section data-qa="on-page-request-pricing-section" className="section pricing">
                {!hideTopBorder && (
                    <img
                        src={pageSectionBorders.blueCurveTopSvg}
                        className="related__border related__borderTop"
                        alt="section border"
                    />
                )}
                <div className="pricing__body">
                    <h2 className="section__title pricing__title">{pricing_title}</h2>
                    <p className="pricing__subtitle">{pricing_subtitle}</p>
                    <div className="pricing__form">
                        <GateForm
                            dataQaLocation="on-page"
                            domain={domain}
                            vertical={vertical}
                            page={page}
                            leadSourceMostRecent1="Contact Request"
                            afterSubmit={this.afterSubmit}
                            copyData={copyData}
                        />
                    </div>
                </div>
                <ThanksModal
                    thanksModalIsOpen={modalIsOpen}
                    closeThanksModal={this.closeModal}
                    pricing_thanks={pricing_thanks}
                />
                <img
                    src={pageSectionBorders.blueCurveBottomSvg}
                    className="related__border related__borderBottom"
                    alt="section border"
                />
            </section>
        );
    }
}

PricingSection.propTypes = {
    pricing_title: PropTypes.string.isRequired,
    pricing_subtitle: PropTypes.string.isRequired,
    pricing_thanks: PropTypes.string.isRequired,
    domain: domainType.isRequired,
    vertical: verticalType.isRequired
};

export default deprecated(PricingSection);
