import React from "react";
import Img from "gatsby-image";
import { CONTENT_DOUBLE_COLUMN_BREAKPOINT } from "../../styles/style-constants";

class LazyImage extends React.Component {
    state = {
        isMobile: false
    };

    componentDidMount() {
        window.addEventListener("resize", this.resize);
        this.resize();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize);
    }

    resize = () => {
        this.setState({
            isMobile: document.body.getBoundingClientRect().width <= CONTENT_DOUBLE_COLUMN_BREAKPOINT
        });
    };

    render() {
        const { img, isStone } = this.props;
        const { isMobile } = this.state;
        const classes = isStone ? "img_stone" : "img_main";
        if (img && img.childImageSharp && img.childImageSharp.fluid && img.childImageSharp.fixed) {
            return (
                <Img
                    className={classes}
                    fluid={img.childImageSharp.fluid}
                    alt=""
                    style={{
                        overflow: "hidden",
                        width: isMobile ? img.childImageSharp.fluid.width : img.childImageSharp.fixed.width
                    }}
                    imgStyle={{ width: "auto", overflow: "hidden", objectFit: "contain" }}
                />
            );
        }
        return null;
    }
}

export default LazyImage;
