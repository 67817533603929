import React from "react";
import FormModalCTA from "../Modal/FormModalCTA";
import "../../styles/button.less";
import "../../styles/hero.less";
import ButtonStyled from "../../components/Buttons/ButtonStyled";
import deprecated from "../deprecated";

const Hero = ({
    image,
    mainHeading,
    subHeading,
    ctaText,
    hideCTA,
    domain,
    copyData,
    vertical,
    cta = null,
    trainersCorner,
    destinationURL
}) => (
    <div className="hero">
        <div className="hero__image">
            <div>{image}</div>
        </div>
        <div className="hero__copy">
            <h1 data-qa="hero-title-text">{mainHeading}</h1>
            <h2>{subHeading}</h2>
            {!hideCTA && (
                <div>
                    {trainersCorner && (
                        <a
                            className="btn btn-bg"
                            href={
                                trainersCorner === "Schools Trainers Corner"
                                    ? "https://support.rosettastone.com/en/k-12/TOTALe_PRO/Implementation-Guide/Admin-Engage-Learners#bottomOfPage"
                                    : "https://support.rosettastone.com/en/business/Catalyst2"
                            }
                            alt={
                                trainersCorner === "Schools Trainers Corner"
                                    ? "Teacher Resources"
                                    : "Catalyst Resources"
                            }
                        >
                            {trainersCorner === "Schools Trainers Corner" ? "Teacher Resources" : "Catalyst Resources"}
                        </a>
                    )}
                    {destinationURL && !trainersCorner && (
                        <ButtonStyled
                            text={ctaText}
                            size="regular"
                            theme="yellow_black"
                            destination={destinationURL}
                            new_window={false}
                        />
                    )}
                    {!destinationURL && !trainersCorner && (
                        <FormModalCTA
                            btn_classes="btn btn-bg"
                            btn_title={ctaText}
                            pricing_title={copyData.pricing_title}
                            pricing_subtitle={copyData.pricing_subtitle}
                            domain={domain}
                            vertical={vertical}
                            copyData={copyData}
                        />
                    )}
                </div>
            )}
            {cta}
        </div>
    </div>
);

export default deprecated(Hero);
