import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { verticalServices } from "@resourcehub/resourcehub-services";
import QuoteBody from "./QuoteBody";

const Quote = ({ dataQa, logo, quote, link, resourcesLink, vertical, hasCombinedResources, market }) => {
    if (!link) {
        return <QuoteBody dataQa={dataQa} logo={logo} quote={quote} />;
    }
    const linkRegex = new RegExp(`${resourcesLink}content/(.*$)`);
    const linkMatches = link.match(linkRegex);
    const isResourceHubLink = linkMatches && linkMatches[1];
    const noResourceHubForVert =
        vertical === "hed" || vertical === "pubsec" || (vertical !== "business" && hasCombinedResources);

    // prefer using gatsby link if link is to resource on hub and can use gatsby link
    if (isResourceHubLink && !noResourceHubForVert) {
        const combinedVerticalsRoute = verticalServices.getHubPrefixFromVertical(market);
        const quoteLink = `${combinedVerticalsRoute}${resourcesLink}content/${linkMatches[1]}`;
        return (
            <Link className="section__quote" to={quoteLink}>
                <QuoteBody dataQa={dataQa} logo={logo} quote={quote} />
            </Link>
        );
    }
    return (
        <a className="section__quote" href={link}>
            <QuoteBody dataQa={dataQa} logo={logo} quote={quote} />
        </a>
    );
};

Quote.propTypes = {
    quote: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    resourcesLink: PropTypes.string.isRequired
};

export default Quote;
