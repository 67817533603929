import React from "react";
import { connect } from "react-redux";
import { componentServices } from "@resourcehub/resourcehub-services";
import { leadGenerationStore } from "@resourcehub/resourcehub-data";
import ContentBackground from "./ContentBackground";
import { domainType, verticalType } from "../../component-proptypes";
import deprecated from "../deprecated";
import "../../styles/content.less";
import VideoPlayer from "../../components/Videos/VideoPlayer";
import styled from "styled-components";

const ContentWrapper = styled.div`
    .media__container {
        div {
            @media (max-width: 768px) {
                width: 100%;
            }

            iframe {
                width: 100% !important;
                left: 0;
                padding: 0;
            }
        }
    }

    @media (max-width: 600px) {
        .content__card {
            padding: 15px;
        }
    }
    @media (max-width: 470px) {
        .content__card {
            padding: 15px 10px;
        }
    }
    @media (max-width: 430px) {
        .content__card {
            padding: 0;
        }
    }
`;

const FlipbookButtonWrapper = styled.div`
    height: 8vh;
    margin: 0 auto;
    text-align: center;
`;

class ContentTemplate extends React.Component {
    state = {
        isMobile: false
    };

    componentDidMount() {
        window.addEventListener("resize", this.resize);
        this.resize();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize);
    }

    resize = () => {
        this.setState({
            isMobile: document.body.getBoundingClientRect().width <= 768
        });
    };

    renderFlipOrBtn = (content, downloadPDF) => {
        const { isMobile } = this.state;
        if (isMobile) {
            return (
                <FlipbookButtonWrapper>
                    <a href={content.media_url} download title={downloadPDF} className="btn btn-expand">
                        {downloadPDF}
                    </a>
                </FlipbookButtonWrapper>
            );
        }
        return (
            <object aria-label={content.title} className="content__flipbook" data={content.media_url}>
                <p>
                    Your browser does not support PDFs.{" "}
                    <a href={content.media_url} download title="Download the PDF">
                        Download the PDF
                    </a>
                    .
                </p>
            </object>
        );
    };

    renderFlipbook = (content, downloadPDF) => {
        const { isMobile } = this.state;
        const containerBackground = isMobile ? "none" : "url(../../static/blue-spinner.gif) center center no-repeat";
        if (content.media_url && !/youtube/.test(content.media_url)) {
            return (
                <>
                    <div
                        className="media__container media__container-flipbook"
                        style={{ background: containerBackground }}
                    >
                        {this.renderFlipOrBtn(content, downloadPDF)}
                    </div>
                </>
            );
        }
        return null;
    };

    render() {
        const { item, copyData } = this.props;
        const content = item.data;
        const { asset_type, media_url } = content;
        const isVideo = asset_type === "video" || asset_type === "webinar";
        const isFlipbook = !isVideo && media_url;
        return (
            <ContentWrapper>
                <div className="content__container" data-uberflip={content.uberflip_url}>
                    <ContentBackground />
                    <div className="content__card">
                        {isVideo && (
                            <div className="media__container">
                                <VideoPlayer
                                    controls={true}
                                    className="content__youtube"
                                    currentVideoUrl={content.media_url}
                                />
                            </div>
                        )}
                        <h1>{content.title}</h1>
                        <div
                            className="content__content"
                            dangerouslySetInnerHTML={componentServices.createMarkup(content.content)}
                        />
                        {isFlipbook && this.renderFlipbook(content, copyData.download_pdf)}
                    </div>
                </div>
            </ContentWrapper>
        );
    }
}

ContentTemplate.propTypes = {
    domain: domainType.isRequired,
    vertical: verticalType.isRequired
};

const mapStateToProps = (state) => ({
    // TODO: Move these to selectors
    hasSubmitted: state.formSubmitted
});

const ContentTemplateConnected = connect(mapStateToProps, {
    autoSubmitToEloqua: leadGenerationStore.leadGenerationActions.prepareAutoSubmitToEloqua,
    handleHardCodedFormData: leadGenerationStore.leadGenerationActions.handleHardCodedFormData
})(ContentTemplate);

export default deprecated(ContentTemplateConnected);
