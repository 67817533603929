import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import Logo from "./Logo";

const TopBorderFooterSection = styled.section`
    background: #262626;
    :before {
        content: ""; /* This is necessary for the pseudo element to work. */
        display: block; /* This will put the pseudo element on its own line. */
        margin: 0 auto; /* This will center the border. */
        width: 95%; /* Change this to whatever width you want. */
        border-bottom: 1px solid #eeeeee; /* This creates the border. Replace black with whatever color you want. */
    }
`;
const FooterSection = styled.section`
    label: FooterSection;

    box-sizing: unset;
    background: #262626;
    color: #eeeeee;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0;
    width: 100%;
`;
const ContentDiv = styled.div`
    label: ContentDiv;

    display: flex;
    max-width: 95%;
    width: 100%;
    margin: 0 auto;
    padding: 1.5em 0;
    flex-direction: column !important;
    justify-content: center;
    align-content: center;
    text-align: center;

    @media only screen and (min-width: 1025px) {
        flex-direction: row !important;
        justify-content: space-between;
        align-content: center;
        text-align: left;
    }
`;

const CopyrightSectionDiv = styled.div`
    label: CopyrightSectionDiv;

    align-items: flex-start;
    display: inline-flex;
    justify-content: center;
    font-family: effra, sans-serif;
    font-size: 13px;
    font-weight: 100;
    line-height: 15.824px;
    padding-bottom: 10px;
    padding-top: 10px;
    padding-left: 10px;

    @media only screen and (max-width: 1025px) {
        padding-left: 0;
        justify-content: flex-start;
        align-items: center;
    }
`;
const CopyrightTextDiv = styled.div`
    label: CopyrightTextDiv;
    display: flex;
    flex-direction: column;
`;
const RSCopyrightTextSpan = styled.div`
    label: RSCopyrightTextSpan;
    flex-grow: 1;
    text-align: left;
`;
const IXLCopyrightTextSpan = styled.div`
    label: IXLCopyrightTextSpan;
    text-align: left;
`;
const IXLLogoDivider = styled.div`
    label: IXLLogoDivider;
    content: "";
    margin: 0 40px;
    width: 1px;
    top: 14px;
    left: 1px;
    bottom: 0;
    height: 45px;
    background: #eee;

    @media only screen and (max-width: 1025px) {
        margin: 2px 25px 0;
        height: 40px;
    }
`;

const LegalLinksDiv = styled.div`
    label: LegalLinksDiv;

    text-align: center;
    font-family: effra, sans-serif;
    font-size: 0.9rem;
    font-weight: 100;
    line-height: 15.824px;

    @media (min-width: 1025px) {
        padding-left: 40px;
        padding-top: 10px;
    }
    @media (max-width: 1025px) {
        display: inline-flex;
        align-items: flex-start;
        flex-direction: column;
    }
`;
const LegalLinkAnchor = styled.a`
    label: LegalLinkAnchor;

    position: relative;
    background-color: rgba(0, 0, 0, 0);
    color: #eeeeee;
    cursor: pointer;
    display: inline-block;
    font-family: effra, sans-serif;
    font-size: 13px;
    font-weight: 100;
    height: 15px;
    line-height: 15.824px;
    padding: 15px 11px;
    text-align: left;
    text-decoration-color: #eeeeee;
    text-decoration-line: none;
    text-decoration-style: solid;
    white-space: nowrap;
    :before {
        position: absolute;
        content: "";
        width: 1px;
        top: 14px;
        left: 0;
        bottom: 0;
        height: 20px;
        background: #eeeeee;
    }
    &:first-of-type:before {
        width: 0;
    }
    @media (max-width: 1025px) {
        padding: 10px 60px;
        :before {
            width: 0;
        }
    }
    @media (max-width: 600px) {
        padding: 10px 35px;
    }
`;

const Meta = ({ config }) => {
    return (
        <TopBorderFooterSection>
            <FooterSection data-qa={"footer-legal-section"}>
                <ContentDiv>
                    {config.copyright.visible && (
                        <CopyrightSectionDiv>
                            {config.rs_logo.visible && <Logo dataQa="footer-legal-rs-logo" {...config.rs_logo} />}
                            <CopyrightTextDiv>
                                <RSCopyrightTextSpan data-qa={"footer-legal-copyright-date"}>
                                    ©1999-{new Date().getFullYear()}
                                </RSCopyrightTextSpan>
                                <RSCopyrightTextSpan data-qa={"footer-legal-copyright-text"}>
                                    {config.copyright.label}
                                    {config.copyright.icpLabel && (
                                        <a data-qa={"footer-icp-rs-link"} href={config.copyright.icpLink}>
                                            {config.copyright.icpLabel}
                                        </a>
                                    )}
                                </RSCopyrightTextSpan>
                                <IXLCopyrightTextSpan>{config.copyright.IXLLabel}</IXLCopyrightTextSpan>
                            </CopyrightTextDiv>
                            <IXLLogoDivider />
                            {config.ixl_logo.visible && <Logo {...config.ixl_logo} />}
                        </CopyrightSectionDiv>
                    )}
                    <LegalLinksDiv>
                        {config.ccpa.visible && <LegalLink dataQa={"footer-legal-ccpa-link"} link={config.ccpa} />}
                        {config.cookieSettings.visible && (
                            <LegalLink dataQa={"footer-legal-cookie-settings-link"} link={config.cookieSettings} />
                        )}
                        {config.agreements.visible && (
                            <LegalLink dataQa={"footer-legal-agreements-link"} link={config.agreements} />
                        )}
                        {config.termsConditions.visible && (
                            <LegalLink dataQa={"footer-legal-terms-link"} link={config.termsConditions} />
                        )}
                        {config.privacyPolicy.visible && (
                            <LegalLink dataQa={"footer-legal-privacy-link"} link={config.privacyPolicy} />
                        )}
                    </LegalLinksDiv>
                </ContentDiv>
            </FooterSection>
        </TopBorderFooterSection>
    );
};

Meta.propTypes = {
    rosetta_copyright: PropTypes.string,
    anti_piracy: PropTypes.string,
    anti_piracy_url: PropTypes.string,
    agreements: PropTypes.string,
    agreements_url: PropTypes.string,
    terms_conditions: PropTypes.string,
    terms_conditions_url: PropTypes.string,
    privacy_policy: PropTypes.string,
    privacy_policy_url: PropTypes.string
};

const LegalLink = ({ link, dataQa }) => {
    return (
        <LegalLinkAnchor
            data-qa={dataQa}
            className={link.destination === "#" ? "optanon-toggle-display" : ""}
            target={link.destination === "#" ? "" : "_blank"}
            rel="noopener noreferrer"
            href={link.destination}
            title={link.label}
        >
            {link.label}
        </LegalLinkAnchor>
    );
};

export default Meta;
