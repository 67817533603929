import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import Img from "gatsby-image";
import Link from "../Links/Link";

const ImageWrapperDiv = styled.div`
    label: ImageWrapperDiv;

    align-items: ${({ alignItems }) => alignItems};
    display: flex;
    flex-direction: column;
`;

const Image = ({
    alt,
    asset_fixed,
    asset_fluid,
    asset_static,
    height,
    object_fit,
    width,
    className,
    dataQa,
    align_items,
    destination,
    new_window
}) => {
    const imageHeight = height ? height : "100%";
    const imageWidth = width ? width : "100%";
    const withLink = destination && destination.length >= 1;

    if (withLink) {
        return (
            <ImageWrapperDiv data-qa={dataQa} className={className} alignItems={align_items}>
                <Link destination={destination} new_window={new_window}>
                    {asset_fixed && (
                        <Img
                            fixed={asset_fixed.childImageSharp.fixed}
                            alt={alt}
                            title={alt}
                            style={{
                                height: imageHeight,
                                width: imageWidth
                            }}
                            imgStyle={{ objectFit: object_fit }}
                        />
                    )}
                    {asset_fluid && (
                        <Img
                            fluid={asset_fluid.childImageSharp.fluid}
                            alt={alt}
                            title={alt}
                            objectFit={object_fit}
                            style={{
                                height: imageHeight,
                                width: imageWidth
                            }}
                            imgStyle={{ objectFit: object_fit }}
                        />
                    )}
                    {asset_static && (
                        <div style={{ overflow: "hidden" }}>
                            <img
                                src={asset_static}
                                alt={alt}
                                style={{ height: imageHeight, width: imageWidth, objectFit: "contain" }}
                            />
                        </div>
                    )}
                </Link>
            </ImageWrapperDiv>
        );
    }

    return (
        <ImageWrapperDiv data-qa={dataQa} className={className} alignItems={align_items}>
            {asset_fixed && (
                <Img
                    fixed={asset_fixed.childImageSharp.fixed}
                    alt={alt}
                    title={alt}
                    style={{
                        height: imageHeight,
                        width: imageWidth
                    }}
                    imgStyle={{ objectFit: object_fit }}
                />
            )}
            {asset_fluid && (
                <Img
                    fluid={asset_fluid.childImageSharp.fluid}
                    alt={alt}
                    title={alt}
                    objectFit={object_fit}
                    style={{
                        height: imageHeight,
                        width: imageWidth
                    }}
                    imgStyle={{ objectFit: object_fit }}
                />
            )}
            {asset_static && (
                <div style={{ overflow: "hidden" }}>
                    <img
                        src={asset_static}
                        alt={alt}
                        style={{ height: imageHeight, width: imageWidth, objectFit: "contain" }}
                    />
                </div>
            )}
        </ImageWrapperDiv>
    );
};
export const ImagePropTypes = {
    asset_static: PropTypes.string,
    asset_fixed: PropTypes.object,
    asset_fluid: PropTypes.object,
    alt: PropTypes.string,
    height: PropTypes.string,
    object_fit: PropTypes.string,
    width: PropTypes.string,
    align_items: PropTypes.string
};
Image.propTypes = ImagePropTypes;
Image.defaultProps = { object_fit: "contain", align_items: "center" };
export default Image;
