import React, { Component } from "react";

const deprecated = (InnerComponent, msg = "") =>
    class extends Component {
        componentDidMount() {
            if (process.env.NODE_ENV === "development") {
                console.warn(`${InnerComponent.name} is deprecated`, msg);
            }
        }

        render() {
            // Render the wrapped component with the same props
            return <InnerComponent {...this.props} />;
        }
    };

export default deprecated;
