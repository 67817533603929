import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { componentServices } from "@resourcehub/resourcehub-services";
import Quote from "../../components/Quotes/Quote";
import LazyImage from "../../components/Images/LazyImage";
import ButtonStyled from "../../components/Buttons/ButtonStyled";
import { CONTENT_DOUBLE_COLUMN_BREAKPOINT_PX } from "../../styles/style-constants";
import deprecated from "../deprecated";

const BgImg = styled.img`
    label: BgImg;

    @media (max-width: ${CONTENT_DOUBLE_COLUMN_BREAKPOINT_PX}) {
        width: 100%;
    }
    @media (min-width: ${CONTENT_DOUBLE_COLUMN_BREAKPOINT_PX}) {
        width: auto;
    }
`;

const ButtonWrapper = styled.div`
    label: ButtonWrapper;

    margin-top: 50px;
`;

const ContentSection = ({
    dataQa,
    body,
    content_child,
    cta_caption,
    cta_link,
    hasCombinedResources,
    img,
    img_bg,
    img_child,
    img_main,
    link,
    logo,
    mainTitle,
    market,
    quote,
    resourcesLink,
    sectionClasses,
    title,
    vertical
}) => {
    if (!title) return null; // exit if no title copy
    const titleEl = mainTitle ? (
        <h1 data-qa={`content-section-main-title-${dataQa}`} className="section__mainTitle">
            {title}
        </h1>
    ) : (
        <h2 data-qa={`content-section-title-${dataQa}`} className="section__title">
            {title}
        </h2>
    );
    return (
        <section data-qa={`content-section-header-${dataQa}`} className={`section ${sectionClasses}`}>
            <div className="section__img">
                {img_bg && <BgImg className="img_bg" src={img_bg} alt={title} />}
                <LazyImage img={img_main} overflow={"hidden"} />
                <LazyImage img={img} overflow={"hidden"} isStone />
                {img_child}
            </div>
            <div className="section__content-container">
                <div className="section__content">
                    {titleEl}
                    {componentServices.checkForMarkUp(body) ? (
                        <div className="section__body" dangerouslySetInnerHTML={componentServices.createMarkup(body)} />
                    ) : (
                        <p data-qa={`content-section-body-${dataQa}`} className="section__body">
                            {body}
                        </p>
                    )}
                    <ButtonWrapper>
                        {cta_caption && !cta_link && (
                            <p data-qa={`content-section-cta-caption-${dataQa}`} className="section__body">
                                {cta_caption}
                            </p>
                        )}
                        {cta_caption && cta_link && (
                            <ButtonStyled destination={cta_link} theme="blue_white" size="mini" text={cta_caption} />
                        )}
                    </ButtonWrapper>
                    {content_child}
                    {quote && (
                        <Quote
                            dataQa={dataQa}
                            logo={logo}
                            quote={quote}
                            link={link}
                            resourcesLink={resourcesLink}
                            vertical={vertical}
                            hasCombinedResources={hasCombinedResources}
                            market={market}
                        />
                    )}
                </div>
            </div>
        </section>
    );
};

ContentSection.propTypes = {
    resourcesLink: PropTypes.string.isRequired
};

export default deprecated(ContentSection);
