import React from "react";
import PropTypes from "prop-types";
import { fieldTypes } from "../../component-proptypes";

const Phone = ({ dataQa, data, handleInputChange, label }) => (
    <div className={`field__container ${data && "field__container--data"}`}>
        <input
            data-qa={dataQa}
            id="phone"
            onChange={handleInputChange}
            value={data}
            autoComplete="tel"
            type="tel"
            name="phone"
            maxLength="20"
            required
        />
        <label data-qa={`${dataQa}-label`} htmlFor="phone">
            {label}
        </label>
    </div>
);

Phone.propTypes = {
    ...fieldTypes,
    label: PropTypes.string.isRequired
};

export default Phone;
