import React from "react";
import { componentServices } from "@resourcehub/resourcehub-services";
import { fieldTypes } from "../../component-proptypes";

const InstitutionSize = ({ dataQa, data, error, handleInputChange, labels }) => (
    <div className={`roleContainer ${componentServices.createFieldClasses(error, data)}`}>
        <select
            data-qa={dataQa}
            id="institutionSize1"
            name="institutionSize1"
            required
            onChange={handleInputChange}
            value={data}
        >
            <option data-qa="institution-size-option-0" value="" style={{ display: "none" }} />
            <option data-qa="institution-size-option-1" value="100 or Less">
                {labels.form_100}
            </option>
            <option data-qa="institution-size-option-2" value="101 - 500">
                {labels.form_500}
            </option>
            <option data-qa="institution-size-option-3" value="501 - 1,000">
                {labels.form_1000}
            </option>
            <option data-qa="institution-size-option-4" value="1,001 - 5,000">
                {labels.form_5000}
            </option>
            <option data-qa="institution-size-option-5" value="5,001 - 10,000">
                {labels.form_10000}
            </option>
            <option data-qa="institution-size-option-6" value="10,001 - 25,000">
                {labels.form_25000}
            </option>
            <option data-qa="institution-size-option-7" value="25,001 - 50,000">
                {labels.form_50000}
            </option>
            <option data-qa="institution-size-option-8" value="50,001 - More">
                {labels.form_more}
            </option>
        </select>
        <label data-qa={`${dataQa}-label`} htmlFor="institutionSize1" aria-labelledby="institutionSize1">
            {labels.form_organizationsize}
        </label>
    </div>
);

InstitutionSize.propTypes = {
    ...fieldTypes
};

export default InstitutionSize;
