import React from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";

import LanguagesPricing from "./LanguagesPricingDesktop";
import NavChatHeader from "./ChatHeader";
import PrimaryNavLink from "./PrimaryNavLink";
import BrandLogo from "./BrandLogo";
import NavSearch from "./Search";
import SecondaryNav from "./SecondaryNav";
import MobileNav from "./MobileNav";
import zIndex from "../../styles/z-indexes";
import MobileNavToggle from "./MobileNavToggle";
import ButtonStyled from "../Buttons/ButtonStyled";
import PhoneIcon from "./PhoneIcon";
import { NAV_TOP_HEIGHT } from "../../styles/style-constants";
import { connect } from "react-redux";
import { cartStore } from "@resourcehub/resourcehub-data";
import CartPanel from "../CartPanel/CartPanel";
import { icons } from "@resourcehub/resourcehub-assets";

const { cartActions } = cartStore;

const PrimaryNavWrapper = styled.section`
    label: PrimaryNavWrapper;

    align-items: center;
    background-color: #fff;
    border-bottom: 8px solid #c19e2a;
    box-shadow: 0 8px 0 #d8af2f;
    display: flex;
    justify-content: space-between;
    min-height: ${NAV_TOP_HEIGHT};
    position: relative;
    top: 0;
    width: 100%;
    z-index: ${zIndex.nav_primary};
`;

const PrimaryNavLeftyWrapper = styled.nav`
    label: PrimaryNavLeftyWrapper;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 1em;
    padding-right: 1em;
`;

const PrimaryNavRightyWrapper = styled.nav`
    label: PrimaryNavRightyWrapper;

    //Mobile-landscape (and larger)
    @media (min-width: 320px) {
        display: none;
    }
    //Mobile-landscape (and larger)
    @media (min-width: 480px) {
        display: none;
    }
    // Tablet-portrait (and larger)
    @media (min-width: 768px) {
        display: none;
    }
    // Tablet-landscape (and larger)
    @media (min-width: 992px) {
        display: none;
    }
    // Laptops (and longer)
    @media (min-width: 1230px) {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 15px;
    }
`;

const MobileNavToggleWrapper = styled.nav`
    label: MobileNavToggleWrapper;

    //Mobile-landscape (and larger)
    @media (min-width: 320px) {
        display: flex;
    }
    //Mobile-landscape (and larger)
    @media (min-width: 480px) {
        display: flex;
    }
    // Tablet-portrait (and larger)
    @media (min-width: 768px) {
        display: flex;
    }
    // Tablet-landscape (and larger)
    @media (min-width: 992px) {
        display: flex;
    }
    // Laptops (and longer)
    @media (min-width: 1230px) {
        display: none;
    }
`;

const CartIconContainer = styled.div`
    padding: 23px 11px;
`;

const CartIcon = ({ setIsOverlayVisible, setIsCartVisible }) => (
    <CartIconContainer>
        <a
            onClick={() => {
                setIsOverlayVisible(true);
                setIsCartVisible(true);
            }}
        >
            <img src={icons.iconCartSvg} alt="Cart Icon" />
        </a>
    </CartIconContainer>
);

const Nav = ({ openDropdown, setOpenDropdown, config, setIsOverlayVisible, setIsCartVisible }) => {
    if (!config) return null;
    return (
        <>
            {config.primary && config.primary.visible && (
                <PrimaryNavWrapper data-qa="main-nav-bar">
                    <PrimaryNavLeftyWrapper>
                        <BrandLogo {...config.primary.rs_logo} />
                    </PrimaryNavLeftyWrapper>
                    <NavChatHeader id="lp-chat-header" />
                    {config.secondary && config.secondary.hasCart && <CartPanel />}
                    <PrimaryNavRightyWrapper>
                        {config.primary.select_language && config.primary.select_language.visible && (
                            <LanguagesPricing
                                setOpenDropdown={setOpenDropdown}
                                openDropdown={openDropdown}
                                config={config.primary.select_language}
                                title={config.primary.select_language.label}
                            />
                        )}
                        {config.primary.vertical_consumer && config.primary.vertical_consumer.visible && (
                            <PrimaryNavLink
                                destination={config.primary.vertical_consumer.destination}
                                external={config.primary.vertical_consumer.external}
                                openNew={config.primary.vertical_consumer.new_window}
                                title={config.primary.vertical_consumer.label}
                            >
                                {config.primary.vertical_consumer.label}
                            </PrimaryNavLink>
                        )}
                        {config.primary.vertical_enterprise && config.primary.vertical_enterprise.visible && (
                            <PrimaryNavLink
                                dataQa="main-nav-enterprise-2020"
                                destination={config.primary.vertical_enterprise.destination}
                                external={config.primary.vertical_enterprise.external}
                                openNew={config.primary.vertical_enterprise.new_window}
                                title={config.primary.vertical_enterprise.label}
                            >
                                {config.primary.vertical_enterprise.label}
                            </PrimaryNavLink>
                        )}
                        {config.primary.vertical_education_primary &&
                            config.primary.vertical_education_primary.visible && (
                                <PrimaryNavLink
                                    dataQa="main-nav-education-primary-2020"
                                    destination={config.primary.vertical_education_primary.destination}
                                    external={config.primary.vertical_education_primary.external}
                                    openNew={config.primary.vertical_education_primary.new_window}
                                    title={config.primary.vertical_education_primary.label}
                                >
                                    {config.primary.vertical_education_primary.label}
                                </PrimaryNavLink>
                            )}
                        {config.primary.vertical_education_secondary &&
                            config.primary.vertical_education_secondary.visible && (
                                <PrimaryNavLink
                                    dataQa="main-nav-education-secondary-2020"
                                    destination={config.primary.vertical_education_secondary.destination}
                                    external={config.primary.vertical_education_secondary.external}
                                    openNew={config.primary.vertical_education_secondary.new_window}
                                    title={config.primary.vertical_education_secondary.label}
                                >
                                    {config.primary.vertical_education_secondary.label}
                                </PrimaryNavLink>
                            )}
                        {config.primary.vertical_education_homeschool &&
                            config.primary.vertical_education_homeschool.visible && (
                                <PrimaryNavLink
                                    dataQa="main-nav-homeschool-2020"
                                    destination={config.primary.vertical_education_homeschool.destination}
                                    external={config.primary.vertical_education_homeschool.external}
                                    openNew={config.primary.vertical_education_homeschool.new_window}
                                    title={config.primary.vertical_education_homeschool.label}
                                >
                                    {config.primary.vertical_education_homeschool.label}
                                </PrimaryNavLink>
                            )}
                        {config.primary.vertical_education_students &&
                            config.primary.vertical_education_students.visible && (
                                <PrimaryNavLink
                                    dataQa="main-nav-education-students-2020"
                                    destination={config.primary.vertical_education_students.destination}
                                    external={config.primary.vertical_education_students.external}
                                    openNew={config.primary.vertical_education_students.new_window}
                                    title={config.primary.vertical_education_students.label}
                                >
                                    {config.primary.vertical_education_students.label}
                                </PrimaryNavLink>
                            )}
                        {config.primary.support && config.primary.support.visible && (
                            <PrimaryNavLink
                                dataQa="main-nav-support-2020"
                                destination={config.primary.support.destination}
                                external={config.primary.support.external}
                                openNew={config.primary.support.new_window}
                                title={config.primary.support.label}
                            >
                                {config.primary.support.label}
                            </PrimaryNavLink>
                        )}
                        {config.primary.contact && config.primary.contact.visible && (
                            <PrimaryNavLink
                                dataQa="main-nav-contact-2020"
                                destination={config.primary.contact.destination}
                                external={config.primary.contact.external}
                                openNew={config.primary.contact.new_window}
                                title={config.primary.contact.label}
                            >
                                {config.primary.contact.label}
                            </PrimaryNavLink>
                        )}
                        {config.primary.blog && config.primary.blog.visible && (
                            <PrimaryNavLink
                                dataQa="main-nav-blog-2020"
                                destination={config.primary.blog.destination}
                                external={config.primary.blog.external}
                                openNew={config.primary.blog.new_window}
                                title={config.primary.blog.label}
                            >
                                {config.primary.blog.label}
                            </PrimaryNavLink>
                        )}
                        {config.primary.search && config.primary.search.visible && (
                            <NavSearch
                                dataQa="main-nav-search-2020"
                                setOpenDropdown={setOpenDropdown}
                                openDropdown={openDropdown}
                                searchLink={config.primary.search.destination}
                                searchPlaceholder={config.primary.search.placeholder}
                            />
                        )}
                        {config.secondary && config.secondary.hasCart && (
                            <CartIcon setIsOverlayVisible={setIsOverlayVisible} setIsCartVisible={setIsCartVisible} />
                        )}
                        {config.primary.phone && config.primary.phone.visible && (
                            <PhoneIcon
                                dataQa="main-nav-phone-icon-2021"
                                destination={config.primary.phone.destination}
                                new_window={config.primary.phone.new_window}
                            />
                        )}
                        {config.primary.sign_in && config.primary.sign_in.visible && (
                            <ButtonStyled
                                dataQa="main-nav-sign-in-2020"
                                size="mini"
                                theme="black_yellow"
                                text={config.primary.sign_in.label}
                                destination={config.primary.sign_in.destination}
                                new_window={config.primary.sign_in.new_window}
                            />
                        )}
                        {config.primary.switch_language && config.primary.switch_language.visible && (
                            <PrimaryNavLink
                                dataQa="main-nav-spanish-2020"
                                destination={config.primary.switch_language.destination}
                                external={config.primary.switch_language.external}
                                openNew={config.primary.switch_language.new_window}
                                title={config.primary.switch_language.label}
                            >
                                {config.primary.switch_language.label}
                            </PrimaryNavLink>
                        )}
                    </PrimaryNavRightyWrapper>
                    <MobileNavToggleWrapper>
                        <MobileNavToggle setOpenDropdown={setOpenDropdown} openDropdown={openDropdown} />
                    </MobileNavToggleWrapper>
                </PrimaryNavWrapper>
            )}
            {config.secondary && config.secondary.visible && <SecondaryNav config={config.secondary} />}
            {config.primary && config.primary.visible && (
                <MobileNav config={config.primary} setOpenDropdown={setOpenDropdown} openDropdown={openDropdown} />
            )}
        </>
    );
};

Nav.propTypes = {
    config: PropTypes.object.isRequired,
    setOpenDropdown: PropTypes.func.isRequired,
    openDropdown: PropTypes.string
};

const mapStateToProps = () => ({});

export { Nav };

export default connect(mapStateToProps, { ...cartActions })(Nav);
