import React from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { Link } from "gatsby";

const themes = ["black_yellow", "blue_white", "yellow_black", "white_black", "white_blue"];
const sizes = ["mini", "regular", "mobile"];

const switchTheme = (theme) => {
    switch (theme) {
        case "black_yellow":
            return `
            background-color: #262626 !important;
            border: 3px solid #262626 !important;
            border-radius: 4px !important;
            color: #F0C433 !important;
            :hover {
                background-color: #262626 !important;
                border: 3px solid #262626 !important;
                color: #F0C433 !important;
                text-decoration: none;
                cursor: pointer;
            }
        `;
        case "yellow_black":
            return `
                color: #262626 !important;
                background-color: #F0C433 !important;
                border: 3px solid #F0C433 !important;
                border-radius: 8px !important;
                :hover {
                    background-color: #D7B12C !important;
                    border: 3px solid #D7B12C !important;
                    color: #262626 !important;
                    text-decoration: none;
                    cursor: pointer;
                }
             `;
        case "white_black":
            return `
                background-color: transparent !important;
                border: 3px solid #262626 !important;
                border-radius: 8px !important;
                color: #262626 !important;
                :hover {
                    background-color: "#262626 !important;
                    border: 3px solid #262626 !important;
                    color: #FFFFFF !important;
                    text-decoration: none;
                    cursor: pointer;
                }
             `;
        case "white_blue":
            return `
                background-color: transparent !important;
                border: 3px solid #0098DB !important;
                border-radius: 8px !important;
                color: #0098DB !important;
                
                :hover {
                    background-color: #0098DB !important;
                    border: "3px solid #0098DB !important;
                    color: "#FFFFFF !important;
                    text-decoration: none;
                    cursor: pointer;
                }
        `;
        case "blue_white":
            return `
                border: 3px solid #0098DB !important;
                border-radius: 8px !important;
                background-color: #0098DB !important;
                color: #ffffff !important;
                :hover {
                    background-color: #377EB3 !important;
                    border: 3px solid #377EB3 !important;
                    color: #ffffff !important;
                    text-decoration: none;
                    cursor: pointer;
                }  
        `;
    }
    return `
        border: 3px solid #0098DB !important;
        border-radius: 8px !important;
        background-color: #0098DB !important;
        color: #ffffff !important;
        :hover {
            background-color: #377EB3 !important;
            border: 3px solid #377EB3 !important;
            color: #ffffff !important;
            text-decoration: none;
            cursor: pointer;
        }    
    `;
};
const switchSize = (size) => {
    // SIZE OPTIONS
    switch (size) {
        case "mini":
            return "padding: .5em 2.5em;";
        case "mobile":
            return "padding: 1.3em 7.5em;";
        case "regular":
        default:
            return "padding: 1.3em 2.5em;";
    }
};

const ButtonStyle = styled.a`
    label: ButtonStyle;

    border-radius: 8px;
    color: #333333;
    display: inline-flex;
    justify-content: center;
    line-height: 19px;
    margin: 5px;
    text-align: center;
    text-decoration: none;
    transition: all 0.1s ease-in-out;

    // Marked as important because of style bleed from LESS.
    text-transform: uppercase !important;
    font-family: effra_bold, sans-serif !important;
    font-size: 16px !important;
    letter-spacing: 4px !important;
    ${({ size }) => switchSize(size)};
    ${({ theme }) => switchTheme(theme)};
`;
const ButtonLinkStyle = styled(Link)`
    label: ButtonStyle;

    border-radius: 8px;
    color: #333333;
    display: inline-flex;
    justify-content: center;
    line-height: 19px;
    margin: 5px;
    text-align: center;
    text-decoration: none;
    transition: all 0.1s ease-in-out;

    // Marked as important because of style bleed from LESS.
    text-transform: uppercase !important;
    font-family: effra_bold, sans-serif !important;
    font-size: 16px !important;
    letter-spacing: 4px !important;
    ${({ size }) => switchSize(size)};
    ${({ theme }) => switchTheme(theme)};
`;

const ButtonStyled = (props) => {
    const { onClick, children, size, theme, text, destination, new_window, dataQa, disabled, internal } = props;
    if (onClick) {
        // When the consumer provides a callback function.
        return (
            <ButtonStyle
                {...props}
                data-qa={dataQa}
                type="button"
                size={size}
                theme={theme}
                onClick={disabled ? () => "return false;" : onClick}
                disabled={disabled}
            >
                {text} {children}
            </ButtonStyle>
        );
    }

    // External links or internal links that need to be opened in a new tab/window.
    // Anything links falling into this category will not have the url prefixed.
    if (!internal) {
        return (
            <ButtonStyle
                href={destination}
                target={new_window ? "_blank" : "_self"}
                rel="noopener"
                data-qa={dataQa}
                size={size}
                theme={theme}
                disabled={disabled}
                {...props}
            >
                {text} {children}
            </ButtonStyle>
        );
    }
    if (internal) {
        // Gatsby Link does not support opening in new window
        return (
            <ButtonLinkStyle to={destination} data-qa={dataQa} size={size} theme={theme} disabled={disabled} {...props}>
                {text} {children}
            </ButtonLinkStyle>
        );
    }
    throw "Invalid prop combination";
};
export const ButtonStyledPropTypes = {
    destination: PropTypes.string,
    disabled: PropTypes.bool,
    internal: PropTypes.bool,
    new_window: PropTypes.bool,
    onClick: PropTypes.func,
    size: PropTypes.oneOf(sizes),
    text: PropTypes.string,
    theme: PropTypes.oneOf(themes)
};
ButtonStyled.defaultProps = {
    disabled: false,
    internal: false,
    new_window: false,
    size: "regular",
    text: "",
    theme: "blue_white"
};
ButtonStyled.propTypes = ButtonStyledPropTypes;
export default ButtonStyled;
