import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { sectionStones } from "@resourcehub/resourcehub-assets";
import TitleWrapper from "../../component-styles/TitleWrapper";

const SectionTitleFlare = styled.div((props) => {
    let flareUrl;
    switch (props.flare) {
        case "Gray Stone":
        case "gray-stone":
            flareUrl = sectionStones.grayStoneSvg;
            break;
        case "Yellow Stone":
        case "yellow-stone":
            flareUrl = sectionStones.yellowStoneSvg;
            break;
        case "Blue Stone":
        case "blue-stone":
        default:
            flareUrl = sectionStones.blueStoneSvg;
    }
    return {
        label: "SectionTitleFlare",
        backgroundImage: `url(${flareUrl})`,
        backgroundPosition: "top",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",

        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        maxHeight: "215px",
        minHeight: "185px",
        minWidth: "280px",
        maxWidth: "327px"
    };
});

export const flareOptions = ["blue-stone", "gray-stone", "yellow-stone", "Blue Stone", "Gray Stone", "Yellow Stone"];
export const hTypes = ["h1", "h2", "h3", "h4", "H1", "H2", "H3"];

const SectionStone = ({ text, flare, hType }) => (
    <SectionTitleFlare flare={flare}>
        <TitleWrapper align="center" heading={hType.toLowerCase()} theme="black" type="stone-title">
            {text}
        </TitleWrapper>
    </SectionTitleFlare>
);

export const SectionStonePropTypes = {
    text: PropTypes.string.isRequired,
    flare: PropTypes.oneOf(flareOptions),
    hType: PropTypes.oneOf(hTypes)
};
SectionStone.propTypes = SectionStonePropTypes;
SectionStone.defaultProps = {
    flare: "blue-stone"
};
export default SectionStone;
