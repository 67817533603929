import React, { Component } from "react";
import Modal from "react-modal";
import Form from "./Form";
import LanguageList from "./LanguageList";
import "../../styles/modal.less";
import "../../styles/demoModalCTA.less";
import "../../styles/button.less";
import deprecated from "../deprecated";

const findAppEL = () => {
    if (typeof window !== "undefined" && window.STORYBOOK_ENV) return "#root";

    return "#___gatsby";
};

const verticalMap = {
    business: "busn",
    k12: "k12",
    hed: "hed",
    publicsector: "pubsec"
};

class DemoModalCallToAction extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isDropDownOpen: false,
            isModalOpen: false,
            isFormModalOpen: false,
            demoUrl: "https://resources.rosettastone.com/CDN/us/rs-new-demo-2/?lang=en-es&type=busn",
            showFallbackVideo: false,
            thanksModalIsOpen: false
        };
    }

    componentDidMount() {
        Modal.setAppElement(findAppEL());
        window.addEventListener("resize", this.resize);
        this.resize();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize);
    }

    closeModal = () => {
        this.setState({
            isModalOpen: false,
            isFormModalOpen: true
        });
    };

    closeFormModal = () => {
        this.setState({ isFormModalOpen: false });
    };

    afterSubmit = () => {
        const { afterSubmit } = this.props;
        if (afterSubmit) {
            afterSubmit();
        } else {
            this.setState({
                isFormModalOpen: false,
                thanksModalIsOpen: true
            });
        }
    };

    closeThanksModal = () => {
        this.setState({ thanksModalIsOpen: false });
    };

    resize = () => {
        this.setState({
            showFallbackVideo: document.body.getBoundingClientRect().width <= 1000
        });
    };

    handleLangSelect = (event, lang) => {
        const { vertical } = this.props;
        const vert = verticalMap[vertical] || "busn"; // default to business
        const demoUrl = `https://resources.rosettastone.com/CDN/us/rs-new-demo-2/?lang=en-${lang}&type=${vert}`;
        this.setState({
            isDropDownOpen: false,
            isModalOpen: true,
            demoUrl
        });
    };

    toggleDropDown() {
        this.setState((prevState) => ({
            isDropDownOpen: !prevState.isDropDownOpen
        }));
    }

    render() {
        const { isDropDownOpen, isModalOpen, isFormModalOpen, demoUrl, showFallbackVideo, thanksModalIsOpen } =
            this.state;
        const { copyData, domain, vertical, section_1_fallback_video } = this.props;
        const ctaClasses = isDropDownOpen
            ? "btn btn-blue btn-sm btn-dropdown js_masthead_demo_drop btn-dropdown_open masthead_cta_keyboard_focus"
            : "btn btn-blue btn-sm btn-dropdown js_masthead_demo_drop masthead_cta_keyboard_focus";
        const slideHeight = "145px";

        return (
            <>
                <div className="masthead_cta_half masthead_demo_dropdown_container">
                    <button type="button" className={ctaClasses} onClick={() => this.toggleDropDown()}>
                        <span>{vertical === "business" ? "Try For Yourself" : "Try A Lesson"}</span>
                        <svg className="masthead_dropdown_arrow" viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
                            <polyline
                                points="3,4.3 5,6.3 7,4.3"
                                style={{
                                    strokeWidth: "0.6",
                                    strokeLinejoin: "round",
                                    strokeLinecap: "round",
                                    fill: "none"
                                }}
                            />
                        </svg>
                    </button>
                    <div className="masthead_demo_dropdown masthead_item_hidden">
                        <div className="masthead_dropdown_slides" style={{ height: slideHeight }}>
                            <div className="masthead_dropdown_slide masthead_slide_active">
                                <LanguageList handleLangSelect={this.handleLangSelect} />
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    isOpen={isModalOpen}
                    onRequestClose={this.closeModal}
                    contentLabel="Demo Modal"
                    className="modal demo"
                    overlayClassName="overlay"
                >
                    <button className="modal__close" type="button" onClick={this.closeModal}>
                        X
                    </button>
                    <iframe title="Business Overview" src={showFallbackVideo ? section_1_fallback_video : demoUrl} />
                </Modal>
                <Form
                    pricing_title={copyData.demo_pricing_title}
                    pricing_subtitle={copyData.demo_pricing_subtitle_business}
                    domain={domain}
                    vertical={vertical}
                    copyData={copyData}
                    modalIsOpen={isFormModalOpen}
                    closeModal={this.closeFormModal}
                    afterSubmit={this.afterSubmit}
                    thanksModalIsOpen={thanksModalIsOpen}
                    closeThanksModal={this.closeThanksModal}
                />
            </>
        );
    }
}

export default deprecated(DemoModalCallToAction);
