import appStorePng from "./app-store/app-store.png";
import avatarJessicaPng from "./avatars/avatar_jessica.png";
import awardPcMagPng from "./awards/award_pc_mag.png";
import azulPng from "./product-sample/azul.png";
import azulesPorUnDiaPng from "./product-sample/azulesPorUnDia.png";
import badgePng from "./images/badge.png";
import bgBlueJpg from "./images/bg-blue.jpg";
import bgGreyJpg from "./images/bg-grey.jpg";
import bgYellowJpg from "./images/bg-yellow.jpg";
import bicicletaAmarillaPng from "./product-sample/bicicletaAmarilla.png";
import bicicletaAzulPng from "./product-sample/bicicletaAzul.png";
import blueBridgeBottomSvg from "./images/blue-bridge-bottom.svg";
import blueBridgeTopSvg from "./images/blue-bridge-top.svg";
import blueCurveBottomSvg from "./page-section-borders/blue-curve-bottom.svg";
import blueCurveTopSvg from "./page-section-borders/blue-curve-top.svg";
import blueDarkStoneSvg from "./section-stones/blue-dark-stone.svg";
import blueSpinnerGif from "./images/blue-spinner.gif";
import blueStoneSvg from "./section-stones/blue-stone.svg";
import blueStoneWatermarkSvg from "./images/blue-stone-watermark.svg";
import brushYellowBracketLeftPng from "./text-emphasis/brush-yellow-bracket-left.png";
import brushYellowBracketRightPng from "./text-emphasis/brush-yellow-bracket-right.png";
import brushYellowCirclePng from "./text-emphasis/brush-yellow-circle.png";
import brushYellowCornerLeftPng from "./text-emphasis/brush-yellow-corner-left.png";
import brushYellowCornerRightPng from "./text-emphasis/brush-yellow-corner-right.png";
import brushYellowLinePng from "./text-emphasis/brush-yellow-line.png";
import brushYellowListCircle_1Png from "./text-emphasis/brush-yellow-list-circle-1.png";
import brushYellowListCircle_2Png from "./text-emphasis/brush-yellow-list-circle-2.png";
import brushYellowListCircle_3Png from "./text-emphasis/brush-yellow-list-circle-3.png";
import brushYellowListCircle_4Png from "./text-emphasis/brush-yellow-list-circle-4.png";
import brushYellowSparkLeftPng from "./text-emphasis/brush-yellow-spark-left.png";
import brushYellowSparkRightPng from "./text-emphasis/brush-yellow-spark-right.png";
import busWebHomepage_2019Png from "./images/BUS-web-homepage-2019.png";
import businessCirclePng from "./images/business-circle.png";
import cartPng from "./images/cart.png";
import cat2TutorScreenPng from "./images/cat2-tutor-screen.png";
import christopherJpeg from "./images/christopher.jpeg";
import closePng from "./images/close.png";
import cnnBgPng from "./images/cnn_bg.png";
import cnnLogoShadowedPng from "./images/cnn_logo_shadowed.png";
import colorsInItalian_2819dJpg from "./banners/colors-in-italian-2819d.jpg";
import constructionHeroPng from "./images/Construction-hero.png";
import constructionPhoto1Png from "./images/Construction-Photo1.png";
import constructionPhoto2Png from "./images/Construction-Photo2.png";
import correctSvg from "./product-sample/correct.svg";
import delaineJpeg from "./images/delaine.jpeg";
import deviceLockupLargePng from "./images/device_lockup_large.png";
import dosBicicletasAzulesPng from "./product-sample/dosBicicletasAzules.png";
import dosBicicletasDiferentesPng from "./product-sample/dosBicicletasDiferentes.png";
import educationPrimaryCirclePng from "./verticals/education-primary-circle.png";
import educationSecondaryCirclePng from "./verticals/education-secondary-circle.png";
import educatorsPng from "./images/educators.png";
import emptyIphonePng from "./images/empty-iphone.png";
import enterpriseCirclePng from "./verticals/enterprise-circle.png";
import enterprisePng from "./images/enterprise.png";
import facebookDarkSvg from "./social/facebook-dark.svg";
import facebookIconSvg from "./images/facebook-icon.svg";
import faviconIco from "./rs-branding/favicon.ico";
import fb2Png from "./images/fb2.png";
import featuresPhoneActivitiesPng from "./features/features-phone-activities.png";
import featuresPhoneImmersionPng from "./features/features-phone-immersion.png";
import featuresPhoneLanguagesPng from "./features/features-phone-languages.png";
import featuresPhoneMobilePng from "./features/features-phone-mobile.png";
import featuresPhoneTruaccentPng from "./features/features-phone-truaccent.png";
import featuresPhoneTutoringPng from "./features/features-phone-tutoring.png";
import fitbrainsBgJpg from "./images/fitbrains-bg.jpg";
import flagRoundAraSvg from "./flags-round/flag-round-ara.svg";
import flagRoundChiSvg from "./flags-round/flag-round-chi.svg";
import flagRoundDeuSvg from "./flags-round/flag-round-deu.svg";
import flagRoundEbrSvg from "./flags-round/flag-round-ebr.svg";
import flagRoundEngSvg from "./flags-round/flag-round-eng.svg";
import flagRoundEscSvg from "./flags-round/flag-round-esc.svg";
import flagRoundEspSvg from "./flags-round/flag-round-esp.svg";
import flagRoundFarSvg from "./flags-round/flag-round-far.svg";
import flagRoundFraSvg from "./flags-round/flag-round-fra.svg";
import flagRoundGleSvg from "./flags-round/flag-round-gle.svg";
import flagRoundGrkSvg from "./flags-round/flag-round-grk.svg";
import flagRoundHebSvg from "./flags-round/flag-round-heb.svg";
import flagRoundHinSvg from "./flags-round/flag-round-hin.svg";
import flagRoundItaSvg from "./flags-round/flag-round-ita.svg";
import flagRoundJpnSvg from "./flags-round/flag-round-jpn.svg";
import flagRoundKorSvg from "./flags-round/flag-round-kor.svg";
import flagRoundLatSvg from "./flags-round/flag-round-lat.svg";
import flagRoundNedSvg from "./flags-round/flag-round-ned.svg";
import flagRoundPolSvg from "./flags-round/flag-round-pol.svg";
import flagRoundPorSvg from "./flags-round/flag-round-por.svg";
import flagRoundRusSvg from "./flags-round/flag-round-rus.svg";
import flagRoundSveSvg from "./flags-round/flag-round-sve.svg";
import flagRoundTglSvg from "./flags-round/flag-round-tgl.svg";
import flagRoundTurSvg from "./flags-round/flag-round-tur.svg";
import flagRoundVieSvg from "./flags-round/flag-round-vie.svg";
import foodserviceHeroPng from "./images/Foodservice-hero.png";
import foodservicePhoto1Png from "./images/Foodservice-Photo1.png";
import foodservicePhoto2Png from "./images/Foodservice-Photo2.png";
import gladysJpg from "./profile-card/Gladys.jpg";
import googlePlayPng from "./app-store/google-play.png";
import googlePlusIconSvg from "./images/google-plus-icon.svg";
import grayCurveBottomSvg from "./page-section-borders/gray-curve-bottom.svg";
import grayCurveTopSvg from "./page-section-borders/gray-curve-top.svg";
import grayStoneSvg from "./section-stones/gray-stone.svg";
import headerBlueYellowSvg from "./backgrounds/header-blue-yellow.svg";
import hedCirclePng from "./images/hed-circle.png";
import hedSection_1ImgPng from "./images/hed-section-1-img.png";
import hedSection_5ImgPng from "./images/hed-section-5-img.png";
import homepageWomanOnBalconyJpg from "./images/HomepageWomanOnBalcony.jpg";
import homepageWomanOnBalconyMobileJpg from "./images/HomepageWomanOnBalcony-mobile.jpg";
import homeschoolBgJpg from "./images/homeschool-bg.jpg";
import homeschoolerJpg from "./homeschool/homeschooler.jpg";
import hospitalityHeroPng from "./images/Hospitality-hero.png";
import hospitalityPhoto1Png from "./images/Hospitality-Photo1.png";
import hospitalityPhoto2Png from "./images/Hospitality-Photo2.png";
import iconAssessmentsSvg from "./icons/icon-assessments.svg";
import iconBookPng from "./icons/icon-book.png";
import iconBriefcasePng from "./icons/icon-briefcase.png";
import iconCartSvg from "./icons/icon-cart.svg";
import iconDynamicSvg from "./icons/icon-dynamic.svg";
import iconFoundationalSvg from "./icons/icon-foundational.svg";
import iconGamesSvg from "./icons/icon-games.svg";
import iconGlobePng from "./icons/icon-globe.png";
import iconHeartPng from "./icons/icon-heart.png";
import iconImplementationSvg from "./icons/icon-implementation.svg";
import iconMobileSvg from "./icons/icon-mobile.svg";
import iconPlacementSvg from "./icons/icon-placement.svg";
import iconProficiencySvg from "./icons/icon-proficiency.svg";
import iconReadingSvg from "./icons/icon-reading.svg";
import iconReportingSvg from "./icons/icon-reporting.svg";
import iconSearchSvg from "./icons/icon_search.svg";
import iconSelector from "./_services/icon-selector.js";
import iconTrainingSvg from "./icons/icon-training.svg";
import iconTransferableSvg from "./icons/icon-transferable.svg";
import iconTruaccentSvg from "./icons/icon-truaccent.svg";
import iconTutoringSvg from "./icons/icon-tutoring.svg";
import icon_24Svg from "./icons/icon-24.svg";
import instagramDarkSvg from "./social/instagram-dark.svg";
import iphoneBluePng from "./images/iphone-blue.png";
import iphoneBlue_2xPng from "./images/iphone-blue@2x.png";
import iphonexEngagingPng from "./images/iphonex-engaging.png";
import iphonexImmersionPng from "./images/iphonex-immersion.png";
import iphonexMobilePng from "./images/iphonex-mobile.png";
import iphonexReportingtoolsPng from "./images/iphonex-reportingtools.png";
import iphonexTruaccentPng from "./images/iphonex-truaccent.png";
import iphonexTutoringPng from "./images/iphonex-tutoring.png";
import iphonex_24Png from "./images/iphonex-24.png";
import ixlLogoSvg from "./ixl-branding/ixl-logo.svg";
import jimJpg from "./profile-card/Jim.jpg";
import k12CirclePng from "./images/k12-circle.png";
import k12Section_1ImgPng from "./images/k12-section-1-img.png";
import k12Section_5ImgPng from "./images/k12-section-5-img.png";
import kidsBgJpg from "./images/kids-bg.jpg";
import kidsPng from "./images/kids.png";
import langLearnJpg from "./images/lang-learn.jpg";
import lausdLogoSvg from "./logos/lausd-logo.svg";
import leadershipMatthewHulettJpg from "./profile/leadership_matthew_hulett.jpg";
import lightboxJpg from "./images/lightbox.jpg";
import linkedinDarkPng from "./social/linkedin-dark.png";
import logoAmazonSvg from "./logos/logo-amazon.svg";
import logoAmgenPng from "./logos/logo-amgen.png";
import logoAndroidSvg from "./logos/logo-android.svg";
import logoAppleWatchSvg from "./logos/logo-apple-watch.svg";
import logoBarSFoodSigmaPng from "./logos/logo-bar-s-food-sigma.png";
import logoBellevueJpg from "./logos/logo-bellevue.jpg";
import logoBestBuySvg from "./logos/logo-best-buy.svg";
import logoBethelPng from "./logos/logo-bethel.png";
import logoBmwPng from "./logos/logo-bmw.png";
import logoBullittPng from "./logos/logo-bullitt.png";
import logoCalvinkleinSvg from "./logos/logo-calvinklein.svg";
import logoCanterburyJpg from "./logos/logo-canterbury.jpg";
import logoCaterpillarPng from "./logos/logo-caterpillar.png";
import logoChickasawPng from "./logos/logo-chickasaw.png";
import logoCincinnatiPng from "./logos/logo-cincinnati.png";
import logoClarksvilleJpg from "./logos/logo-clarksville.jpg";
import logoCnnBgSvg from "./logos/logo-cnn-bg.svg";
import logoCnnSvg from "./logos/logo-cnn.svg";
import logoCoronaPng from "./logos/logo-corona.png";
import logoCrowderPng from "./logos/logo-crowder.png";
import logoCvsHealthPng from "./logos/logo-cvs-health.png";
import logoDprPng from "./logos/logo-dpr.png";
import logoEonPng from "./logos/logo-eon.png";
import logoFairfaxPng from "./logos/logo-fairfax.png";
import logoFenderSvg from "./logos/logo-fender.svg";
import logoFicoPng from "./logos/logo-fico.png";
import logoFlorettePng from "./logos/logo-florette.png";
import logoGeorgesPng from "./logos/logo-georges.png";
import logoGilbanePng from "./logos/logo-gilbane.png";
import logoGmPng from "./logos/logo-gm.png";
import logoGuthrieJpg from "./logos/logo-guthrie.jpg";
import logoHarrogateJpg from "./logos/logo-harrogate.jpg";
import logoHitachiPng from "./logos/logo-hitachi.png";
import logoHumboldtPng from "./logos/logo-humboldt.png";
import logoIosSvg from "./logos/logo-ios.svg";
import logoKimberlyClarkPng from "./logos/logo-kimberly-clark.png";
import logoKonicaPng from "./logos/logo-konica.png";
import logoLamResearchPng from "./logos/logo-lam-research.png";
import logoLeclercPng from "./logos/logo-leclerc.png";
import logoLibertyPng from "./logos/logo-liberty.png";
import logoMacworldBgSvg from "./logos/logo-macworld-bg.svg";
import logoMacworldSvg from "./logos/logo-macworld.svg";
import logoMarriottInternationalPng from "./logos/logo-marriott-international.png";
import logoMashableSvg from "./logos/logo-mashable.svg";
import logoNeaJpg from "./logos/logo-nea.jpg";
import logoNorlingtonJpg from "./logos/logo-norlington.jpg";
import logoNorthernBorderPng from "./logos/logo-northern-border.png";
import logoNycHealthPng from "./logos/logo-nyc-health.png";
import logoOreillyPng from "./logos/logo-oreilly.png";
import logoPcmagPng from "./logos/logo-pcmag.png";
import logoPcmag_2Png from "./logos/logo-pcmag-2.png";
import logoPhilipMorrisInternationalPng from "./logos/logo-philip-morris-international.png";
import logoPhoenixPng from "./logos/logo-phoenix.png";
import logoPphePng from "./logos/logo-pphe.png";
import logoPuebloPng from "./logos/logo-pueblo.png";
import logoRaleighJpg from "./logos/logo-raleigh.jpg";
import logoRstPng from "./logos/logo_rst.png";
import logoSamsungSvg from "./logos/logo-samsung.svg";
import logoSonyPicturesPng from "./logos/logo-sony-pictures.png";
import logoTargetSvg from "./logos/logo-target.svg";
import logoTechcrunchBgSvg from "./logos/logo-techcrunch-bg.svg";
import logoTechcrunchLightSvg from "./logos/logo-techcrunch-light.svg";
import logoTechcrunchSvg from "./logos/logo-techcrunch.svg";
import logoTishmanSpeyerPng from "./logos/logo-tishman-speyer.png";
import logoTripadvisor2Png from "./logos/logo-tripadvisor2.png";
import logoTripadvisorPng from "./logos/logo-tripadvisor.png";
import logoTripadvisorSvg from "./logos/logo-tripadvisor.svg";
import logoTwitterPng from "./logos/logo-twitter.png";
import logoUcbPng from "./logos/logo-ucb.png";
import logoViPng from "./logos/logo-vi.png";
import logoVodafonePng from "./logos/logo-vodafone.png";
import logoWalmartSvg from "./logos/logo_walmart.svg";
import logoWilliamsSonomaPng from "./logos/logo-williams-sonoma.png";
import logosTilesAmgenSvg from "./logos-tiles/logos-tiles-amgen.svg";
import logosTilesBarSSvg from "./logos-tiles/logos-tiles-bar-s.svg";
import logosTilesBmwSvg from "./logos-tiles/logos-tiles-bmw.svg";
import logosTilesCaterpillarSvg from "./logos-tiles/logos-tiles-caterpillar.svg";
import logosTilesCrowderSvg from "./logos-tiles/logos-tiles-crowder.svg";
import logosTilesCvsSvg from "./logos-tiles/logos-tiles-cvs.svg";
import logosTilesDprSvg from "./logos-tiles/logos-tiles-dpr.svg";
import logosTilesGeorgesSvg from "./logos-tiles/logos-tiles-georges.svg";
import logosTilesGmSvg from "./logos-tiles/logos-tiles-gm.svg";
import logosTilesLamSvg from "./logos-tiles/logos-tiles-lam.svg";
import logosTilesLeclercSvg from "./logos-tiles/logos-tiles-leclerc.svg";
import logosTilesLegoSvg from "./logos-tiles/logos-tiles-lego.svg";
import logosTilesMarriottSvg from "./logos-tiles/logos-tiles-marriott.svg";
import logosTilesNycHealthSvg from "./logos-tiles/logos-tiles-nyc-health.svg";
import logosTilesOreillySvg from "./logos-tiles/logos-tiles-oreilly.svg";
import logosTilesPmiSvg from "./logos-tiles/logos-tiles-pmi.svg";
import logosTilesPpheSvg from "./logos-tiles/logos-tiles-pphe.svg";
import logosTilesTishSvg from "./logos-tiles/logos-tiles-tish.svg";
import logosTilesTwitterSvg from "./logos-tiles/logos-tiles-twitter.svg";
import logosTilesUberSvg from "./logos-tiles/logos-tiles-uber.svg";
import logosTilesViSvg from "./logos-tiles/logos-tiles-vi.svg";
import logosTilesWsSvg from "./logos-tiles/logos-tiles-ws.svg";
import macworldBgPng from "./images/macworld_bg.png";
import macworldLogoShadowedPng from "./images/macworld_logo_shadowed.png";
import manufacturingHeroPng from "./images/Manufacturing-hero.png";
import manufacturingPhoto1Png from "./images/Manufacturing-Photo1.png";
import manufacturingPhoto2Png from "./images/Manufacturing-Photo2.png";
import mastheadBgDesktopFranceJpg from "./images/masthead_bg_desktop_france.jpg";
import mastheadBgDesktopPng from "./images/masthead_bg_desktop.png";
import mastheadBgMobileFranceJpg from "./images/masthead_bg_mobile_france.jpg";
import mastheadBgMobilePng from "./images/masthead_bg_mobile.png";
import maunfacturingPhoto2Png from "./images/Maunfacturing-Photo2.png";
import mobileAppsAnotherReasonJpeg from "./images/mobile-apps-another-reason.jpeg";
import mobileAppsImage_1Jpg from "./images/mobile-apps-image-1.jpg";
import mobileAppsImage_2Jpg from "./images/mobile-apps-image-2.jpg";
import mobileAppsImage_3Png from "./images/mobile-apps-image-3.png";
import mobileAppsImage_4Jpg from "./images/mobile-apps-image-4.jpg";
import mobileAppsLearningFeaturesPng from "./images/mobile-apps-learning-features.png";
import mobileAppsSpeakConfidentlyJpeg from "./images/mobile-apps-speak-confidently.jpeg";
import mobileAppsTeaserSectionJpeg from "./images/mobile-apps-teaser-section.jpeg";
import newWindowPng from "./images/new-window.png";
import nunoJpeg from "./images/nuno.jpeg";
import opdemo2AudioCompanionPng from "./images/opdemo2-audio-companion.png";
import opdemo2OnlineLockupPng from "./images/opdemo2-online-lockup.png";
import opdemo2PhonePng from "./images/opdemo2-phone.png";
import opdemo2ProvenMethodPng from "./images/opdemo2-proven-method.png";
import opdemo2TruaccentPng from "./images/opdemo2-truaccent.png";
import opdemo2TutorScreenPng from "./images/opdemo2-tutor-screen.png";
import phoneFanoutPng from "./homeschool/phone-fanout.png";
import phoneWhiteSpanishGif from "./animations/phone-white-spanish.gif";
import phoneWhiteSpanishMp4 from "./animations/phone-white-spanish.mp4";
import playBtnPng from "./images/Play-btn.png";
import pricingBgSvg from "./images/pricing-bg.svg";
import proServicesHeroPng from "./images/ProServices-hero.png";
import proServicesPhoto1Png from "./images/ProServices-Photo1.png";
import proServicesPhoto2Png from "./images/ProServices-Photo2.png";
import productUnit_10LifePng from "./product-units/product-unit-10-life.png";
import productUnit_11EverydayThingsPng from "./product-units/product-unit-11-everyday-things.png";
import productUnit_12PlacesEventsPng from "./product-units/product-unit-12-places-events.png";
import productUnit_13TourismPng from "./product-units/product-unit-13-tourism.png";
import productUnit_14ProfessionPng from "./product-units/product-unit-14-profession.png";
import productUnit_15AtHomePng from "./product-units/product-unit-15-at-home.png";
import productUnit_16StylePng from "./product-units/product-unit-16-style.png";
import productUnit_17BusinessPng from "./product-units/product-unit-17-business.png";
import productUnit_18ArtPng from "./product-units/product-unit-18-art.png";
import productUnit_19EmergencyPng from "./product-units/product-unit-19-emergency.png";
import productUnit_1ApplePng from "./product-units/product-unit-1-apple.png";
import productUnit_20FamilyPng from "./product-units/product-unit-20-family.png";
import productUnit_2TeaPng from "./product-units/product-unit-2-tea.png";
import productUnit_3PencilPng from "./product-units/product-unit-3-pencil.png";
import productUnit_4WalletPng from "./product-units/product-unit-4-wallet.png";
import productUnit_5PassportPng from "./product-units/product-unit-5-passport.png";
import productUnit_6PastFuturePng from "./product-units/product-unit-6-past-future.png";
import productUnit_7MobilePng from "./product-units/product-unit-7-mobile.png";
import productUnit_8DiningPng from "./product-units/product-unit-8-dining.png";
import productUnit_9HealthPng from "./product-units/product-unit-9-health.png";
import pubCirclePng from "./images/pub-circle.png";
import publicsectorCirclePng from "./verticals/publicsector-circle.png";
import pubsecSection_1ImgPng from "./images/pubsec-section-1-img.png";
import pubsecSection_5ImgPng from "./images/pubsec-section-5-img.png";
import quoteCirlceSvg from "./profile-card/quote-cirlce.svg";
import ratingsStarSvg from "./images/ratings-star.svg";
import relatedBottomSvg from "./images/related-bottom.svg";
import relatedTopSvg from "./images/related-top.svg";
import resourcesPng from "./images/resources.png";
import retailHeroPng from "./images/Retail-hero.png";
import retailPhoto1Png from "./images/Retail-Photo1.png";
import retailPhoto2Png from "./images/Retail-Photo2.png";
import reviewsVideoAntoniaPng from "./images/reviews-video-antonia.png";
import reviewsVideoChrisPng from "./images/reviews-video-chris.png";
import reviewsVideoDelainePng from "./images/reviews-video-delaine.png";
import reviewsVideoGracePng from "./images/reviews-video-grace.png";
import reviewsVideoMarkPng from "./images/reviews-video-mark.png";
import reviewsVideoNunoPng from "./images/reviews-video-nuno.png";
import rsIconSvg from "./rs-branding/rs-icon.svg";
import rsIconWhiteSvg from "./rs-branding/rs-icon-white.svg";
import rsLogoBlackSvg from "./rs-branding/rs-logo-black.svg";
import screenAssessmentPng from "./images/screen-assessment.png";
import screenImplementationPng from "./images/screen-implementation.png";
import screenPlacementPng from "./images/screen-placement.png";
import screenReportingPng from "./images/screen-reporting.png";
import screenTestingPng from "./images/screen-testing.png";
import screenTrainingPng from "./images/screen-training.png";
import section_1Img_1Png from "./images/section-1-img-1.png";
import section_1Img_1_2xPng from "./images/section-1-img-1@2x.png";
import section_1Img_2Png from "./images/section-1-img-2.png";
import section_1StoneGreySvg from "./images/section-1-stone-grey.svg";
import section_2StoneBlueSvg from "./images/section-2-stone-blue.svg";
import section_3StoneGreySvg from "./images/section-3-stone-grey.svg";
import section_4StoneYellowSvg from "./images/section-4-stone-yellow.svg";
import section_5Img_1Png from "./images/section-5-img-1.png";
import section_5Img_1_2xPng from "./images/section-5-img-1@2x.png";
import section_5Img_2Png from "./images/section-5-img-2.png";
import section_5Img_2_2xPng from "./images/section-5-img-2@2x.png";
import section_5StoneGreySvg from "./images/section-5-stone-grey.svg";
import section_6StoneGreySvg from "./images/section-6-stone-grey.svg";
import section_7StoneBlueSvg from "./images/section-7-stone-blue.svg";
import solHealthcareSection_2ImgPng from "./images/sol-healthcare-section-2-img.png";
import solHealthcareSection_4ImgPng from "./images/sol-healthcare-section-4-img.png";
import solK12Section_1ImgJpg from "./images/sol-k12-section-1-img.jpg";
import solK12Section_2ImgPng from "./images/sol-k12-section-2-img.png";
import solK12Section_4ImgPng from "./images/sol-k12-section-4-img.png";
import solSection_1ImgPng from "./images/sol-section-1-img.png";
import solSection_1StoneBlueSvg from "./images/sol-section-1-stone-blue.svg";
import solSection_2ImgPng from "./images/sol-section-2-img.png";
import solSection_3StoneYellowSvg from "./images/sol-section-3-stone-yellow.svg";
import solSection_4ImgPng from "./images/sol-section-4-img.png";
import solSection_7StoneBlueSvg from "./images/sol-section-7-stone-blue.svg";
import solSection_7StoneYellowSvg from "./images/sol-section-7-stone-yellow.svg";
import starSvg from "./profile-card/star.svg";
import supportPng from "./images/support.png";
import syJpg from "./profile-card/Sy.jpg";
import techcrunchBgPng from "./images/techcrunch_bg.png";
import techcrunchLogoShadowedPng from "./images/techcrunch_logo_shadowed.png";
import thankYouMultilanguagePng from "./images/thank-you-multilanguage.png";
import threeWomenPhonePng from "./images/three-women-phone.png";
import triScreenLockupPng from "./images/tri-screen-lockup.png";
import triScreenLockupV2Png from "./images/tri-screen-lockup-v2.png";
import tutorScreenPng from "./images/tutor-screen.png";
import tutorScreen_0_5xPng from "./images/tutor-screen@0.5x.png";
import tutorScreen_2xPng from "./images/tutor-screen@2x.png";
import twitterDarkSvg from "./social/twitter-dark.svg";
import twitterIconSvg from "./images/twitter-icon.svg";
import usConsumerReviewsAnotherReasonJpeg from "./images/us-consumer-reviews-another_reason.jpeg";
import usConsumerReviewsCustomersReviewsJpeg from "./images/us-consumer-reviews-customers-reviews.jpeg";
import usConsumerReviewsTeaserJpeg from "./images/us-consumer-reviews-teaser.jpeg";
import usConsumerReviewsTeaserMobilePng from "./images/us-consumer-reviews-teaser-mobile.png";
import usEnterpriseCampaignMtcGif from "./animations/us-enterprise-campaign-mtc.gif";
import usEnterpriseCampaignMtcMp4 from "./animations/us-enterprise-campaign-mtc.mp4";
import usEnterpriseCutCostsGif from "./cards/us-enterprise-cut-costs.gif";
import usEnterpriseCutCostsMp4 from "./animations/us-enterprise-cut-costs.mp4";
import usEnterpriseLearnerSuccessGif from "./cards/us-enterprise-learner-success.gif";
import usEnterpriseLearnerSuccessMp4 from "./animations/us-enterprise-learner-success.mp4";
import usEnterpriseTopTalentGif from "./cards/us-enterprise-top-talent.gif";
import usEnterpriseTopTalentMp4 from "./animations/us-enterprise-top-talent.mp4";
import usEnterpriseWorkBetterGif from "./cards/us-enterprise-work-better.gif";
import usEnterpriseWorkBetterMp4 from "./animations/us-enterprise-work-better.mp4";
import verdePng from "./product-sample/verde.png";
import verdesPorUnDiaPng from "./product-sample/verdesPorUnDia.png";
import videoStillJourneyJpg from "./images/video_still_journey.jpg";
import yearsBubbleLargePng from "./images/years_bubble_large.png";
import yearsBubbleMobilePng from "./images/years_bubble_mobile.png";
import yearsBubblePng from "./images/years_bubble.png";
import yearsWatermarkPng from "./images/years_watermark.png";
import yellowBlueStonesSvg from "./section-stones/yellow-blue-stones.svg";
import yellowBridgeFooterSvg from "./trustpilot/yellow-bridge-footer.svg";
import yellowBridgeHeaderSvg from "./trustpilot/yellow-bridge-header.svg";
import yellowCurveBottomSvg from "./page-section-borders/yellow-curve-bottom.svg";
import yellowCurveTopSvg from "./page-section-borders/yellow-curve-top.svg";
import yellowStoneSvg from "./section-stones/yellow-stone.svg";
import youtubeDarkSvg from "./social/youtube-dark.svg";
import youtubeIconSvg from "./images/youtube-icon.svg";
import zonTheGo1Png from "./images/zon-the-go1.png";
import zonTheGo2Png from "./images/zon-the-go2.png";

const services = {
    iconSelector
};
const animations = {
    phoneWhiteSpanishGif,
    phoneWhiteSpanishMp4,
    usEnterpriseCampaignMtcGif,
    usEnterpriseCampaignMtcMp4,
    usEnterpriseCutCostsMp4,
    usEnterpriseLearnerSuccessMp4,
    usEnterpriseTopTalentMp4,
    usEnterpriseWorkBetterMp4
};
const appStore = {
    appStorePng,
    googlePlayPng
};
const avatars = {
    avatarJessicaPng
};
const awards = {
    awardPcMagPng
};
const backgrounds = {
    headerBlueYellowSvg
};
const banners = {
    colorsInItalian_2819dJpg
};
const cards = {
    usEnterpriseCutCostsGif,
    usEnterpriseLearnerSuccessGif,
    usEnterpriseTopTalentGif,
    usEnterpriseWorkBetterGif
};
const features = {
    featuresPhoneActivitiesPng,
    featuresPhoneImmersionPng,
    featuresPhoneLanguagesPng,
    featuresPhoneMobilePng,
    featuresPhoneTruaccentPng,
    featuresPhoneTutoringPng
};
const flagsRound = {
    flagRoundAraSvg,
    flagRoundChiSvg,
    flagRoundDeuSvg,
    flagRoundEbrSvg,
    flagRoundEngSvg,
    flagRoundEscSvg,
    flagRoundEspSvg,
    flagRoundFarSvg,
    flagRoundFraSvg,
    flagRoundGleSvg,
    flagRoundGrkSvg,
    flagRoundHebSvg,
    flagRoundHinSvg,
    flagRoundItaSvg,
    flagRoundJpnSvg,
    flagRoundKorSvg,
    flagRoundLatSvg,
    flagRoundNedSvg,
    flagRoundPolSvg,
    flagRoundPorSvg,
    flagRoundRusSvg,
    flagRoundSveSvg,
    flagRoundTglSvg,
    flagRoundTurSvg,
    flagRoundVieSvg
};
const homeschool = {
    homeschoolerJpg,
    phoneFanoutPng
};
const icons = {
    icon_24Svg,
    iconAssessmentsSvg,
    iconBookPng,
    iconBriefcasePng,
    iconCartSvg,
    iconDynamicSvg,
    iconFoundationalSvg,
    iconGamesSvg,
    iconGlobePng,
    iconHeartPng,
    iconImplementationSvg,
    iconMobileSvg,
    iconPlacementSvg,
    iconProficiencySvg,
    iconReadingSvg,
    iconReportingSvg,
    iconTrainingSvg,
    iconTransferableSvg,
    iconTruaccentSvg,
    iconTutoringSvg,
    iconSearchSvg
};
const images = {
    busWebHomepage_2019Png,
    constructionPhoto1Png,
    constructionPhoto2Png,
    constructionHeroPng,
    foodservicePhoto1Png,
    foodservicePhoto2Png,
    foodserviceHeroPng,
    homepageWomanOnBalconyMobileJpg,
    homepageWomanOnBalconyJpg,
    hospitalityPhoto1Png,
    hospitalityPhoto2Png,
    hospitalityHeroPng,
    manufacturingPhoto1Png,
    manufacturingPhoto2Png,
    manufacturingHeroPng,
    maunfacturingPhoto2Png,
    playBtnPng,
    proServicesPhoto1Png,
    proServicesPhoto2Png,
    proServicesHeroPng,
    retailPhoto1Png,
    retailPhoto2Png,
    retailHeroPng,
    badgePng,
    bgBlueJpg,
    bgGreyJpg,
    bgYellowJpg,
    blueBridgeBottomSvg,
    blueBridgeTopSvg,
    blueSpinnerGif,
    blueStoneWatermarkSvg,
    businessCirclePng,
    cartPng,
    cat2TutorScreenPng,
    christopherJpeg,
    closePng,
    cnnBgPng,
    cnnLogoShadowedPng,
    delaineJpeg,
    deviceLockupLargePng,
    educatorsPng,
    emptyIphonePng,
    enterprisePng,
    facebookIconSvg,
    fb2Png,
    fitbrainsBgJpg,
    googlePlusIconSvg,
    hedCirclePng,
    hedSection_1ImgPng,
    hedSection_5ImgPng,
    homeschoolBgJpg,
    iphoneBluePng,
    iphoneBlue_2xPng,
    iphonex_24Png,
    iphonexEngagingPng,
    iphonexImmersionPng,
    iphonexMobilePng,
    iphonexReportingtoolsPng,
    iphonexTruaccentPng,
    iphonexTutoringPng,
    k12CirclePng,
    k12Section_1ImgPng,
    k12Section_5ImgPng,
    kidsBgJpg,
    kidsPng,
    langLearnJpg,
    lightboxJpg,
    macworldBgPng,
    macworldLogoShadowedPng,
    mastheadBgDesktopPng,
    mastheadBgDesktopFranceJpg,
    mastheadBgMobilePng,
    mastheadBgMobileFranceJpg,
    mobileAppsAnotherReasonJpeg,
    mobileAppsImage_1Jpg,
    mobileAppsImage_2Jpg,
    mobileAppsImage_3Png,
    mobileAppsImage_4Jpg,
    mobileAppsLearningFeaturesPng,
    mobileAppsSpeakConfidentlyJpeg,
    mobileAppsTeaserSectionJpeg,
    newWindowPng,
    nunoJpeg,
    opdemo2AudioCompanionPng,
    opdemo2OnlineLockupPng,
    opdemo2PhonePng,
    opdemo2ProvenMethodPng,
    opdemo2TruaccentPng,
    opdemo2TutorScreenPng,
    pricingBgSvg,
    pubCirclePng,
    pubsecSection_1ImgPng,
    pubsecSection_5ImgPng,
    ratingsStarSvg,
    relatedBottomSvg,
    relatedTopSvg,
    resourcesPng,
    reviewsVideoAntoniaPng,
    reviewsVideoChrisPng,
    reviewsVideoDelainePng,
    reviewsVideoGracePng,
    reviewsVideoMarkPng,
    reviewsVideoNunoPng,
    screenAssessmentPng,
    screenImplementationPng,
    screenPlacementPng,
    screenReportingPng,
    screenTestingPng,
    screenTrainingPng,
    section_1Img_1Png,
    section_1Img_1_2xPng,
    section_1Img_2Png,
    section_1StoneGreySvg,
    section_2StoneBlueSvg,
    section_3StoneGreySvg,
    section_4StoneYellowSvg,
    section_5Img_1Png,
    section_5Img_1_2xPng,
    section_5Img_2Png,
    section_5Img_2_2xPng,
    section_5StoneGreySvg,
    section_6StoneGreySvg,
    section_7StoneBlueSvg,
    solHealthcareSection_2ImgPng,
    solHealthcareSection_4ImgPng,
    solK12Section_1ImgJpg,
    solK12Section_2ImgPng,
    solK12Section_4ImgPng,
    solSection_1ImgPng,
    solSection_1StoneBlueSvg,
    solSection_2ImgPng,
    solSection_3StoneYellowSvg,
    solSection_4ImgPng,
    solSection_7StoneBlueSvg,
    solSection_7StoneYellowSvg,
    supportPng,
    techcrunchBgPng,
    techcrunchLogoShadowedPng,
    thankYouMultilanguagePng,
    threeWomenPhonePng,
    triScreenLockupV2Png,
    triScreenLockupPng,
    tutorScreenPng,
    tutorScreen_0_5xPng,
    tutorScreen_2xPng,
    twitterIconSvg,
    usConsumerReviewsAnotherReasonJpeg,
    usConsumerReviewsCustomersReviewsJpeg,
    usConsumerReviewsTeaserMobilePng,
    usConsumerReviewsTeaserJpeg,
    videoStillJourneyJpg,
    yearsBubblePng,
    yearsBubbleLargePng,
    yearsBubbleMobilePng,
    yearsWatermarkPng,
    youtubeIconSvg,
    zonTheGo1Png,
    zonTheGo2Png
};
const ixlBranding = {
    ixlLogoSvg
};
const logos = {
    lausdLogoSvg,
    logoAmazonSvg,
    logoAmgenPng,
    logoAndroidSvg,
    logoAppleWatchSvg,
    logoBarSFoodSigmaPng,
    logoBellevueJpg,
    logoBestBuySvg,
    logoBethelPng,
    logoBmwPng,
    logoBullittPng,
    logoCalvinkleinSvg,
    logoCanterburyJpg,
    logoCaterpillarPng,
    logoChickasawPng,
    logoCincinnatiPng,
    logoClarksvilleJpg,
    logoCnnBgSvg,
    logoCnnSvg,
    logoCoronaPng,
    logoCrowderPng,
    logoCvsHealthPng,
    logoDprPng,
    logoEonPng,
    logoFairfaxPng,
    logoFenderSvg,
    logoFicoPng,
    logoFlorettePng,
    logoGeorgesPng,
    logoGilbanePng,
    logoGmPng,
    logoGuthrieJpg,
    logoHarrogateJpg,
    logoHitachiPng,
    logoHumboldtPng,
    logoIosSvg,
    logoKimberlyClarkPng,
    logoKonicaPng,
    logoLamResearchPng,
    logoLeclercPng,
    logoLibertyPng,
    logoMacworldBgSvg,
    logoMacworldSvg,
    logoMarriottInternationalPng,
    logoMashableSvg,
    logoNeaJpg,
    logoNorlingtonJpg,
    logoNorthernBorderPng,
    logoNycHealthPng,
    logoOreillyPng,
    logoPcmag_2Png,
    logoPcmagPng,
    logoPhilipMorrisInternationalPng,
    logoPhoenixPng,
    logoPphePng,
    logoPuebloPng,
    logoRaleighJpg,
    logoSamsungSvg,
    logoSonyPicturesPng,
    logoTargetSvg,
    logoTechcrunchBgSvg,
    logoTechcrunchLightSvg,
    logoTechcrunchSvg,
    logoTishmanSpeyerPng,
    logoTripadvisorPng,
    logoTripadvisorSvg,
    logoTripadvisor2Png,
    logoTwitterPng,
    logoUcbPng,
    logoViPng,
    logoVodafonePng,
    logoWilliamsSonomaPng,
    logoRstPng,
    logoWalmartSvg
};
const logosTiles = {
    logosTilesAmgenSvg,
    logosTilesBarSSvg,
    logosTilesBmwSvg,
    logosTilesCaterpillarSvg,
    logosTilesCrowderSvg,
    logosTilesCvsSvg,
    logosTilesDprSvg,
    logosTilesGeorgesSvg,
    logosTilesGmSvg,
    logosTilesLamSvg,
    logosTilesLeclercSvg,
    logosTilesLegoSvg,
    logosTilesMarriottSvg,
    logosTilesNycHealthSvg,
    logosTilesOreillySvg,
    logosTilesPmiSvg,
    logosTilesPpheSvg,
    logosTilesTishSvg,
    logosTilesTwitterSvg,
    logosTilesUberSvg,
    logosTilesViSvg,
    logosTilesWsSvg
};
const pageSectionBorders = {
    blueCurveBottomSvg,
    blueCurveTopSvg,
    grayCurveBottomSvg,
    grayCurveTopSvg,
    yellowCurveBottomSvg,
    yellowCurveTopSvg
};
const productSample = {
    azulPng,
    azulesPorUnDiaPng,
    bicicletaAmarillaPng,
    bicicletaAzulPng,
    correctSvg,
    dosBicicletasAzulesPng,
    dosBicicletasDiferentesPng,
    verdePng,
    verdesPorUnDiaPng
};
const productUnits = {
    productUnit_1ApplePng,
    productUnit_10LifePng,
    productUnit_11EverydayThingsPng,
    productUnit_12PlacesEventsPng,
    productUnit_13TourismPng,
    productUnit_14ProfessionPng,
    productUnit_15AtHomePng,
    productUnit_16StylePng,
    productUnit_17BusinessPng,
    productUnit_18ArtPng,
    productUnit_19EmergencyPng,
    productUnit_2TeaPng,
    productUnit_20FamilyPng,
    productUnit_3PencilPng,
    productUnit_4WalletPng,
    productUnit_5PassportPng,
    productUnit_6PastFuturePng,
    productUnit_7MobilePng,
    productUnit_8DiningPng,
    productUnit_9HealthPng
};
const profile = {
    leadershipMatthewHulettJpg
};
const profileCard = {
    gladysJpg,
    jimJpg,
    syJpg,
    quoteCirlceSvg,
    starSvg
};
const rsBranding = {
    faviconIco,
    rsIconWhiteSvg,
    rsIconSvg,
    rsLogoBlackSvg
};
const sectionStones = {
    blueDarkStoneSvg,
    blueStoneSvg,
    grayStoneSvg,
    yellowBlueStonesSvg,
    yellowStoneSvg
};
const social = {
    facebookDarkSvg,
    instagramDarkSvg,
    linkedinDarkPng,
    twitterDarkSvg,
    youtubeDarkSvg
};
const textEmphasis = {
    brushYellowBracketLeftPng,
    brushYellowBracketRightPng,
    brushYellowCirclePng,
    brushYellowCornerLeftPng,
    brushYellowCornerRightPng,
    brushYellowLinePng,
    brushYellowListCircle_1Png,
    brushYellowListCircle_2Png,
    brushYellowListCircle_3Png,
    brushYellowListCircle_4Png,
    brushYellowSparkLeftPng,
    brushYellowSparkRightPng
};
const trustpilot = {
    yellowBridgeFooterSvg,
    yellowBridgeHeaderSvg
};
const verticals = {
    educationPrimaryCirclePng,
    educationSecondaryCirclePng,
    enterpriseCirclePng,
    publicsectorCirclePng
};

export {
    services,
    animations,
    appStore,
    avatars,
    awards,
    backgrounds,
    banners,
    cards,
    features,
    flagsRound,
    homeschool,
    icons,
    images,
    ixlBranding,
    logos,
    logosTiles,
    pageSectionBorders,
    productSample,
    productUnits,
    profile,
    profileCard,
    rsBranding,
    sectionStones,
    social,
    textEmphasis,
    trustpilot,
    verticals
};
