import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import PricingSection from "../PricingSection/PricingSection";
import deprecated from "../deprecated";

const ContactWrapper = styled.div`
    section {
        margin-bottom: 0;
    }

    .pricing__body {
        padding-top: 2em;
        padding-bottom: 2em;
    }
`;

const Contact = ({ copyData, vertical, domain }) => (
    <ContactWrapper>
        <PricingSection
            pricing_title={copyData.pricing_title}
            pricing_subtitle={copyData.pricing_subtitle}
            pricing_thanks={copyData.pricing_thanks}
            domain={domain}
            vertical={vertical}
            copyData={copyData}
            hideTopBorder
        />
    </ContactWrapper>
);

Contact.propTypes = {
    copyData: PropTypes.shape({
        pricing_title: PropTypes.string.isRequired,
        pricing_subtitle: PropTypes.string.isRequired,
        pricing_thanks: PropTypes.string.isRequired
    }).isRequired
};

export default deprecated(Contact);
