import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

import Image, { ImagePropTypes } from "../Images/Image";
import Link, { LinkPropTypes } from "../Links/Link";

// Styles
import ChildContentWrapper from "../../component-styles/ChildContentWrapper";
import TitleWrapper, { TitleWrapperPropTypes } from "../../component-styles/TitleWrapper";
import BodyWrapper, { BodyWrapperPropTypes } from "../../component-styles/BodyWrapper";

const VerticalDiv = styled.div`
    label: VerticalDiv;

    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    list-style: none;
    padding: 0 20px;
    text-align: center;
    a {
        text-decoration: none;
        color: black;
        :hover {
            text-decoration: none;
        }
    }

    //Mobile-landscape (and larger)
    @media (min-width: 320px) {
        img {
            max-width: 85%;
        }
        h3 {
            font-family: helvetica_bold, sans-serif;
            padding: 5px;
            font-size: 1.125rem;
        }
        margin-bottom: 30px;
    }
    //Mobile-landscape (and larger)
    @media (min-width: 480px) {
        h3 {
            font-family: helvetica_bold, sans-serif;
            padding-top: 23px;
            font-size: 1.125rem;
        }
    }
    // Tablet-portrait (and larger)
    @media (min-width: 768px) {
        h3 {
            font-family: helvetica_bold, sans-serif;
            padding-top: 23px;
            font-size: 1.125rem;
        }
    }
    // Tablet-landscape (and larger)
    @media (min-width: 992px) {
        h3 {
            font-family: helvetica_bold, sans-serif;
            padding-top: 23px;
            font-size: 1.625rem;
        }
    }
    // Laptops (and longer)
    @media (min-width: 1200px) {
        h3 {
            font-family: helvetica_bold, sans-serif;
            padding-top: 23px;
            font-size: 1.625rem;
        }
    }
`;

const Vertical = ({ link, title, body, image, children }) => (
    <VerticalDiv>
        <Link {...link}>
            {image && <Image {...image} />}
            {title && <TitleWrapper {...title} as={"h3"} />}
            {body && <BodyWrapper {...body} />}
            {children && <ChildContentWrapper>{children}</ChildContentWrapper>}
        </Link>
    </VerticalDiv>
);

export const VerticalPropTypes = {
    dataQa: PropTypes.string,
    link: PropTypes.shape(LinkPropTypes),
    title: PropTypes.shape(TitleWrapperPropTypes),
    body: PropTypes.shape(BodyWrapperPropTypes),
    image: PropTypes.shape(ImagePropTypes)
};
Vertical.propTypes = VerticalPropTypes;
Vertical.defaultProps = {};
export default Vertical;
