import React from "react";
import PropTypes from "prop-types";
import { componentServices } from "@resourcehub/resourcehub-services";
import { fieldTypes } from "../../component-proptypes";

const LastName = ({ dataQa, error, data, handleInputChange, label }) => (
    <div className={componentServices.createFieldClasses(error, data)}>
        <input
            data-qa={dataQa}
            id="family-name"
            onChange={handleInputChange}
            value={data}
            autoComplete="family-name"
            type="text"
            name="last_name"
            required
            maxLength="255"
        />
        <label data-qa={`${dataQa}-label`} htmlFor="family-name">
            {label}
        </label>
    </div>
);

LastName.propTypes = {
    ...fieldTypes,
    label: PropTypes.string.isRequired
};

export default LastName;
