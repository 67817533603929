import React from "react";
import { Link } from "gatsby";
import "../../styles/card.less";
import deprecated from "../deprecated";

/**
 * Tries to use Gatsby Link for in app routing. Falls back to an anchor when
 * given an absolute path.
 * @param {Object} params props
 * @param {Object} params.node { node: data: { itemInfo } }
 * @param {Object} params.copyData { articleTitles}
 * @param {Object} params.siteMetadata { 'businessLocalized', 'siteURL' }
 * @returns Component
 */
const LinkCard = ({ node, copyData }) => {
    const { title, url } = node.data;
    const { resources_link: resourcesLink, read_more } = copyData;
    if (/(https|http)/.test(url)) {
        // if url is not a relative path (has a protocol), open in new tab
        return (
            <a className="card link-card" href={url} rel="noopener noreferrer" target="_blank">
                <div className="card__content">
                    <div className="card__title">{title}</div>
                    <div className="card__action">{read_more}</div>
                </div>
            </a>
        );
    }
    return (
        <Link className="card link-card" to={`${resourcesLink}${url}/`}>
            <div className="card__content">
                <div className="card__title">{title}</div>
                <div className="card__action">{read_more}</div>
            </div>
        </Link>
    );
};

export default deprecated(LinkCard);
