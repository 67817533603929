import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import ChildContentWrapper from "../../component-styles/ChildContentWrapper";
import Image, { ImagePropTypes } from "./Image";

const ImageBannerWrapperDiv = styled.div`
    label: ImageBannerWrapperDiv;

    align-items: center;
    display: flex;
    flex-direction: column;

    margin: 0;
    padding: 0;

    > div {
        ${({ height }) => (height ? `height: ${height};` : "height: 356px;")};
        ${({ width }) => (width ? `width: ${width};` : "width: 100vw;")};
        object-fit: contain;
    }

    > img {
        ${({ height }) => (height ? `height: ${height};` : "height: 356px;")};
        ${({ width }) => (width ? `width: ${width};` : "width: 100vw;")};
        object-fit: cover;
    }
`;
const ImageBanner = ({ banner, dataQa, children }) => (
    <ImageBannerWrapperDiv data-qa={`content-section-body-${dataQa}`} width="100vw" height="unset">
        <Image {...banner} width="100vw" height="unset" dataQa={dataQa} />
        {children && <ChildContentWrapper>{children}</ChildContentWrapper>}
    </ImageBannerWrapperDiv>
);

export const ImageBannerPropTypes = {
    banner: PropTypes.shape(ImagePropTypes).isRequired,
    dataQa: PropTypes.string
};
ImageBanner.propTypes = ImageBannerPropTypes;
ImageBanner.defaultProps = {};
export default ImageBanner;
