import React from "react";
import styled from "@emotion/styled";
import marked from "marked";
import PropTypes from "prop-types";
import { configServices } from "@resourcehub/resourcehub-services";

import ButtonStyled from "../../components/Buttons/ButtonStyled";
import FreeTrialBanner from "../../components/FreeTrial/FreeTrialBanner";
import GutterWrapper from "../../component-styles/GutterWrapper";
import PageSectionEmpty from "../../page-sections/PageSectionEmpty";
import PageSectionImage from "../../page-sections/PageSectionImage";
import PageSectionImageBanner from "../../page-sections/PageSectionImageBanner";
import PageSectionLink from "../../page-sections/PageSectionLink";
import PageSectionQuoteBranded from "../../page-sections/PageSectionQuoteBranded";
import PageSectionStoneText from "../../page-sections/PageSectionStoneText";
import PageSectionTrustPilot from "../../page-sections/PageSectionTrustPilot";
import PageTemplateWrapper from "../PageTemplateWrapper";
import TitleWrapper from "../../component-styles/TitleWrapper";

const PageWrapper = styled.div`
    label: PageWrapper;
    margin-top: 1em;
    > section {
        margin-top: 1em;
        margin-bottom: 1em;
    }
`;
const PageHeader = styled.header`
    label: PageHeader;
    > div:nth-of-type(1) {
        padding: 20px;
    }
`;

const PageSectionWrapper = styled.div`
    label: PageSectionWrapper;
    margin-top: 10px;
`;
const VideoFrame = styled.iframe`
    label: VideoFrame;
    /* Extra small devices (phones, 600px and down) */
    @media only screen and (max-width: 600px) {
        width: 100vw;
    }

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (min-width: 600px) {
        width: 100vw;
    }

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) {
        width: 90vw;
    }

    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (min-width: 1025px) {
        width: 90vw;
    }

    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1200px) {
        width: 90vw;
    }

    text-align: center;
    max-width: 560px;
    max-height: 315px;
    height: 315px;
`;

// Markdown Rendering Overrides
// https://marked.js.org/#/USING_PRO.md#renderer
const renderer = {
    link(href, title, text) {
        return `
                    <a title="${title}" href=${href} target="_blank" rel="noopener noreferrer">
                        ${text}
                    </a>
               `;
    }
};
marked.use({ renderer });

/* Check Netlify Config file for field data */
class PageTemplateConsumerSeoBasic extends React.Component {
    constructor(props) {
        super(props);

        this.topFreeTrialButtonRef = React.createRef();
        this.footerRef = React.createRef();
        this.state = {
            topFreeTrialButtonVisible: true,
            footerVisible: true
        };
    }

    componentDidMount = () => {
        window.addEventListener("scroll", this.scrollManager);
    };

    componentWillUnmount = () => {
        window.removeEventListener("scroll", this.scrollManager);
    };

    scrollManager = () => {
        const topFreeTrialButtonVisible = this.isScrolledIntoView(this.topFreeTrialButtonRef.current);

        if (topFreeTrialButtonVisible !== this.state.topFreeTrialButtonVisible) {
            this.setState({
                topFreeTrialButtonVisible
            });
        }
        const footerVisible = this.isScrolledIntoView(this.footerRef.current);
        if (footerVisible !== this.state.footerVisible) {
            this.setState({
                footerVisible
            });
        }
    };

    isScrolledIntoView = (el) => {
        const rect = el.getBoundingClientRect();
        const elemTop = rect.top;
        const elemBottom = rect.bottom;

        // Only completely visible elements return true:
        // Partially visible elements return true:
        //isVisible = elemTop < window.innerHeight && elemBottom >= 0;
        return elemTop >= 0 && elemBottom <= window.innerHeight;
    };

    render() {
        const { content, config, context, connected } = this.props;
        const { topFreeTrialButtonVisible, footerVisible } = this.state;

        // Inject dynamic content in page helmet
        config.page.helmet.meta_title = content.meta.meta_title;
        config.page.helmet.meta_description = content.meta.meta_description;
        config.page.helmet.language = content.meta.language;
        config.page.helmet.canonical_url = content.meta.canonical_url;

        //
        config.page.content.email_collection_bar.web_path = context.location.pathname;
        config.page.content.email_collection_bar.language = content.meta.language;

        // data manipulation
        content.header.visible = content.header.title && content.header.description;
        content.page_sections.visible = Array.isArray(content.page_sections) && content.page_sections.length > 0;

        return (
            <PageTemplateWrapper config={config} connected={connected}>
                <PageWrapper>
                    {/* Page Content */}
                    <>
                        {configServices.hasChildObjectVisible(content, "header") &&
                            content.header.title &&
                            content.header.description && (
                                <GutterWrapper>
                                    <PageHeader>
                                        <TitleWrapper align="center" heading="div" theme="black" type="page-title">
                                            {content.header.title}
                                        </TitleWrapper>
                                        <TitleWrapper align="center" heading="div" theme="black" type="page-sub-title">
                                            {content.header.description}
                                        </TitleWrapper>
                                    </PageHeader>
                                </GutterWrapper>
                            )}
                        {configServices.hasChildObjectVisible(content, "header") &&
                            content.header.cta_label &&
                            content.header.cta_destination && (
                                <GutterWrapper>
                                    <PageSectionEmpty>
                                        <div ref={this.topFreeTrialButtonRef}>
                                            <ButtonStyled
                                                text={content.header.cta_label}
                                                size="regular"
                                                theme="blue_white"
                                                destination={content.header.cta_destination}
                                                new_window={true}
                                            />
                                        </div>
                                    </PageSectionEmpty>
                                </GutterWrapper>
                            )}
                        {configServices.hasChildObjectVisible(content, "page_sections") &&
                            Array.isArray(content.page_sections) &&
                            content.page_sections.map((page_section, psIndex) => (
                                <PageSectionWrapper key={`page-section-${psIndex}`}>
                                    <PageSectionImageBanner
                                        panels={[
                                            {
                                                banner: {
                                                    alt: page_section.banner_alt,
                                                    asset_static: page_section.banner_image,
                                                    height: "100%",
                                                    width: "100%"
                                                }
                                            }
                                        ]}
                                        dataQa={`page-section-${psIndex}-banner`}
                                    />

                                    {page_section.content_sections &&
                                        Array.isArray(page_section.content_sections) &&
                                        page_section.content_sections.map((content_section, csIndex) => (
                                            <GutterWrapper key={`content-section-${csIndex}`}>
                                                <PageSectionStoneText
                                                    title={content_section.title}
                                                    titleFlare={content_section.title_flare}
                                                    titleHType={content_section.title_h_type}
                                                    body={{
                                                        align: "left",
                                                        content: marked(content_section.content || ""),
                                                        dangerous: true
                                                    }}
                                                    dataQa={`section-content-${csIndex}`}
                                                    childPosition={content_section.child_position || "bottom"}
                                                >
                                                    {content_section.video_url && (
                                                        <VideoFrame
                                                            src={content_section.video_url}
                                                            frameBorder="0"
                                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                            allowFullScreen
                                                        />
                                                    )}
                                                </PageSectionStoneText>
                                            </GutterWrapper>
                                        ))}
                                </PageSectionWrapper>
                            ))}
                        {configServices.hasChildObjectVisible(content, "ratings") && (
                            <PageSectionTrustPilot
                                button_destination={content.ratings.cta_destination}
                                button_label={content.ratings.cta_label}
                                button_visible={!!content.ratings.cta_label && !!content.ratings.cta_destination}
                                backdrop_visible={true}
                                dataQa={"sections-ratings"}
                                language={content.meta.language}
                                title={{
                                    align: "center",
                                    content: content.ratings.title,
                                    theme: "black",
                                    type: "section-title"
                                }}
                                title_visible={!!content.ratings.title}
                                skus={content.ratings.skus}
                            />
                        )}
                        {configServices.hasChildObjectVisible(content, "related") && (
                            <GutterWrapper>
                                <PageSectionLink
                                    title={{
                                        align: "center",
                                        content: content.related.title,
                                        theme: "black",
                                        type: "section-title"
                                    }}
                                    panels={content.related.pages}
                                    dataQa="sections-linklist"
                                />
                            </GutterWrapper>
                        )}
                    </>

                    {configServices.hasChildObjectVisible(config, "footer") && <div ref={this.footerRef} />}
                </PageWrapper>
            </PageTemplateWrapper>
        );
    }
}

PageTemplateConsumerSeoBasic.propTypes = {
    content: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    context: PropTypes.object.isRequired,
    connected: PropTypes.bool.isRequired
};
PageTemplateConsumerSeoBasic.defaultProps = {
    connected: true
};

export default PageTemplateConsumerSeoBasic;
