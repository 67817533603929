import React from "react";
import PropTypes from "prop-types";
import { navigate } from "gatsby";
import { domainType, verticalType } from "../../component-proptypes";
import Form from "./Form";
import ButtonStyled from "../../components/Buttons/ButtonStyled";
import deprecated from "../deprecated";

/**
 * Wraps the Form Modal with a CTA button. Can be used as Primary or Secondary
 * CTA based on isSecondary prop.
 */
class FormModalCTA extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            modalIsOpen: false,
            thanksModalIsOpen: false
        };
    }

    openModal = () => {
        this.setState({ modalIsOpen: true });
    };

    closeModal = () => {
        this.setState({ modalIsOpen: false });
    };

    afterSubmit = () => {
        const { afterSubmit, copyData, vertical } = this.props;
        if (afterSubmit) {
            afterSubmit();
        } else if (
            (copyData.market.toString() === "US" || copyData.market.toString() === "UK") &&
            vertical === "business"
        ) {
            afterSubmit();
        } else {
            this.setState({
                modalIsOpen: false,
                thanksModalIsOpen: true
            });
        }
    };

    closeThanksModal = () => {
        this.setState({ thanksModalIsOpen: false });
    };

    render() {
        const { dataQa, btn_title, copyData, domain, page, pricing_subtitle, pricing_title, vertical } = this.props;
        const { modalIsOpen, thanksModalIsOpen } = this.state;
        return (
            <span>
                <ButtonStyled
                    dataQa={dataQa}
                    size="regular"
                    text={btn_title}
                    theme="yellow_black"
                    onClick={this.openModal}
                />
                <Form
                    afterSubmit={this.afterSubmit}
                    closeModal={this.closeModal}
                    closeThanksModal={this.closeThanksModal}
                    copyData={copyData}
                    domain={domain}
                    page={page}
                    modalIsOpen={modalIsOpen}
                    pricing_subtitle={pricing_subtitle}
                    pricing_title={pricing_title}
                    thanksModalIsOpen={thanksModalIsOpen}
                    vertical={vertical}
                />
            </span>
        );
    }
}

FormModalCTA.propTypes = {
    btn_title: PropTypes.string.isRequired,
    pricing_title: PropTypes.string.isRequired,
    pricing_subtitle: PropTypes.string.isRequired,
    domain: domainType.isRequired,
    vertical: verticalType.isRequired,
    copyData: PropTypes.shape({}).isRequired,
    isSecondary: PropTypes.bool,
    dataQa: PropTypes.string
};

FormModalCTA.defaultProps = {
    isSecondary: true,
    dataQa: null
};

export default deprecated(FormModalCTA);
