import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import TitleWrapper, { TitleWrapperPropTypes } from "../../component-styles/TitleWrapper";
import Image, { ImagePropTypes } from "../Images/Image";
import BodyWrapper, { BodyWrapperPropTypes } from "../../component-styles/BodyWrapper";

const CardStyle = styled.div`
    label: Card;

    background-color: #fff;
    height: 400px;
    max-width: 300px;
    width: 300px;
    min-width: 300px;
    box-shadow: 0 0 20px grey;
    border-color: #ccc;
    border-style: solid;
    border-width: 1px;
    border-radius: 8px;
    text-align: center;

    /* Center slide text vertically */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 22px;
`;
const VideoMask = styled.div`
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow: hidden;
`;
const CardInfo = ({ active, image, title, body, video }) => (
    <CardStyle active={active}>
        {video && (
            <VideoMask>
                <video width={"100%"} autoPlay={true} loop={true}>
                    <source {...video} />
                </video>
            </VideoMask>
        )}
        {image && <Image {...image} />}
        {title && <TitleWrapper {...title} />}
        {body && <BodyWrapper {...body} />}
    </CardStyle>
);

export const CardInfoPropTypes = {
    active: PropTypes.bool,
    image: PropTypes.shape(ImagePropTypes),
    video: PropTypes.object,
    title: PropTypes.shape(TitleWrapperPropTypes),
    body: PropTypes.shape(BodyWrapperPropTypes)
};
CardInfo.propTypes = CardInfoPropTypes;
CardInfo.defaultProps = {};
export default CardInfo;
