import React from "react";
import { connect } from "react-redux";
import styled from "@emotion/styled";
import Cookies from "js-cookie";

import { leadGenerationStore } from "@resourcehub/resourcehub-data";
import { verticalServices } from "@resourcehub/resourcehub-services";

import Comments from "./Comments";
import Company from "./Company";
import Country from "./Country";
import deprecated from "../deprecated";
import Email from "./Email";
import ExplicitOptIn from "../../components/LeadGeneration/ExplicitOptIn";
import FirstName from "./FirstName";
import HowHear from "./HowHear";
import InstitutionSize from "./InstitutionSize";
import LastName from "./LastName";
import Phone from "./Phone";
import Role from "./Role";
import SolutionInterest from "./SolutionInterest";
import Submit from "./Submit";
import { verticalType, domainType } from "../../component-proptypes";

import "../../styles/contactform.less";
import "../../styles/button.less";
import { getAllData } from "@resourcehub/resourcehub-data/src/store/lead-generation/lead-generation-selectors";

const InputCentered = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 100%;
`;

export class GateForm extends React.Component {
    constructor(props) {
        super(props);
        this.formRef = React.createRef();
    }

    componentDidMount() {
        const {
            assetType,
            CampaignID,
            domain,
            eloquaDataRequest,
            handleHardCodedFormData,
            hasEloquaSuccess,
            importSourceMostRecent1,
            lastAccessedAssetID1,
            leadSourceMostRecent1,
            page,
            parseQueryParams,
            setLocationData,
            vertical
        } = this.props;

        if (!hasEloquaSuccess) {
            eloquaDataRequest();
        }
        const locationDataString = Cookies.get("onetrustGeolocationData") || null;
        const locationData = JSON.parse(locationDataString);
        if (locationData) {
            setLocationData({
                country: locationData.country_code,
                state: locationData.state_code
            });
        }
        const argsForDerivation = { domain, vertical, page };
        const directValues = {
            CampaignID,
            leadSourceMostRecent1,
            importSourceMostRecent1,
            lastAccessedAssetID1,
            assetType
        };
        handleHardCodedFormData(argsForDerivation, directValues);
        parseQueryParams();
    }

    handleSubmit = () => {
        const { afterSubmit, vertical, prepareUserSubmitToEloqua } = this.props;
        if (this.isValid()) {
            prepareUserSubmitToEloqua(vertical);
            afterSubmit && afterSubmit();
        }
    };

    isValid = () => {
        const { processFieldError, verifyFormValidity } = this.props;
        const formLength = this.formRef.current.length;
        let formValid = true;

        for (let i = 0; i < formLength; i += 1) {
            const el = this.formRef.current[i];

            // skip submit & phone (optional)
            if (!(el.type === "tel" || el.type === "button")) {
                processFieldError(el.name, !el.validity.valid);
                if (!el.validity.valid) formValid = false;
            }
        }
        verifyFormValidity(formValid);
        return formValid;
    };

    render() {
        const { dataQaLocation, formData, vertical, copyData, domain, submitText, changeFieldValue } = this.props;
        const market = copyData.market.toString().toLowerCase();
        const noOrgSizeOrHowHear = market === "us" || market === "de" || market === "uk";
        const addComments = market === "de" || market === "uk";
        const commentsInputLabel = market === "de" ? "Wie können wir Ihnen weiter helfen?" : "How Can We Help?";
        const usEducationPrimary = market === "us" && (vertical === "k12" || vertical === "education-primary");
        return (
            <form ref={this.formRef} id="eAndEForm" onSubmit={this.handleSubmit}>
                <div id="eAndEFormContainer">
                    <div className="inputs">
                        <div className="inputs__group">
                            <FirstName
                                dataQa={`${dataQaLocation}-gate-form-first-name-input-field`}
                                error={formData.first_name_error}
                                data={formData.first_name}
                                handleInputChange={changeFieldValue}
                                label={copyData.form_firstname}
                            />
                            <LastName
                                dataQa={`${dataQaLocation}-gate-form-last-name-input-field`}
                                error={formData.last_name_error}
                                data={formData.last_name}
                                handleInputChange={changeFieldValue}
                                label={copyData.form_lastname}
                            />
                            <Email
                                dataQa={`${dataQaLocation}-gate-form-email-input-field`}
                                error={formData.email_error}
                                data={formData.email}
                                handleInputChange={changeFieldValue}
                                label={copyData.form_email}
                            />
                            {market !== "us" && (
                                <Phone
                                    dataQa={`${dataQaLocation}-gate-form-phone-number-input-field`}
                                    error={formData.phone_error}
                                    data={formData.phone}
                                    handleInputChange={changeFieldValue}
                                    label={copyData.form_phone}
                                />
                            )}
                            {verticalServices.isCombinedVertical(market) && (
                                <Country
                                    dataQa={`${dataQaLocation}-gate-form-country-input-field`}
                                    error={formData.country_error}
                                    data={formData.country}
                                    handleInputChange={changeFieldValue}
                                />
                            )}
                        </div>
                        <div className="inputs__group">
                            {market === "us" && (
                                <Phone
                                    dataQa={`${dataQaLocation}-gate-form-phone-number-input-field`}
                                    error={formData.phone_error}
                                    data={formData.phone}
                                    handleInputChange={changeFieldValue}
                                    label={copyData.form_phone}
                                />
                            )}
                            <Company
                                dataQa={`${dataQaLocation}-gate-form-company-input-field`}
                                error={formData.company_error}
                                data={formData.company}
                                handleInputChange={changeFieldValue}
                                label={copyData.form_organization}
                            />
                            {!noOrgSizeOrHowHear && (
                                <InstitutionSize
                                    dataQa={`${dataQaLocation}-gate-form-institution-size-input-field`}
                                    error={formData.institutionSize1_error}
                                    data={formData.institutionSize1}
                                    handleInputChange={changeFieldValue}
                                    labels={copyData}
                                />
                            )}
                            <Role
                                dataQa={`${dataQaLocation}-gate-form-role-input-field`}
                                vertical={vertical}
                                error={formData.role_error}
                                data={formData.role}
                                handleInputChange={changeFieldValue}
                                labels={copyData}
                            />
                            {!noOrgSizeOrHowHear && (
                                <HowHear
                                    dataQa={`${dataQaLocation}-gate-form-how-hear-input-field`}
                                    error={formData.howDidYouHearAboutUs1_error}
                                    data={formData.howDidYouHearAboutUs1}
                                    handleInputChange={changeFieldValue}
                                    labels={copyData}
                                />
                            )}
                            {addComments && (
                                <Comments
                                    dataQa={`${dataQaLocation}-gate-form-comments-input-field`}
                                    error={formData.anythingtoadd_error}
                                    data={formData.anythingtoadd}
                                    handleInputChange={changeFieldValue}
                                    label={commentsInputLabel}
                                />
                            )}
                            <ExplicitOptIn
                                dataQa={`${dataQaLocation}-gate-form-opt-in-input-field`}
                                data={formData.ExplicitOptIn}
                                handleInputChange={changeFieldValue}
                                labels={copyData}
                                domain={domain}
                            />
                        </div>
                    </div>
                    {usEducationPrimary && (
                        <InputCentered>
                            <SolutionInterest
                                dataQa={`${dataQaLocation}-gate-form-solution-input-field`}
                                vertical={vertical}
                                error={formData.productSolutionOfInterest1_error}
                                data={formData.productSolutionOfInterest1}
                                handleInputChange={changeFieldValue}
                                labels={copyData}
                            />
                        </InputCentered>
                    )}
                    <div className="inputs inputs__submit">
                        <Submit
                            dataQa={`${dataQaLocation}-gate-form-submit-button`}
                            handleSubmit={this.handleSubmit}
                            label={submitText || copyData.form_submit}
                        />
                    </div>
                </div>
            </form>
        );
    }
}

GateForm.propTypes = {
    domain: domainType.isRequired,
    vertical: verticalType.isRequired
};

const mapStateToProps = (state) => ({
    formData: leadGenerationStore.leadGenerationSelectors.getAllData(state)
});

const connectedActions = {
    changeFieldValue: leadGenerationStore.leadGenerationActions.changeFieldValue,
    handleHardCodedFormData: leadGenerationStore.leadGenerationActions.handleHardCodedFormData,
    eloquaDataRequest: leadGenerationStore.leadGenerationActions.eloquaDataRequest,
    prepareUserSubmitToEloqua: leadGenerationStore.leadGenerationActions.prepareUserSubmitToEloqua,
    verifyFormValidity: leadGenerationStore.leadGenerationActions.verifyFormValidity,
    processFieldError: leadGenerationStore.leadGenerationActions.processFieldError,
    setLocationData: leadGenerationStore.leadGenerationActions.geoIpSuccess,
    parseQueryParams: leadGenerationStore.leadGenerationActions.parseQueryParams
};

const GateFormConnected = connect(mapStateToProps, connectedActions)(GateForm);

export default deprecated(GateFormConnected);
