import styled from "@emotion/styled";

const ChildContentWrapper = styled.div`
    label: ChildContentWrapper;

    margin-top: 20px;
    li,
    p {
        color: black;
        font-size: 14pt;
        line-height: 32px;
        list-style: disc;
        text-align: left;
    }
    a {
        color: #0099e1;
        text-align: center;
        font-size: 14pt;
    }

    //Mobile-landscape (and larger)
    @media (min-width: 320px) {
        justify-content: center;
    }
    //Mobile-landscape (and larger)
    @media (min-width: 480px) {
        justify-content: center;
    }
    // Tablet-portrait (and larger)
    @media (min-width: 768px) {
        justify-content: center;
    }
    // Tablet-landscape (and larger)
    @media (min-width: 992px) {
        justify-content: center;
    }
    // Laptops (and longer)
    @media (min-width: 1200px) {
        justify-content: flex-start;
    }
`;

export default ChildContentWrapper;
