import React from "react";
import Modal from "react-modal";
import styled from "@emotion/styled";
import "../../styles/modal.less";
import deprecated from "../deprecated";

function findAppEL() {
    // fallback for handling storybook
    if (typeof window !== "undefined" && window.STORYBOOK_ENV) return "#root";
    return "#___gatsby";
}

const ModalActionWrapper = styled.button`
    border: none !important;
`;

class ModalWrapper extends React.Component {
    constructor() {
        super();

        this.state = {
            modalIsOpen: false
        };
    }

    openModal = () => {
        this.setState({ modalIsOpen: true });
    };

    closeModal = () => {
        this.setState({ modalIsOpen: false });
    };

    componentDidMount() {
        Modal.setAppElement(findAppEL());
    }

    render() {
        const { modalIsOpen } = this.state;
        const { buttonBody, modalBody, buttonClasses } = this.props;
        return (
            <>
                <ModalActionWrapper type="button" onClick={this.openModal} className={buttonClasses}>
                    {buttonBody}
                </ModalActionWrapper>
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={this.closeModal}
                    contentLabel="Example Modal"
                    className="modal"
                    overlayClassName="overlay"
                >
                    <button className="modal__close" type="button" onClick={this.closeModal}>
                        X
                    </button>
                    {modalBody}
                </Modal>
            </>
        );
    }
}

export default deprecated(ModalWrapper);
