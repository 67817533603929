import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import Image, { ImagePropTypes } from "../Images/Image";

const QuoteBlock = styled.div((props) => {
    return {
        label: "QuoteBlock",
        backgroundImage: `url(${props.backdrop})`,
        boxShadow: "0 3px 10px 0 rgba(33, 33, 33, 0.5)",
        boxSizing: "border-box",
        color: "#fff",
        display: "flex",
        flexDirection: "column",
        fontFamily: "effra_bold",
        fontSize: "18px",
        height: "200px",
        justifyContent: "space-between",
        lineHeight: "1.33",
        margin: "0 15px 20px",
        padding: "20px 24px",
        width: "350px",
        "& em": {
            fontFamily: "effra_medium,sans-serif",
            fontWeight: 700
        },
        "& img": {
            bottom: 0,
            height: "35px"
        }
    };
});

const QuoteBranded = ({ dataQa, quote, backdrop, logo }) => (
    <QuoteBlock backdrop={backdrop} data-qa={dataQa}>
        <div dangerouslySetInnerHTML={{ __html: quote }} />
        <div>
            <Image {...logo} />
        </div>
    </QuoteBlock>
);
export const QuoteBrandedPropTypes = {
    logo: PropTypes.shape(ImagePropTypes).isRequired,
    quote: PropTypes.string.isRequired,

    backdrop: PropTypes.string,
    dataQa: PropTypes.string,
    name: PropTypes.string
};
QuoteBranded.propTypes = QuoteBrandedPropTypes;
QuoteBranded.defaultProps = {
    dataQa: "qa"
};
export default QuoteBranded;
