import React from "react";
import PropTypes from "prop-types";
import { images, logos } from "@resourcehub/resourcehub-assets";
import ContentSection from "../ContentSection/ContentSection";
import DemoModalCallToAction from "../Modal/DemoModalCallToAction";
import FormFollowUpModal from "../Modal/FormFollowUpModal";
import LearnerFeatures from "../../components/Features/LearnerFeatures";
import AdminFeatures from "../../components/Features/AdminFeatures";
import SpeakRightCarousel from "../SpeakRightCarousel/SpeakRightCarousel";
import { domainType, verticalType } from "../../component-proptypes";
import "../../styles/home.less";
import deprecated from "../deprecated";
import LeadGenerationButton from "../../components/LeadGeneration/LeadGenerationButton";
import FormModalCTA from "../Modal/FormModalCTA";

const Home = ({ pageData, data, domain, vertical, copyData, hideShared, config }) => {
    const { section_1_img_1, iphone_blue, tutor_screen, section_5_img_1 } = data;
    return (
        <>
            <main className="main home">
                <ContentSection
                    dataQa="1"
                    sectionClasses="section__left section_1"
                    img={section_1_img_1}
                    title={pageData.section_1_title}
                    mainTitle
                    body={pageData.section_1_body}
                    cta_caption={pageData.section_1_cta_caption}
                    resourcesLink={copyData.resources_link}
                    vertical={vertical}
                    hasCombinedResources={copyData.has_combined_resources}
                    market={copyData.market}
                    content_child={
                        <>
                            {pageData.hide_demo &&
                                pageData.section_1_fallback_cta &&
                                pageData.section_1_fallback_video && (
                                    <FormFollowUpModal
                                        buttonClasses="btn btn-blue btn-sm"
                                        buttonBody={pageData.section_1_fallback_cta}
                                        copyData={copyData}
                                        vertical={vertical}
                                        domain={domain}
                                        modalBody={
                                            <iframe title="Business Overview" src={pageData.section_1_fallback_video} />
                                        }
                                    />
                                )}
                            {!pageData.hide_demo && pageData.section_1_fallback_video && (
                                <DemoModalCallToAction
                                    pageData={pageData}
                                    vertical={vertical}
                                    copyData={copyData}
                                    domain={domain}
                                    section_1_fallback_video={pageData.section_1_fallback_video}
                                />
                            )}
                            {config && config.page.content.sections[0].panels[0].custom ? (
                                <LeadGenerationButton
                                    {...config.page.content.sections[0].panels[0].custom.lead_generation_button}
                                />
                            ) : (
                                <FormModalCTA
                                    dataQa="masthead-request-pricing-button"
                                    btn_title={pageData.section_1_secondary_cta}
                                    pricing_title={copyData.pricing_title}
                                    pricing_subtitle={copyData.pricing_subtitle}
                                    domain={domain}
                                    vertical={vertical}
                                    copyData={copyData}
                                />
                            )}
                        </>
                    }
                />
                <ContentSection
                    dataQa="2"
                    sectionClasses="section__right section_2"
                    img_bg={images.section_2StoneBlueSvg}
                    img_child={<SpeakRightCarousel data={data} />}
                    title={pageData.section_2_title}
                    body={pageData.section_2_body}
                    quote={pageData.section_2_quote}
                    link={pageData.section_2_link}
                    logo={logos[pageData.section_2_logo]}
                    resourcesLink={copyData.resources_link}
                    vertical={vertical}
                    hasCombinedResources={copyData.has_combined_resources}
                    market={copyData.market}
                />
                <ContentSection
                    dataQa="3"
                    sectionClasses="section__left section_3"
                    img_bg={images.section_3StoneGreySvg}
                    img_main={iphone_blue}
                    title={pageData.section_3_title}
                    body={pageData.section_3_body}
                    quote={pageData.section_3_quote}
                    link={pageData.section_3_link}
                    logo={logos[pageData.section_3_logo]}
                    resourcesLink={copyData.resources_link}
                    vertical={vertical}
                    hasCombinedResources={copyData.has_combined_resources}
                    market={copyData.market}
                />
                <ContentSection
                    dataQa="4"
                    sectionClasses="section__right section_4"
                    img_bg={images.section_4StoneYellowSvg}
                    img_main={tutor_screen}
                    title={pageData.section_4_title}
                    body={pageData.section_4_body}
                    quote={pageData.section_4_quote}
                    link={pageData.section_4_link}
                    logo={logos[pageData.section_4_logo]}
                    resourcesLink={copyData.resources_link}
                    vertical={vertical}
                    hasCombinedResources={copyData.has_combined_resources}
                    market={copyData.market}
                />
                <ContentSection
                    dataQa="5"
                    sectionClasses="section__left section_5"
                    img={section_5_img_1}
                    title={pageData.section_5_title}
                    body={pageData.section_5_body}
                    quote={pageData.section_5_quote}
                    link={pageData.section_5_link}
                    logo={logos[pageData.section_5_logo]}
                    resourcesLink={copyData.resources_link}
                    vertical={vertical}
                    hasCombinedResources={copyData.has_combined_resources}
                    market={copyData.market}
                />
                <LearnerFeatures
                    sectionClasses="section_6"
                    img_bg={images.section_6StoneGreySvg}
                    pageData={pageData}
                    data={data}
                    isHomePage={true}
                />
                {!pageData.hide_admin && (
                    <AdminFeatures
                        sectionClasses="section_7"
                        img_bg={images.section_7StoneBlueSvg}
                        pageData={pageData}
                        data={data}
                        isHomePage={true}
                    />
                )}
            </main>
            {hideShared && (
                <>
                    <br />
                    <br />
                    <br />
                </>
            )}
        </>
    );
};

Home.propTypes = {
    domain: domainType.isRequired,
    vertical: verticalType.isRequired,
    hideShared: PropTypes.bool
};

Home.defaultProps = {
    hideShared: false
};

export default deprecated(Home);
