import React from "react";
import { services } from "@resourcehub/resourcehub-assets";
import wrapFeatureFrenzy from "./wrapFeatureFrenzy";
import AdminImage from "./AdminImage";
import FeatureButton from "./FeatureButton";
import deprecated from "../deprecated";

const { iconSelector } = services;

const AdminFeatures = ({ data, img_bg, pageData, sectionClasses, selected, changeSelected, handleMouseOut }) => (
    <section className={`app_section section__left admin_features ${sectionClasses}`}>
        <div className="section__img">
            <div className="app_top_content">
                <div className="section__title">{pageData.admin_feature_title}</div>
            </div>
            <AdminImage data={data} selected={selected} img_bg={img_bg} />
        </div>
        <div className="section__content-container app_btns">
            <div className="app_content">
                <FeatureButton
                    index="1"
                    title={pageData.admin_feature_1_title}
                    subtitle={pageData.admin_feature_1_subtitle}
                    icon={iconSelector(pageData.admin_feature_1_icon_name)}
                    selected={selected}
                    changeSelected={changeSelected}
                    handleMouseOut={handleMouseOut}
                />
                <FeatureButton
                    index="2"
                    title={pageData.admin_feature_2_title}
                    subtitle={pageData.admin_feature_2_subtitle}
                    icon={iconSelector(pageData.admin_feature_2_icon_name)}
                    selected={selected}
                    changeSelected={changeSelected}
                    handleMouseOut={handleMouseOut}
                />
                <FeatureButton
                    index="3"
                    title={pageData.admin_feature_3_title}
                    subtitle={pageData.admin_feature_3_subtitle}
                    icon={iconSelector(pageData.admin_feature_3_icon_name)}
                    selected={selected}
                    changeSelected={changeSelected}
                    handleMouseOut={handleMouseOut}
                />
                <FeatureButton
                    index="4"
                    title={pageData.admin_feature_4_title}
                    subtitle={pageData.admin_feature_4_subtitle}
                    icon={iconSelector(pageData.admin_feature_4_icon_name)}
                    selected={selected}
                    changeSelected={changeSelected}
                    handleMouseOut={handleMouseOut}
                />
                <FeatureButton
                    index="5"
                    title={pageData.admin_feature_5_title}
                    subtitle={pageData.admin_feature_5_subtitle}
                    icon={iconSelector(pageData.admin_feature_5_icon_name)}
                    selected={selected}
                    changeSelected={changeSelected}
                    handleMouseOut={handleMouseOut}
                />
                <FeatureButton
                    index="6"
                    title={pageData.admin_feature_6_title}
                    subtitle={pageData.admin_feature_6_subtitle}
                    icon={iconSelector(pageData.admin_feature_6_icon_name)}
                    selected={selected}
                    changeSelected={changeSelected}
                    handleMouseOut={handleMouseOut}
                />
            </div>
        </div>
    </section>
);

export default deprecated(wrapFeatureFrenzy(AdminFeatures));
