import React from "react";
import styled from "@emotion/styled";

const LinkCloudWrapper = styled.div`
    label: LinkCloudWrapper;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    //Mobile-landscape (and larger)
    @media (min-width: 320px) {
        & > div:nth-of-type(1) {
            display: flex;
            flex-direction: row;
            float: right;
            justify-content: end;
        }
        & > div:nth-of-type(2) {
            justify-content: start;
            width: 60%;
        }
    }
    //Mobile-landscape (and larger)
    @media (min-width: 480px) {
        & > div:nth-of-type(1) {
            display: flex;
            flex-direction: row;
            float: right;
            justify-content: end;
        }
        & > div:nth-of-type(2) {
            justify-content: start;
            width: 60%;
        }
    }
    // Tablet-portrait (and larger)
    @media (min-width: 768px) {
        flex-wrap: wrap;
        justify-content: center;
        & > div:nth-of-type(1) {
            margin-top: 40px;
            margin-bottom: 40px;
        }
    }
    // Tablet-landscape (and larger)
    @media (min-width: 992px) {
        flex-wrap: wrap;
        justify-content: center;
        & > div:nth-of-type(1) {
            margin-top: 40px;
            margin-bottom: 40px;
        }
    }
    // Laptops (and longer)
    @media (min-width: 1200px) {
        flex-wrap: wrap;
        justify-content: center;
        & > div:nth-of-type(1) {
            margin-top: 40px;
            margin-bottom: 40px;
        }
    }
`;
const LinkCloudContent = styled.div`
    label: LinkCloudContent;

    flex: 1;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto 70px auto;

    color: #262626;
    display: inline-block;
    font-size: 1rem;
    line-height: 1.5;
    font-family: effra, sans-serif;
    ul {
        li {
            display: inline-block;
            padding: 35px 30px;
        }
    }

    & p {
        padding-bottom: 20px;
    }
    & em {
        font-style: italic;
    }
    & strong {
        font-weight: bolder;
    }
    & a {
        color: #0098db;
    }
    @media (min-width: 1200px) {
        max-width: 1140px;
    }
`;
const LinkCloudTitle = styled.h4`
    label: LinkCloudTitle;

    font-size: 40px;
    font-family: helvetica_bold, sans-serif;
    max-width: 500px;
    margin: 0 auto 50px;
`;
const LinkCloud = ({ title, pages }) => (
    <LinkCloudWrapper>
        <LinkCloudTitle>{title}</LinkCloudTitle>
        <LinkCloudContent>
            <ul>
                {pages.map((page, idx) => (
                    <li key={`page-${idx}`}>
                        <a href={page.url} target="_blank" rel="noopener noreferrer">
                            {page.label}
                        </a>
                    </li>
                ))}
            </ul>
        </LinkCloudContent>
    </LinkCloudWrapper>
);

export default LinkCloud;
