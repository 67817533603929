import React from "react";
import "../../styles/awardsNew.less";
import deprecated from "../deprecated";

const AwardsNew = ({ copyData }) => {
    const {
        award_section_header,
        media_quote_1,
        media_quote_1_bold,
        media_quote_1_end,
        media_quote_2,
        media_quote_2_bold,
        media_quote_2_end,
        media_quote_3,
        media_quote_3_bold,
        media_quote_3_end
    } = copyData;
    return (
        <div className="home2018">
            <section className="social_proof">
                <div className="social_proof_title">{award_section_header}</div>
                <div className="company_logos">
                    <img src="https://www.rosettastone.com/lp/home_2018/assets/tripadvisor.svg" alt="TripAdvisor" />
                    <img src="https://www.rosettastone.com/lp/home_2018/assets/fender.svg" alt="Fender" />
                    <img src="https://www.rosettastone.com/lp/home_2018/assets/ck.svg" alt="Calvin Klein" />
                </div>
                <div className="media_quotes">
                    <div className="media_quote media_macworld">
                        <div className="media_quote_text">
                            {media_quote_1} <span>{media_quote_1_bold}</span>
                            {media_quote_1_end}
                        </div>
                        <div className="media_quote_logo">
                            <img
                                src="https://www.rosettastone.com/lp/home_2018/assets/macworld-logo.svg"
                                alt="Macworld"
                            />
                        </div>
                    </div>
                    <div className="media_quote media_techcrunch">
                        <div className="media_quote_text">
                            {media_quote_2} <span>{media_quote_2_bold}</span>
                            {media_quote_2_end}
                        </div>
                        <div className="media_quote_logo">
                            <img
                                src="https://www.rosettastone.com/lp/home_2018/assets/techcrunch-logo.svg"
                                alt="TechCrunch"
                            />
                        </div>
                    </div>
                    <div className="media_quote media_cnn">
                        <div className="media_quote_text">
                            {media_quote_3} <span>{media_quote_3_bold}</span> {media_quote_3_end}
                        </div>
                        <div className="media_quote_logo">
                            <img src="https://www.rosettastone.com/lp/home_2018/assets/cnn-logo.svg" alt="CNN" />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default deprecated(AwardsNew);
