import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import Swiper from "swiper/bundle";

// Styles
import TitleWrapper, { TitleWrapperPropTypes } from "../component-styles/TitleWrapper";
import PageSectionWrapper from "../component-styles/PageSectionWrapper";
import ChildContentWrapper from "../component-styles/ChildContentWrapper";

// Components
import AnimationText, { AnimationTextPropTypes } from "../components/Animations/AnimationText";

// Carousel
const SwiperContainer = styled.div`
    label: SwiperWrapper;
`;
const SwiperWrapper = styled.div`
    label: SwiperWrapper;
`;
const CarouselContainer = styled.div`
    label: CarouselContainer;

    width: 100%;
    > div {
        margin-left: 2em;
        margin-right: 2em;
    }
`;
const CarouselNavigationBullets = styled.div`
    label: CarouselNavigationBullets;

    .swiper-pagination-bullet {
        background-color: inherit !important;
        border: 2px solid #666666;
        height: 10px;
        margin-left: 13px !important;
        margin-right: 13px !important;
        width: 10px;
    }

    .swiper-pagination-bullet-active {
        background-color: #666666 !important;
    }
`;
const CarouselNavigationNext = styled.div`
    label: CarouselNavigationNext;

    --swiper-navigation-color: #666666;
`;
const CarouselNavigationPrevious = styled.div`
    label: CarouselNavigationPrevious;

    --swiper-navigation-color: #666666;
`;
class AnimationTextCarousel extends React.Component {
    state = {
        activeCard: 1,
        maxCardsBeforePaging: 4
    };
    componentDidMount() {
        const carouselOptions = {
            centeredSlides: true,
            cssMode: true,
            keyboard: true,
            loop: true,
            mousewheel: true,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev"
            },
            pagination: {
                el: ".swiper-pagination"
            }
        };

        new Swiper(".swiper-container", carouselOptions);
    }

    render() {
        const { dataQa, panels, title, children } = this.props;
        if (!panels) return null;
        const { activeCard } = this.state;
        return (
            <CarouselContainer data-qa={`page-section-animation-text-carousel-section-${dataQa}`}>
                {title && <TitleWrapper {...title} data-qa={`page-section-animation-text-carousel-title-${dataQa}`} />}
                {panels && (
                    <SwiperContainer className="swiper-container">
                        <SwiperWrapper className="swiper-wrapper">
                            {panels.map((panel, i) => (
                                <AnimationText
                                    className="swiper-slide"
                                    data-qa={`animation-text-carousel-slide-${dataQa}-${i}`}
                                    key={`animation-text-carousel-slide-${dataQa}-${i}`}
                                    {...panel}
                                    active={activeCard === i + 1}
                                />
                            ))}
                            {/* Add Arrows */}

                            <CarouselNavigationBullets className="swiper-pagination" />
                            <CarouselNavigationNext
                                className="swiper-button-next"
                                visible={panels.length > this.state.maxCardsBeforePaging}
                            />
                            <CarouselNavigationPrevious
                                className="swiper-button-prev"
                                visible={panels.length > this.state.maxCardsBeforePaging}
                            />
                        </SwiperWrapper>
                    </SwiperContainer>
                )}
                {children && (
                    <ChildContentWrapper data-qa={`page-section-animation-text-list-children-${dataQa}`}>
                        {children}
                    </ChildContentWrapper>
                )}
            </CarouselContainer>
        );
    }
}

// List
const AnimationTextListContainer = styled.div`
    label: AnimationTextListContainer;

    display: flex;
    flex-direction: column;
    > div {
        margin-bottom: 50px;
    }
`;
const AnimationTextWrapper = styled(PageSectionWrapper)`
    justify-content: ${({ justifyContent }) => justifyContent};
    padding: ${({ padding }) => padding};
    @media (max-width: 968px) {
        margin: 90px 0;
    }
`;
const AnimationTextList = ({ dataQa, panels, title, justifyContent, maxWidth, padding, children }) => {
    if (!panels) return null;
    return (
        <AnimationTextWrapper
            data-qa={`content-section-header-${dataQa}`}
            justifyContent={justifyContent}
            padding={padding}
        >
            {title && <TitleWrapper {...title} />}
            {panels && (
                <AnimationTextListContainer>
                    {panels.map((panel, i) => (
                        <AnimationText
                            data-qa={`page-section-animation-text-list-${dataQa}-${i}`}
                            key={`page-section-animation-text-list-${dataQa}-${i}`}
                            justifyContent={justifyContent}
                            maxWidth={maxWidth}
                            {...panel}
                        />
                    ))}
                </AnimationTextListContainer>
            )}
            {children && <ChildContentWrapper>{children}</ChildContentWrapper>}
        </AnimationTextWrapper>
    );
};

// Root Component
const PageSectionAnimationText = (props) => {
    switch (props.layout) {
        case "list":
            return <AnimationTextList {...props} />;
        case "carousel":
            return <AnimationTextCarousel {...props} />;
    }
};
const PageSectionAnimationTextPropTypes = {
    dataQa: PropTypes.string,
    layout: PropTypes.oneOf(["carousel", "list"]),

    // Carousel Options
    autoplay: PropTypes.bool,
    autoplayDelay: PropTypes.number,
    justifyContent: PropTypes.string,

    panels: PropTypes.arrayOf(PropTypes.shape(AnimationTextPropTypes)).isRequired,
    title: PropTypes.shape(TitleWrapperPropTypes)
};
PageSectionAnimationText.defaultProps = {
    autoplay: false,
    autoplayDelay: 2500,
    dataQa: "page-section-animation-text",

    layout: "list",
    justifyContent: "center"
};
PageSectionAnimationText.propTypes = PageSectionAnimationTextPropTypes;
export default PageSectionAnimationText;
