import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { configServices } from "@resourcehub/resourcehub-services";
import GlobalStyles from "../component-styles/GlobalStyles.jsx";
import OverlayStyled from "../component-styles/OverlayStyled.jsx";
import HelmetWrapper from "../components/HelmetWrapper/HelmetWrapper";
import NavConnected, { Nav } from "../components/Header/Nav";
import GoogleTagManager from "../components/Analytics/GoogleTagManager";
import GutterWrapper from "../component-styles/GutterWrapper";
import PageSectionVertical from "../page-sections/PageSectionVertical";
import PageSectionImage from "../page-sections/PageSectionImage";
import PageSectionQuoteBranded from "../page-sections/PageSectionQuoteBranded";
import Footer from "../components/Footer/Footer";
import LeadGenerationBarConnected, { LeadGenerationBar } from "../components/LeadGeneration/LeadGenerationBar";
import EmailCollectionBarConnected, { EmailCollectionBar } from "../components/EmailCollection/EmailCollectionBar";

const ContentWrapper = styled.div`
    label: ContentWrapper;

    .gatsby-image-wrapper {
        margin: auto;
    }
`;

class PageTemplateWrapper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: false,
            openDropdown: null,
            cartProducts: [],
            cartPrices: [],
            cartCount: 0
        };
    }

    // Lifecycle Methods
    componentDidMount() {
        window.addEventListener("resize", this.resize);
        this.resize();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize);
    }

    render() {
        const { children, config, connected } = this.props;
        const { openDropdown } = this.state;
        return (
            <div>
                <GlobalStyles />
                {config.page.helmet && <HelmetWrapper {...config.page.helmet} />}
                {configServices.hasChildObjectVisible(config, "nav") && connected && (
                    <NavConnected
                        config={config.nav}
                        openDropdown={openDropdown}
                        setOpenDropdown={this.setOpenDropdown}
                    />
                )}
                {configServices.hasChildObjectVisible(config, "nav") && !connected && (
                    <Nav config={config.nav} openDropdown={openDropdown} setOpenDropdown={this.setOpenDropdown} />
                )}
                <OverlayStyled
                    role="menuitem"
                    showOverlay={openDropdown !== null && openDropdown !== "Mobile Nav"}
                    onClick={this.overlayClick}
                />
                <ContentWrapper>{children}</ContentWrapper>
                {configServices.hasChildObjectVisible(config.page.content, "verticals") && (
                    <GutterWrapper>
                        <PageSectionVertical {...config.page.content.verticals} />
                    </GutterWrapper>
                )}
                {configServices.hasChildObjectVisible(config.page.content, "awards") && (
                    <GutterWrapper>
                        <PageSectionImage {...config.page.content.awards} />
                    </GutterWrapper>
                )}
                {configServices.hasChildObjectVisible(config.page.content, "branded_quotes") && (
                    <GutterWrapper>
                        <PageSectionQuoteBranded {...config.page.content.branded_quotes} />
                    </GutterWrapper>
                )}
                {configServices.hasChildObjectVisible(config.page.content, "lead_generation_bar") && connected && (
                    <LeadGenerationBarConnected {...config.page.content.lead_generation_bar} />
                )}
                {configServices.hasChildObjectVisible(config.page.content, "lead_generation_bar") && !connected && (
                    <LeadGenerationBar {...config.page.content.lead_generation_bar} />
                )}
                {configServices.hasChildObjectVisible(config.page.content, "email_collection_bar") && connected && (
                    <EmailCollectionBarConnected {...config.page.content.email_collection_bar} />
                )}
                {configServices.hasChildObjectVisible(config.page.content, "email_collection_bar") && !connected && (
                    <EmailCollectionBar {...config.page.content.email_collection_bar} />
                )}
                {configServices.hasChildObjectVisible(config, "footer") && (
                    <Footer config={config.footer} eloquaPage={config.page.eloquaPage} />
                )}
                <GoogleTagManager gtm_id={config.analytics.gtm_id} />
            </div>
        );
    }

    // Custom Methods
    setOpenDropdown = (openDropdown) => {
        this.setState({
            openDropdown
        });
    };

    resize = () => {
        const MOBILE_BREAKPOINT = 1079;
        const { openDropdown, isMobile } = this.state;
        this.setState({
            isMobile: document.body.getBoundingClientRect().width <= MOBILE_BREAKPOINT
        });
        if (!isMobile && openDropdown === "Mobile Nav") {
            this.setState({
                openDropdown: null
            });
        } else if (isMobile && openDropdown === "Languages & Pricing") {
            this.setState({
                openDropdown: null
            });
        }
    };

    overlayClick = () => {
        this.setState({
            openDropdown: null
        });
    };
}

PageTemplateWrapper.propTypes = {
    config: PropTypes.object,
    children: PropTypes.node,
    connected: PropTypes.bool
};
PageTemplateWrapper.defaultProps = {
    connected: true
};

export default PageTemplateWrapper;
