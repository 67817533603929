import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import MultiSelect from "@khanacademy/react-multi-select";
import { fieldTypes } from "../../component-proptypes";

const SelectWrapper = styled.div`
    /* Extra small devices (phones, 600px and down) */
    @media only screen and (max-width: 600px) {
        min-width: 294px;
    }

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (min-width: 600px) {
        min-width: 294px;
    }

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) {
        min-width: 455px;
    }

    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (min-width: 1025px) {
        min-width: 690px;
    }

    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1200px) {
        min-width: 690px;
    }

    margin: auto auto 20px;
    .dropdown-heading {
        height: 44px !important;
        border-radius: 8px !important;
    }
    .dropdown-heading-value {
        padding-top: 4px !important;
        span {
            font-size: 13px !important;
            color: #777 !important;
        }
    }
    .dropdown-content {
        margin-top: -5px !important;
        font-size: 13px !important;
        color: #777 !important;
    }
`;

class SolutionInterest extends React.Component {
    state = {
        selected: [],
        options: [
            {
                label: "Elementary English Language Learning",
                value: "Elementary English Language Learning"
            },
            {
                label: "Secondary English Language Learning",
                value: "Secondary English Language Learning"
            },
            {
                label: "World Language Learning",
                value: "World Language Learning"
            }
        ]
    };

    render() {
        const { dataQa } = this.props;
        return (
            <SelectWrapper data-qa={dataQa}>
                <MultiSelect
                    overrideStrings={{
                        selectSomeItems: "Solution Interest"
                    }}
                    disableSearch={true}
                    filterOptions={false}
                    onSelectedChanged={this.onSelect}
                    options={this.state.options}
                    selected={this.state.selected}
                />
            </SelectWrapper>
        );
    }

    onSelect = (selected) => {
        this.setState({ selected }, () => {
            this.props.handleInputChange({
                target: {
                    name: "productSolutionOfInterest1",
                    value: selected.join(";")
                }
            });
        });
    };
}

SolutionInterest.propTypes = {
    vertical: PropTypes.string.isRequired,
    ...fieldTypes
};

export default SolutionInterest;
