import React from "react";
import Img from "gatsby-image";
import Video from "../Modal/Video";
import deprecated from "../deprecated";

const AdminImage = ({ selected, img_bg, data }) => {
    const {
        screen_assessment,
        screen_implementation,
        screen_placement,
        screen_reporting,
        screen_testing,
        screen_training
    } = data;
    const rawEls = [
        <div key={1}>
            <div className="video__container">
                <Img
                    className="video__img"
                    fluid={screen_placement.childImageSharp.fluid}
                    alt="Personalized Placement"
                />
            </div>
        </div>,
        <Video
            key={2}
            title="Assessment"
            videoSrc="https://www.youtube.com/embed/xINchcc5qoM?wmode=transparent&autoplay=1&rel=0"
            thumbnailSrc={screen_assessment}
        />,
        <div key={3}>
            <div className="video__container">
                <Img
                    className="video__img"
                    fluid={screen_testing.childImageSharp.fluid}
                    alt="English Proficiency Testing"
                />
            </div>
        </div>,
        <Video
            key={4}
            title="Reporting"
            videoSrc="https://www.youtube.com/embed/3owfNdL5iRk?wmode=transparent&autoplay=1&rel=0"
            thumbnailSrc={screen_reporting}
        />,
        <Video
            key={5}
            title="Implmentation Support"
            videoSrc="https://www.youtube.com/embed/FwiKbMpn5Iw?wmode=transparent&autoplay=1&rel=0"
            thumbnailSrc={screen_implementation}
        />,
        <div key={6}>
            <div className="video__container">
                <Img
                    className="video__img"
                    fluid={screen_training.childImageSharp.fluid}
                    alt="Personalized Placement"
                />
            </div>
        </div>
    ];
    const els = rawEls.map((el, index) => {
        const adjustedIndex = (index + 1).toString(); // to match numbers on data
        let screenshotClasses = "app_screenshot";
        if (selected === adjustedIndex) {
            screenshotClasses = "app_screenshot app_screenshot_active";
        }
        const key = `${index}-key`;
        return (
            <div key={key} className={screenshotClasses}>
                {el}
            </div>
        );
    });
    return (
        <div className="">
            <img className="img_bg" src={img_bg} alt="Admin Graphic" />
            {els}
        </div>
    );
};

export default deprecated(AdminImage);
