import React from "react";
import ButtonStyled from "../../components/Buttons/ButtonStyled";

const Submit = ({ dataQa, handleSubmit, label }) => (
    <div onClick={handleSubmit}>
        <ButtonStyled dataQa={dataQa} type="button" size="regular" theme="yellow_black" text={label} />
    </div>
);

export default Submit;
