import React from "react";
import { componentServices, resourceServices, verticalServices } from "@resourcehub/resourcehub-services";
import Lock from "./Lock";
import AnchorFallback from "../../components/AnchorFallback/AnchorFallback";
import "../../styles/card.less";
import deprecated from "../deprecated";

/**
 * Uses anchor tags with absolute paths instead of Link relative paths, used
 * for navigating outside hub and into business hub. Takes in helper methods
 * To assemble proper absolute path.
 * @param {Object} params props
 * @param {Object} params.node { node: data: { itemInfo } }
 * @param {Object} params.copyData { articleTitles}
 * @param {Object} params.siteMetadata { 'businessLocalized', 'siteURL' }
 * @returns Component
 */
const RelatedCard = ({ node, copyData, siteMetadata }) => {
    const { url_slug, thumbnail_url, thumbnail_alt_text, title, asset_type, duration } = node.data;

    const { siteUrl, businessLocalized } = siteMetadata;

    const action = componentServices.getAction(copyData, asset_type, duration);

    // Build prefix route for items outside of business hub
    const urlPrefix = verticalServices.buildAbsolutePath(siteUrl, businessLocalized);

    // Create link using rest of path
    const cardLink = `${urlPrefix}${copyData.resources_link}content/${url_slug}/`;
    const durationEl = duration && <span className="card__duration">{duration}</span>;
    return (
        <AnchorFallback classes="card" useLink={true} destination={cardLink}>
            <div className="card__content">
                <div className="card__img">
                    <img src={thumbnail_url} alt={thumbnail_alt_text || title} />
                    {durationEl}
                </div>
                <div className="card__title">{resourceServices.resourcesTrimTitle(title)}</div>
                <div className="card__action">{action}</div>
            </div>
        </AnchorFallback>
    );
};

export default deprecated(RelatedCard);
