import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import Image, { ImagePropTypes } from "../Images/Image";
import Link, { LinkPropTypes } from "../Links/Link";

const BrandLogoLink = styled(Link)`
    label: BrandLogoLink;
    display: inline-flex;
    line-height: 0;
    flex-grow: 0;
`;

const BrandLogo = ({ image, link, classname }) => (
    <BrandLogoLink {...link} className={classname}>
        <Image {...image} />
    </BrandLogoLink>
);

BrandLogo.propTypes = {
    image: PropTypes.shape(ImagePropTypes).isRequired,
    link: PropTypes.shape(LinkPropTypes).isRequired
};

BrandLogo.defaultProps = {};
export default BrandLogo;
