import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { images } from "@resourcehub/resourcehub-assets";

import "react-toastify/dist/ReactToastify.css";
import Image from "../Images/Image";

const ThankYouImageContainer = styled.div`
    label: ThankYouImageContainer;

    /* Extra small devices (phones, 600px and down) */
    @media only screen and (max-width: 600px) {
        padding: 40px;
    }

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (min-width: 600px) {
        padding: 40px;
    }

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) {
        padding: 60px;
    }

    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (min-width: 1025px) {
        padding: 80px;
    }

    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1200px) {
        padding: 100px;
    }
`;

class LeadGenerationThankYou extends React.Component {
    render = () => {
        return (
            <ThankYouImageContainer>
                <Image asset_static={images.thankYouMultilanguagePng} />
            </ThankYouImageContainer>
        );
    };
}

export const LeadGenerationThankYouPropTypes = {
    dataQa: PropTypes.string
};
LeadGenerationThankYou.propTypes = LeadGenerationThankYouPropTypes;
LeadGenerationThankYou.defaultProps = {};

export default LeadGenerationThankYou;
