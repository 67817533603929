import React from "react";
import styled from "styled-components";

const PhoneIconLink = styled.div`
    label: PhoneIconLink;
    cursor: pointer;
    display: inline-block;
    line-height: 1.5;
    a {
        padding: 23px 11px;
    }
`;

const Link = ({ dataQa, destination }) => (
    <PhoneIconLink data-qa={dataQa}>
        <a href={destination} rel="noopener noreferrer">
            <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjFweCIgaGVpZ2h0PSIyMXB4IiB2aWV3Qm94PSIwIDAgMjEgMjEiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8dGl0bGU+NzVFMjMxOUUtMzlCMi00M0NFLThGOTUtMzk1MEE3MjFDRUM0PC90aXRsZT4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJkZXNrdG9wLXNpdGUtbmF2IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTM5My4wMDAwMDAsIC0yMi4wMDAwMDApIiBmaWxsPSIjMDAwMDAwIiBmaWxsLXJ1bGU9Im5vbnplcm8iPgogICAgICAgICAgICA8ZyBpZD0iUGhvbmUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEzOTMuMDAwMDAwLCAyMi4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik00LjIyMzMzMzMzLDkuMDg4MzMzMzMgQzUuOTAzMzMzMzMsMTIuMzkgOC42MSwxNS4wODUgMTEuOTExNjY2NywxNi43NzY2NjY3IEwxNC40NzgzMzMzLDE0LjIxIEMxNC43OTMzMzMzLDEzLjg5NSAxNS4yNiwxMy43OSAxNS42NjgzMzMzLDEzLjkzIEMxNi45NzUsMTQuMzYxNjY2NyAxOC4zODY2NjY3LDE0LjU5NSAxOS44MzMzMzMzLDE0LjU5NSBDMjAuNDc1LDE0LjU5NSAyMSwxNS4xMiAyMSwxNS43NjE2NjY3IEwyMSwxOS44MzMzMzMzIEMyMSwyMC40NzUgMjAuNDc1LDIxIDE5LjgzMzMzMzMsMjEgQzguODc4MzMzMzMsMjEgMCwxMi4xMjE2NjY3IDAsMS4xNjY2NjY2NyBDMCwwLjUyNSAwLjUyNSwwIDEuMTY2NjY2NjcsMCBMNS4yNSwwIEM1Ljg5MTY2NjY3LDAgNi40MTY2NjY2NywwLjUyNSA2LjQxNjY2NjY3LDEuMTY2NjY2NjcgQzYuNDE2NjY2NjcsMi42MjUgNi42NSw0LjAyNSA3LjA4MTY2NjY3LDUuMzMxNjY2NjcgQzcuMjEsNS43NCA3LjExNjY2NjY3LDYuMTk1IDYuNzksNi41MjE2NjY2NyBMNC4yMjMzMzMzMyw5LjA4ODMzMzMzIFoiIGlkPSJQYXRoIj48L3BhdGg+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==" />
        </a>
    </PhoneIconLink>
);

export default Link;
