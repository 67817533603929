import React from "react";
import PropTypes from "prop-types";
import Meta from "./Meta";
import Menu from "./Menu";
import RegionSocial from "./RegionSocial";
import CallToActionBar from "./CallToActionBar";

const Footer = ({ config, eloquaPage = "webpage" }) => {
    return (
        <footer data-qa={"footer-section"}>
            {config.cta && config.cta.visible && <CallToActionBar config={config.cta} eloquaPage={eloquaPage} />}
            {config.menu && config.menu.visible && <Menu config={config.menu} />}
            {config.meta && config.meta.visible && <Meta config={config.meta} />}
            {config.region_social && config.region_social.visible && <RegionSocial config={config.region_social} />}
        </footer>
    );
};

Footer.propTypes = {
    config: PropTypes.object.isRequired,
    eloquaPage: PropTypes.any
};

export default Footer;
