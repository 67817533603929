import React from "react";
import deprecated from "../deprecated";

const LanguageList = ({ handleLangSelect }) => (
    <ul className="masthead_demo_langs">
        <li>
            <button type="button" onClick={(e) => handleLangSelect(e, "es")}>
                Try Spanish <span>(Latin America)</span>
            </button>
        </li>
        <li>
            <button type="button" onClick={(e) => handleLangSelect(e, "fr")}>
                Try French
            </button>
        </li>
        <li>
            <button type="button" onClick={(e) => handleLangSelect(e, "it")}>
                Try Italian
            </button>
        </li>
        <li>
            <button type="button" onClick={(e) => handleLangSelect(e, "de")}>
                Try German
            </button>
        </li>
        <li>
            <button type="button" onClick={(e) => handleLangSelect(e, "en")}>
                Try English <span>(American)</span>
            </button>
        </li>
    </ul>
);

export default deprecated(LanguageList);
