import React from "react";
import { componentServices } from "@resourcehub/resourcehub-services";
import { staticData } from "@resourcehub/resourcehub-data";
import { fieldTypes } from "../../component-proptypes";
import "../../styles/contactform.less";

const Country = ({ dataQa, data, error, handleInputChange }) => (
    <div className={`roleContainer ${componentServices.createFieldClasses(error, data)}`}>
        <select data-qa={dataQa} id="country" name="country" required onChange={handleInputChange} value={data}>
            <option value="" style={{ display: "none" }} />
            {staticData.countryData.map((node) => (
                <option key={`country-${node.value}`} value={node.value}>
                    {node.label}
                </option>
            ))}
        </select>
        <label data-qa={`${dataQa}-label`} htmlFor="country">
            国家
        </label>
    </div>
);

Country.propTypes = {
    ...fieldTypes
};

export default Country;
