import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import Swiper from "swiper/bundle";

// Styles
import TitleWrapper, { TitleWrapperPropTypes } from "../component-styles/TitleWrapper";
import PageSectionWrapper from "../component-styles/PageSectionWrapper";
import ChildContentWrapper from "../component-styles/ChildContentWrapper";

// Components
import Link, { LinkPropTypes } from "../components/Links/Link";

// Carousel
const LinkCarouselSlideDiv = styled.div`
    label: LinkCarouselSlideDiv;
    text-align: center;
`;
const CarouselNavigationBullets = styled.div`
    label: CarouselNavigationBullets;

    .swiper-pagination-bullet {
        border: 2px solid #666666;
        height: 10px;
        width: 10px;
        margin-left: 13px !important;
        margin-right: 13px !important;
        background-color: inherit !important;
    }

    .swiper-pagination-bullet-active {
        background-color: #666666 !important;
    }
`;
const CarouselNavigationNext = styled.div`
    label: CarouselNavigationNext;

    --swiper-navigation-color: #666666;
`;
const CarouselNavigationPrevious = styled.div`
    label: CarouselNavigationPrevious;

    --swiper-navigation-color: #666666;
`;
const LinkCarouselLayout = styled.div`
    //display: flex;
    //flex-direction: column;
    width: 100%;
    > div:nth-of-type(1) {
        margin-left: 2.5em;
        margin-right: 4em;
        margin-bottom: 50px;
    }
`;
class LinkCarousel extends React.Component {
    componentDidMount() {
        new Swiper(".swiper-container", {
            cssMode: true,
            loop: true,
            centeredSlides: true,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev"
            },
            pagination: {
                el: ".swiper-pagination"
            },
            mousewheel: true,
            keyboard: true
        });
    }
    render() {
        const { dataQa, panels, title } = this.props;
        if (!panels) return null;
        return (
            <PageSectionWrapper data-qa={`content-section-header-${dataQa}`}>
                <LinkCarouselLayout>
                    {title && <TitleWrapper {...title} />}
                    {panels && (
                        <div className="swiper-container">
                            <div className="swiper-wrapper">
                                {panels.map((panel, i) => (
                                    <LinkCarouselSlideDiv
                                        className="swiper-slide"
                                        key={`image-text-carousel-slide-${i}`}
                                    >
                                        <Link {...panel} />
                                    </LinkCarouselSlideDiv>
                                ))}
                            </div>

                            {/* Add Arrows */}

                            <CarouselNavigationBullets className="swiper-pagination" />
                            <CarouselNavigationNext className="swiper-button-next" />
                            <CarouselNavigationPrevious className="swiper-button-prev" />
                        </div>
                    )}
                </LinkCarouselLayout>
            </PageSectionWrapper>
        );
    }
}

// List
const LinkListLayout = styled.div`
    label: LinkListLayout;

    display: flex;
    flex-direction: column;
    width: 100%;
`;
const LinkListContainer = styled.div`
    label: LinkListContainer;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    > div {
        flex: 1;
        margin: 50px;
        white-space: nowrap;
    }
`;
const LinkTitleWrapper = styled(TitleWrapper)`
    margin-bottom: 40px;
`;
const LinkList = ({ dataQa, panels, title, children }) => {
    if (!panels) return null;
    return (
        <PageSectionWrapper data-qa={`content-section-header-${dataQa}`}>
            <LinkListLayout>
                {title && <LinkTitleWrapper {...title} />}
                {panels && (
                    <LinkListContainer>
                        {panels.map((panel, i) => (
                            <Link key={`image-text-${i}`} {...panel} />
                        ))}
                    </LinkListContainer>
                )}
            </LinkListLayout>
            {children && <ChildContentWrapper>{children}</ChildContentWrapper>}
        </PageSectionWrapper>
    );
};

// Root Component
const PageSectionLink = (props) => {
    switch (props.layout) {
        case "list":
            return <LinkList {...props} />;
        case "carousel":
            return <LinkCarousel {...props} />;
    }
};
export const PageSectionLinkPropTypes = {
    dataQa: PropTypes.string,
    layout: PropTypes.oneOf(["list", "carousel"]),
    panels: PropTypes.arrayOf(PropTypes.shape(LinkPropTypes)).isRequired,
    title: PropTypes.shape(TitleWrapperPropTypes)
};
PageSectionLink.defaultProps = {
    dataQa: "page-section-image",
    layout: "list"
};
PageSectionLink.propTypes = PageSectionLinkPropTypes;
export default PageSectionLink;
