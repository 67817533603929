//  NEEDS SEPARATION

import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

// Styles
import TitleWrapper, { TitleWrapperPropTypes } from "../component-styles/TitleWrapper";
import PageSectionWrapper from "../component-styles/PageSectionWrapper";

// Components
import BodyWrapper from "../component-styles/BodyWrapper";
import VideoPlayer, { VideoPlayerPropTypes } from "../components/Videos/VideoPlayer";
import ChildContentWrapper from "../component-styles/ChildContentWrapper";

const MediaWrapper = styled.div`
    label: MediaWrapper;
    padding: 0;
    margin: 0;
    text-align: center;
    //Mobile-landscape (and larger)
    @media (min-width: 320px) {
        min-width: 100%;
    }
    //Mobile-landscape (and larger)
    @media (min-width: 480px) {
        min-width: 100%;
    }
    // Tablet-portrait (and larger)
    @media (min-width: 768px) {
        min-width: 100%;
    }
    // Tablet-landscape (and larger)
    @media (min-width: 992px) {
        min-width: 60%;
    }
    // Laptops (and longer)
    @media (min-width: 1200px) {
        min-width: 40%;
        max-height: 30%;
        img {
            min-width: auto;
            max-width: 100%;
        }
    }
`;
const ContentWrapper = styled.div`
    label: ContentWrapper;

    padding: 20px;
    width: 100%;

    //Mobile-landscape (and larger)
    @media (min-width: 320px) {
        text-align: center;
    }
    //Mobile-landscape (and larger)
    @media (min-width: 480px) {
        text-align: center;
    }
    // Tablet-portrait (and larger)
    @media (min-width: 768px) {
        text-align: center;
    }
    // Tablet-landscape (and larger)
    @media (min-width: 992px) {
        text-align: center;
    }
    // Laptops (and longer)
    @media (min-width: 1200px) {
        text-align: left;
    }
`;

const PageSectionVideo = ({ dataQa, panels, children }) => {
    const panel = panels[0];
    return (
        <PageSectionWrapper direction={panel.direction} data-qa={`content-section-header-${dataQa}`}>
            <MediaWrapper>
                <VideoPlayer controls={true} currentVideoUrl={panel.video.source} />
            </MediaWrapper>
            <ContentWrapper>
                {panel.title && <TitleWrapper {...panel.title} dataQa={`content-section-main-title-${dataQa}`} />}
                {panel.body && <BodyWrapper {...panel.body} dataQa={dataQa} />}
                <ChildContentWrapper>{children}</ChildContentWrapper>
            </ContentWrapper>
        </PageSectionWrapper>
    );
};

const PageSectionVideoPropTypes = {
    dataQa: PropTypes.string,
    direction: PropTypes.oneOf(["content-left", "content-right"]),
    title: PropTypes.shape(TitleWrapperPropTypes),
    video: PropTypes.shape(VideoPlayerPropTypes)
};
PageSectionVideo.defaultProps = {
    direction: "content-left"
};
PageSectionVideo.propTypes = PageSectionVideoPropTypes;
export default PageSectionVideo;
