import React from "react";
import { navigate } from "gatsby";
import RelatedContent from "../RelatedContent/RelatedContent";
import AwardsNew from "../AwardsNew/AwardsNew";
import ButtonStyled from "../../components/Buttons/ButtonStyled";
import "../../styles/home.less";
import "../../styles/hero.less";
import deprecated from "../deprecated";

const AdwordsSuccess = ({ data, items, copyData }) => (
    <>
        <div className="hero">
            <div className="hero__image">
                <div>
                    <img
                        src="https://resources.rosettastone.com/assets/lp/9999999998/resources/imgs/bus-hero-1.png"
                        alt="success"
                    />
                </div>
            </div>
            <div className="hero__copy hero__success">
                <h1>{copyData.pricing_thanks}</h1>
                <ButtonStyled text="back to home" size="regular" theme="yellow_black" onClick={() => navigate("/")} />
            </div>
        </div>
        <RelatedContent
            copyData={copyData}
            items={items}
            title={copyData.keep_exploring}
            siteMetadata={data.site.siteMetadata}
            showBorders
        />
        <AwardsNew copyData={copyData} />
    </>
);

AdwordsSuccess.propTypes = {};

export default deprecated(AdwordsSuccess);
