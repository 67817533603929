import React from "react";
import PropTypes from "prop-types";
import { trustpilot } from "@resourcehub/resourcehub-assets";
import styled from "@emotion/styled";

// Styles
import PageSectionWrapper from "../component-styles/PageSectionWrapper";
import TitleWrapper, { TitleWrapperPropTypes } from "../component-styles/TitleWrapper";
import ChildContentWrapper from "../component-styles/ChildContentWrapper";

// Components
import TrustPilotRatingsWidget from "../components/TrustPilotRatings/TrustPilotRatings";
import ButtonStyled from "../components/Buttons/ButtonStyled";

const Backdrop = styled.div`
    label: Backdrop;

    font-family: effra, sans-serif;

    display: flex;
    flex-direction: column;
    justify-content: center;

    > div:nth-of-type(2) {
        background: #f0c433;
        color: #000000;
    }
`;
const ContentWrapper = styled.div`
    label: ContentWrapper;

    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-evenly;
    text-align: center;

    //Mobile-landscape (and larger)
    @media (min-width: 320px) {
        padding-top: 20px;
        padding-left: 5px;
        padding-right: 5px;
    }
    //Mobile-landscape (and larger)
    @media (min-width: 480px) {
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
    }
    // Tablet-portrait (and larger)
    @media (min-width: 768px) {
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
    }
    // Tablet-landscape (and larger)
    @media (min-width: 992px) {
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
    }
    // Laptops (and longer)
    @media (min-width: 1200px) {
        padding-top: 20px;
        padding-left: 20%;
        padding-right: 20%;
    }
`;
const BodyWrapper = styled.div`
    label: BodyWrapper;

    > div {
        margin-bottom: 30px;
    }

    /* Extra small devices (phones, 600px and down) */
    @media only screen and (max-width: 600px) {
        width: 100%;
        max-width: 350px;
    }

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (min-width: 600px) {
        width: 100%;
        max-width: 500px;
    }

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) {
        width: 100%;
        max-width: 700px;
    }

    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (min-width: 1025px) {
        width: 100%;
        max-width: 750px;
    }

    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1200px) {
        width: 100%;
        max-width: 750px;
    }
`;
const BorderImageDiv = styled.div`
    label: BorderImageDiv;

    padding: 0;
    margin: 0;
    > img {
        vertical-align: bottom;
        width: 100vw;
        height: 50px;
    }
`;

const Content = (props) => {
    const {
        title,
        skus,
        dataQa,
        button_visible,
        button_label,
        button_destination,
        children,
        language,
        page_title
    } = props;
    return (
        <ContentWrapper>
            {title && <TitleWrapper {...title} />}
            <BodyWrapper data-qa={`content-section-body-${dataQa}`}>
                <div>
                    <TrustPilotRatingsWidget skuList={skus || ""} language={language} pageTitle={page_title} />
                </div>
                {button_visible && (
                    <div>
                        <ButtonStyled
                            size="regular"
                            theme="blue_white"
                            text={button_label}
                            destination={button_destination}
                            new_window={true}
                        />
                    </div>
                )}
            </BodyWrapper>
            {children && <ChildContentWrapper>{children}</ChildContentWrapper>}
        </ContentWrapper>
    );
};

Content.propTypes = {
    title: PropTypes.any,
    skus: PropTypes.any,
    dataQa: PropTypes.any,
    button_visible: PropTypes.any,
    button_label: PropTypes.any,
    button_destination: PropTypes.any,
    children: PropTypes.any,
    language: PropTypes.any,
    page_title: PropTypes.any
};

const PageSectionTrustPilot = (props) => {
    if (props.backdrop_visible) {
        return (
            <PageSectionWrapper data-qa={`content-section-header-${props.dataQa}`}>
                <Backdrop>
                    <BorderImageDiv>
                        <img src={trustpilot.yellowBridgeHeaderSvg} alt="section border" />
                    </BorderImageDiv>
                    <Content {...props} />
                    <BorderImageDiv>
                        <img src={trustpilot.yellowBridgeFooterSvg} alt="section border" />
                    </BorderImageDiv>
                </Backdrop>
            </PageSectionWrapper>
        );
    }
    return (
        <PageSectionWrapper data-qa={`content-section-header-${props.dataQa}`}>
            <Content {...props} />
        </PageSectionWrapper>
    );
};
export const PageSectionTrustPilotPropTypes = {
    skus: PropTypes.string.isRequired,
    backdrop_visible: PropTypes.bool,
    button_visible: PropTypes.bool,
    button_label: PropTypes.string.isRequired,
    button_destination: PropTypes.string.isRequired,
    language: PropTypes.string,
    page_title: PropTypes.string,
    dataQa: PropTypes.string,
    title: PropTypes.shape(TitleWrapperPropTypes)
};
PageSectionTrustPilot.defaultProps = {
    backdrop_visible: true,
    button_visible: true,
    title_visible: true,
    language: "ENG",
    page_title: "Rosetta Stone"
};
PageSectionTrustPilot.propTypes = PageSectionTrustPilotPropTypes;
export default PageSectionTrustPilot;
