import React from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import Swiper from "swiper/bundle";

// Styles
import PageSectionWrapper from "../component-styles/PageSectionWrapper";

// Components
import ToggleContent, { ToggleContentPropTypes } from "../components/ToggleContents/ToggleContent";
import { TitleWrapperPropTypes } from "../component-styles/TitleWrapper";

// Carousel
const ToggleContentCarouselSlideDiv = styled.div`
    label: ToggleContentCarouselSlideDiv;

    width: 100%;
    > div {
        margin-left: 4em;
        margin-right: 4em;
    }
`;
const CarouselNavigationBullets = styled.div`
    label: CarouselNavigationBullets;

    .swiper-pagination-bullet {
        border: 2px solid #666666;
        height: 10px;
        width: 10px;
        margin-left: 13px !important;
        margin-right: 13px !important;
        background-color: inherit !important;
    }

    .swiper-pagination-bullet-active {
        background-color: #666666 !important;
    }
`;
const CarouselNavigationNext = styled.div`
    label: CarouselNavigationNext;

    --swiper-navigation-color: #666666;
`;
const CarouselNavigationPrevious = styled.div`
    label: CarouselNavigationPrevious;

    --swiper-navigation-color: #666666;
`;
class ToggleContentCarousel extends React.Component {
    componentDidMount() {
        new Swiper(".swiper-container", {
            cssMode: true,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev"
            },
            pagination: {
                el: ".swiper-pagination"
            },
            mousewheel: true,
            keyboard: true
        });
    }
    render() {
        const { dataQa, panels } = this.props;
        if (!panels) return null;
        return (
            <PageSectionWrapper data-qa={`content-section-header-${dataQa}`}>
                <div className="swiper-container">
                    <div className="swiper-wrapper">
                        {panels.map((panel, i) => (
                            <ToggleContentCarouselSlideDiv
                                className="swiper-slide"
                                key={`image-text-carousel-slide-${i}`}
                            >
                                <ToggleContent {...panel} />
                            </ToggleContentCarouselSlideDiv>
                        ))}
                    </div>

                    {/* Add Arrows */}

                    <CarouselNavigationBullets className="swiper-pagination" />
                    <CarouselNavigationNext className="swiper-button-next" />
                    <CarouselNavigationPrevious className="swiper-button-prev" />
                </div>
            </PageSectionWrapper>
        );
    }
}

// Duo
const ToggleContentDuoContainer = styled.div`
    label: ToggleContentDuoContainer;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 500px;
    width: 100%;

    // Tablet-portrait (and larger)
    @media (min-width: 768px) {
        flex-direction: row;
`;
class ToggleContentDuo extends React.Component {
    state = {
        toggles: {}
    };
    componentDidMount() {
        const { panels } = this.props;
        if (!panels) return;

        const toggles = {};
        panels.forEach((p, i) => {
            toggles[i] = false;
        });
        this.setState({
            toggles
        });
    }
    changeTracker = (item, toggle) => {
        const newState = Object.assign({}, this.state);
        newState.toggles[item] = toggle;
        this.setState(newState);
    };
    render() {
        const { dataQa, panels } = this.props;
        const { toggles } = this.state;

        if (!panels) return null;
        const anyOpen = Object.keys(toggles).some((t) => toggles[t]);
        return (
            <PageSectionWrapper data-qa={`content-section-header-${dataQa}`}>
                <ToggleContentDuoContainer>
                    {panels.map((panel, i) => (
                        <ToggleContent
                            key={`image-text-${i}`}
                            emit_change={this.changeTracker}
                            index={i}
                            toggle_state={toggles[i]}
                            {...panel}
                            visible={anyOpen === false || toggles[i]}
                        />
                    ))}
                </ToggleContentDuoContainer>
            </PageSectionWrapper>
        );
    }
}
ToggleContentDuo.propTypes = {
    dataQa: PropTypes.any,
    panels: PropTypes.any
};

// List
const ToggleContentListContainer = styled.div`
    label: ToggleContentListContainer;
    display: flex;
    flex-direction: column;
    > div {
        margin-bottom: 50px;
    }
`;
const ToggleContentList = ({ dataQa, panels }) => {
    if (!panels) return null;
    return (
        <PageSectionWrapper data-qa={`content-section-header-${dataQa}`}>
            <ToggleContentListContainer>
                {panels.map((panel, i) => (
                    <ToggleContent key={`image-text-${i}`} {...panel} />
                ))}
            </ToggleContentListContainer>
        </PageSectionWrapper>
    );
};

// Root Component
const PageSectionToggleContent = (props) => {
    switch (props.layout) {
        case "duo":
            return <ToggleContentDuo {...props} />;
        case "list":
            return <ToggleContentList {...props} />;
        case "carousel":
            return <ToggleContentCarousel {...props} />;
    }
};
export const PageSectionToggleContentPropTypes = {
    dataQa: PropTypes.string,
    layout: PropTypes.oneOf(["list", "carousel", "duo"]),
    panels: PropTypes.arrayOf(PropTypes.shape(ToggleContentPropTypes)).isRequired,
    title: PropTypes.shape(TitleWrapperPropTypes)
};
PageSectionToggleContent.defaultProps = {
    layout: "list",
    dataQa: "content-section-image-content"
};
PageSectionToggleContent.propTypes = PageSectionToggleContentPropTypes;
export default PageSectionToggleContent;
