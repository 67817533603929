import React from "react";
import PropTypes from "prop-types";
import { componentServices } from "@resourcehub/resourcehub-services";
import { fieldTypes } from "../../component-proptypes";

const Email = ({ dataQa, error, data, handleInputChange, label }) => (
    <div className={componentServices.createFieldClasses(error, data)}>
        <input
            data-qa={dataQa}
            id="email"
            onChange={handleInputChange}
            value={data}
            autoComplete="email"
            type="email"
            name="email"
            required
            maxLength="255"
        />
        <label data-qa={`${dataQa}-label`} htmlFor="email">
            {label}
        </label>
    </div>
);

Email.propTypes = {
    ...fieldTypes,
    label: PropTypes.string.isRequired
};

export default Email;
