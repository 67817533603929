import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import ButtonStyled from "../Buttons/ButtonStyled";

const ResourceDiv = styled.div`
    label: ResourceDiv;

    border: #e5e5e5 solid 1px;

    background-color: #fff;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    max-width: 360px;
    overflow: hidden;
    padding: 0;

    margin: 10px;
    align-items: center;
    text-align: center;
    > div:nth-of-type(1) {
        flex: 0;
        align-content: center;
        text-align: center;
        width: 380px;
        height: 240px;
        max-height: 240px;
        min-height: 240px;
        max-width: 360px;
        min-width: 360px;
        overflow: hidden;
        img {
            width: 360px;
            object-fit: cover;
        }
    }
    > div:nth-of-type(2) {
        flex: 1;
        font-family: effra_medium, sans-serif;
        font-size: 18px;
        font-weight: 700;
        max-height: 66px;
        overflow: hidden;
        line-height: 18px;
        padding: 15px;
        h3 {
            font-family: helvetica_bold, sans-serif;
            font-size: 0.9em;
            font-weight: 700;
        }
    }
    > div:nth-of-type(3) {
        flex: 0;
        padding: 15px;
    }
`;

const CardResource = ({ dataQa, thumbnail_url, thumbnail_alt_text, title, destination, button_label }) => (
    <ResourceDiv data-qa={`card-resource-${dataQa}`}>
        <div>
            <img src={thumbnail_url} alt={thumbnail_alt_text} />
        </div>
        <div>
            <h3>{title}</h3>
        </div>
        <div>
            <ButtonStyled
                destination={destination}
                new_window={true}
                size="mini"
                theme="blue_white"
                text={button_label}
            />
        </div>
    </ResourceDiv>
);

export const CardResourcePropTypes = {
    button_label: PropTypes.string,
    dataQa: PropTypes.string,
    destination: PropTypes.string,
    thumbnail_alt_text: PropTypes.string,
    thumbnail_url: PropTypes.string,
    title: PropTypes.string,
    is_gated: PropTypes.bool
};
CardResource.propTypes = CardResourcePropTypes;
CardResource.defaultProps = {
    is_gated: false
};
export default CardResource;
