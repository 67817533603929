import React from "react";
import styled from "@emotion/styled";

const ChatHeaderStyled = styled.div`
    vertical-align: middle;
    box-sizing: border-box;
    background: url(https://resources.rosettastone.com/assets/lp/1539375484/sbsr/assets/chat-icon.svg) no-repeat 66px
        2px;
    padding-right: 20px;
    background-size: 21%;
    cursor: pointer !important;
    //Mobile-landscape (and larger)
    @media (min-width: 320px) {
        display: none;
    }
    //Mobile-landscape (and larger)
    @media (min-width: 480px) {
        display: none;
    }
    // Tablet-portrait (and larger)
    @media (min-width: 768px) {
        display: inline-block;
    }
    // Tablet-landscape (and larger)
    @media (min-width: 992px) {
        display: inline-block;
    }
    // Laptops (and longer)
    @media (min-width: 1200px) {
        display: inline-block;
    }
`;

const ChatHeader = () => <ChatHeaderStyled />;

export default ChatHeader;
