import React from "react";
import PropTypes from "prop-types";
import { images } from "@resourcehub/resourcehub-assets";
import "../../styles/notfound.less";
import deprecated from "../deprecated";

const NotFound = ({ copyData }) => (
    <div className="notFound-container">
        <img className="img_bg" alt="Stone Background" src={images.section_3StoneGreySvg} />
        <div className="notFound-content">
            <img
                alt="Sacrebleu !"
                className="notFound-sacrebleu"
                src="https://www.rosettastone.de/image/image_gallery?uuid=be8ed4af-8abe-4650-b9e6-29d2b3dfee4c&groupId=10165&t=1435200502811"
            />
            <h1>{copyData.site_not_found}</h1>
            <p className="notFound-message">{copyData.helpful_links}</p>
            <div className="notFound-filterWrapper" />
            <ul className="notFound-links">
                <li>
                    <a className="notFound-redirect" href={copyData.home_url} title={copyData.consumer_home}>
                        {copyData.consumer_home}
                    </a>
                </li>
                <li>
                    <a
                        className="notFound-redirect"
                        title={`${copyData.vertical_k12} ${copyData.home}`}
                        href={copyData.for_educators_url}
                    >
                        {`${copyData.vertical_k12} ${copyData.home}`}
                    </a>
                </li>
                <li>
                    <a
                        className="notFound-redirect"
                        title={`${copyData.vertical_k12} ${copyData.solutions}`}
                        href={copyData.for_educators_solutions_url}
                    >
                        {`${copyData.vertical_k12} ${copyData.solutions}`}
                    </a>
                </li>
                <li>
                    <a
                        className="notFound-redirect"
                        title={`${copyData.vertical_k12} ${copyData.resources}`}
                        href={copyData.for_educators_resources_url}
                    >
                        {`${copyData.vertical_k12} ${copyData.resources}`}
                    </a>
                </li>
                <li>
                    <a
                        className="notFound-redirect"
                        title={`${copyData.vertical_business} ${copyData.home}`}
                        href={copyData.for_business_url}
                    >
                        {`${copyData.vertical_business} ${copyData.home}`}
                    </a>
                </li>
                <li>
                    <a
                        className="notFound-redirect"
                        title={`${copyData.vertical_business} ${copyData.solutions}`}
                        href={copyData.for_business_solutions_url}
                    >
                        {`${copyData.vertical_business} ${copyData.solutions}`}
                    </a>
                </li>
                <li>
                    <a
                        className="notFound-redirect"
                        title={`${copyData.vertical_business} ${copyData.resources}`}
                        href={copyData.for_business_resources_url}
                    >
                        {`${copyData.vertical_business} ${copyData.resources}`}
                    </a>
                </li>
                <li>
                    <a className="notFound-redirect" title={copyData.vertical_pub} href={copyData.vertical_pub_link}>
                        {copyData.vertical_pub}
                    </a>
                </li>
                <li>
                    <a className="notFound-redirect" title={copyData.vertical_hed} href={copyData.vertical_hed_link}>
                        {copyData.vertical_hed}
                    </a>
                </li>
                <li>
                    <a className="notFound-redirect" title={copyData.support} href={copyData.support_site_url}>
                        {copyData.support}
                    </a>
                </li>
            </ul>
        </div>
    </div>
);

NotFound.propTypes = {
    copyData: PropTypes.shape({
        site_not_found: PropTypes.string.isRequired,
        helpful_links: PropTypes.string.isRequired,
        consumer_home: PropTypes.string.isRequired,
        home_url: PropTypes.string.isRequired,
        vertical_k12: PropTypes.string.isRequired,
        home: PropTypes.string.isRequired,
        for_educators_url: PropTypes.string.isRequired,
        solutions: PropTypes.string.isRequired,
        for_educators_solutions_url: PropTypes.string.isRequired,
        resources: PropTypes.string.isRequired,
        for_educators_resources_url: PropTypes.string.isRequired,
        vertical_business: PropTypes.string.isRequired,
        for_business_url: PropTypes.string.isRequired,
        for_business_solutions_url: PropTypes.string.isRequired,
        for_business_resources_url: PropTypes.string.isRequired,
        vertical_pub: PropTypes.string.isRequired,
        vertical_pub_link: PropTypes.string.isRequired,
        vertical_hed: PropTypes.string.isRequired,
        vertical_hed_link: PropTypes.string.isRequired,
        support: PropTypes.string.isRequired,
        support_site_url: PropTypes.string.isRequired
    }).isRequired
};

export default deprecated(NotFound);
