import React from "react";
import PropTypes from "prop-types";
import { images } from "@resourcehub/resourcehub-assets";
import { verticalServices } from "@resourcehub/resourcehub-services";
import Card from "../../components/Cards/CardStyled";
import RelatedCard from "../Card/RelatedCard";
import "../../styles/related.less";
import deprecated from "../deprecated";

const random = (range) => Math.floor(Math.random() * range);

function createCards(usesStandardCard, edge, copyData, siteMetadata) {
    if (usesStandardCard) {
        return <Card copyData={copyData} key={edge.node.id} node={edge.node} />;
    }
    return <RelatedCard copyData={copyData} key={edge.node.id} node={edge.node} siteMetadata={siteMetadata} />;
}

function determineRelated(items, copyData, siteMetadata) {
    const len = items.length;
    let related = "";
    // Business, Services, and China should use normal 'Standard' Card
    const usesStandardCard =
        siteMetadata.vertical === "business" ||
        siteMetadata.vertical === "services" ||
        siteMetadata.domain === ".com" ||
        verticalServices.isCombinedVertical(copyData.market);
    if (len >= 4) {
        const rand = random(len - 4);
        related = items
            .slice(rand, rand + 4)
            .map((edge) => createCards(usesStandardCard, edge, copyData, siteMetadata));
    } else if (items && items.length > 0) {
        related = items.map((edge) => createCards(usesStandardCard, edge, copyData, siteMetadata));
    }
    return related;
}

const RelatedContent = ({ items, title = "Similar Resources", showBorders, copyData, siteMetadata }) => {
    if (!items || (Array.isArray(items) && items.length < 1)) return <div className="related_content_none_available" />;
    const relatedCards = determineRelated(items, copyData, siteMetadata);
    const classes = showBorders ? "resources related related--borders" : "resources related";
    return (
        <div className={classes}>
            {showBorders && (
                <img src={images.relatedTopSvg} className="related__border related__borderTop" alt="section border" />
            )}
            <h2>{title}</h2>
            <div className="cards">{relatedCards}</div>
            {showBorders && (
                <img
                    src={images.relatedBottomSvg}
                    className="related__border related__borderBottom"
                    alt="section border"
                />
            )}
        </div>
    );
};

RelatedContent.propTypes = {
    copyData: PropTypes.shape({
        read_article: PropTypes.string.isRequired,
        read_case_study: PropTypes.string.isRequired,
        read_ebook: PropTypes.string.isRequired,
        read_fact_sheet: PropTypes.string.isRequired,
        read_guide: PropTypes.string.isRequired,
        view_infographic: PropTypes.string.isRequired,
        listen_now: PropTypes.string.isRequired,
        read_report: PropTypes.string.isRequired,
        watch_video: PropTypes.string.isRequired,
        watch_webinar: PropTypes.string.isRequired,
        read_white_paper: PropTypes.string.isRequired
    }).isRequired,
    siteMetadata: PropTypes.shape({
        vertical: PropTypes.string.isRequired,
        domain: PropTypes.string.isRequired,
        businessLocalized: PropTypes.string.isRequired
    }).isRequired
};

export default deprecated(RelatedContent);
