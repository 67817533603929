import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

const FooterSection = styled.section`
    label: FooterSection;
    background-color: #f1c433;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto;
    width: 100%;
`;
const ContentDiv = styled.div`
    label: ContentDiv;
    display: flex;
    align-items: center;
    max-width: 1084px;
    min-height: 125px;
    width: 100%;

    /* Extra small devices (phones, 600px and down) */
    @media only screen and (max-width: 600px) {
        flex-direction: column !important;
        justify-content: center;
        align-content: center;
        padding: 1em 0;
        text-align: center;
    }

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (min-width: 600px) {
        flex-direction: column !important;
        justify-content: center;
        align-content: center;
        padding: 1em 0;
        text-align: center;
    }

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) {
        flex-direction: column !important;
        justify-content: center;
        align-content: center;
        padding: 1em 0;
        text-align: center;
    }

    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (min-width: 1025px) {
        flex-direction: row !important;
        justify-content: space-between;
        align-content: center;
        padding: 1em 15%;
        text-align: left;
    }

    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1200px) {
        flex-direction: row !important;
        justify-content: space-between;
        align-content: center;
        margin: auto;
        padding: 1em 0;
        text-align: left;
    }
`;
const HeaderLabel = styled.div`
    label: HeaderLabel;

    display: block;
    font-family: effra, sans-serif;
    font-size: 14px;
    letter-spacing: 0.4px;
    margin-bottom: 1em;

    /* Extra small devices (phones, 600px and down) */
    @media only screen and (max-width: 600px) {
        text-align: center;
        width: 100%;
        :after {
            content: "";
        }
    }

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (min-width: 600px) {
        text-align: center;
        width: 100%;
        :after {
            content: "";
        }
    }

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) {
        text-align: center;
        width: 100%;
        :after {
            content: "";
        }
    }

    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (min-width: 1025px) {
        text-align: left;
        :after {
            content: ":";
        }
    }

    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1200px) {
        text-align: left;
        :after {
            content: ":";
        }
    }
`;

const RegionSelectWrap = styled.div`
    label: RegionSelectWrap;

    background-color: #fff;
    border-radius: 20px;
    border: 1px solid #999;
    position: relative;

    :after {
        content: "";
        position: absolute;
        right: 18px;
        top: calc(50% - 8px);
        width: 9px;
        height: 9px;
        border: 2px solid #0098db;
        border-top: none;
        border-right: none;
        transform: rotate(-45deg);
    }

    /* Extra small devices (phones, 600px and down) */
    @media only screen and (max-width: 600px) {
        justify-content: center;
        margin-bottom: 1em;
    }

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (min-width: 600px) {
        justify-content: center;
        margin-bottom: 1em;
    }

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) {
        justify-content: center;
        margin-bottom: 1em;
    }

    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (min-width: 1025px) {
        justify-content: space-between;
        padding: 0;
    }

    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1200px) {
        justify-content: space-between;
        padding: 0;
    }
`;
const RegionSelect = styled.select`
    label: RegionSelect;

    background: 0 0;
    border: none;
    color: #262626;
    cursor: pointer;
    display: block;
    font-family: effra, sans-serif;
    font-size: 16px;
    font-weight: lighter;
    line-height: 1.15;
    padding: 10px 110px 11px 14px;
    position: relative;
    width: 100%;

    // Keep this to remove the default dropdown icon
    -webkit-appearance: none;
    -moz-appearance: none;
`;

const SocialIconList = styled.ul`
    label: SocialIconList;
    display: flex;
    flex-direction: row;
    align-content: center;
    list-style: none;
    text-align: center;
    margin-bottom: 1em;
    padding: 0;

    /* Extra small devices (phones, 600px and down) */
    @media only screen and (max-width: 600px) {
        justify-content: center;
    }

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (min-width: 600px) {
        justify-content: center;
    }

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) {
        justify-content: center;
    }

    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (min-width: 1025px) {
        justify-content: space-between;
    }

    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1200px) {
        justify-content: space-between;
    }
`;
const SocialIcon = styled.a`
    label: SocialIcon;

    background-image: url(${(props) => props.icon});
    display: block;
    background-repeat: no-repeat;
    height: 25px;
    width: 40px;
    background-size: contain;
    margin-right: 15px;
    background-position: 50%;
    z-index: 1333;
`;

class RegionSocial extends React.Component {
    render() {
        const { config } = this.props;
        return (
            <FooterSection data-qa={"footer-region-selection-section"}>
                <ContentDiv>
                    {config.region && config.region.visible && <Region config={config.region} />}
                    {config.social && config.social.visible && <Social config={config.social} />}
                </ContentDiv>
            </FooterSection>
        );
    }
}

RegionSocial.propTypes = {
    config: PropTypes.any.isRequired
};
export default RegionSocial;

const handleRegionChange = (event) => {
    if (event.target.value !== window.location.href && window) {
        window.location.href = event.target.value;
    }
};

const Region = ({ config }) => (
    <div>
        <HeaderLabel>{config.label}</HeaderLabel>
        <RegionSelectWrap>
            <RegionSelect data-qa={"footer-region-dropdown"} onChange={handleRegionChange}>
                {config.options.map((r, i) => {
                    const key = `region-${i}`;
                    return (
                        <option data-qa={`footer-region-option-${i}`} key={key} value={r.destination}>
                            {r.label}
                        </option>
                    );
                })}
            </RegionSelect>
        </RegionSelectWrap>
    </div>
);

const Social = ({ config }) => (
    <div>
        <HeaderLabel>{config.label}</HeaderLabel>
        <SocialIconList>
            {config.options.map((r, i) => {
                const key = `region-${i}`;
                return (
                    <li key={key}>
                        <SocialIcon
                            data-qa={`footer-social-option-${i}`}
                            href={r.destination}
                            target="_blank"
                            rel="noopener noreferrer"
                            icon={r.image}
                            title={r.label}
                        />
                    </li>
                );
            })}
        </SocialIconList>
    </div>
);
