import React from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";

const NavLinkWrapper = styled.div`
    label: NavLinkWrapper;

    padding: 1em;
    border-bottom: 1px solid #555;
`;

const NavLinkStyled = styled.a`
    label: NavLinkStyled;

    color: #fff;
    display: block;
    font-family: effra, sans-serif;
    font-size: 1.4em;
    padding: 10px 0;
    text-decoration: none;
    :hover {
        text-decoration: none;
    }
`;

const MobileNavLink = (props) => {
    const { dataQa, destination, children, openNew, selected } = props;
    let specialProps = {
        selected
    };
    if (openNew) {
        specialProps = {
            target: "_blank",
            rel: "noopener noreferrer"
        };
    }
    if (destination) {
        // Links outside of the immediate gatsby hub/site
        return (
            <NavLinkWrapper {...props}>
                <NavLinkStyled data-qa={dataQa} href={destination} {...specialProps}>
                    {children}
                </NavLinkStyled>
            </NavLinkWrapper>
        );
    }
    // this is for the case where we want the look and feel of the nav link with no action. children
    return (
        <NavLinkWrapper>
            <NavLinkStyled data-qa={dataQa} {...specialProps} {...props}>
                {children}
            </NavLinkStyled>
        </NavLinkWrapper>
    );
};

MobileNavLink.propTypes = {
    dataQa: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    destination: PropTypes.string,
    external: PropTypes.bool,
    openNew: PropTypes.bool
};

MobileNavLink.defaultProps = {
    dataQa: null,
    destination: null,
    external: false,
    openNew: false
};

export { NavLinkStyled };
export default MobileNavLink;
