import React from "react";
import { componentServices } from "@resourcehub/resourcehub-services";
import { fieldTypes } from "../../component-proptypes";

class HowHear extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isOther: false
        };
    }

    interceptChange = (event) => {
        const { handleInputChange } = this.props;
        if (event.target.value === "Other") {
            this.setState({
                isOther: true
            });
            // event.target.value = "";
            handleInputChange(event);
        } else {
            this.setState({
                isOther: false
            });
            handleInputChange(event);
        }
    };

    render() {
        const { dataQa, error, data, handleInputChange, labels } = this.props;
        const { isOther } = this.state;
        const { form_how_blog, form_how_email, form_how_event, form_how_online, form_how_referral } = labels;
        return (
            <>
                <div className={`roleContainer ${componentServices.createFieldClasses(error, data)}`}>
                    <select
                        data-qa={dataQa}
                        id="howHear"
                        name="howDidYouHearAboutUs1"
                        onChange={this.interceptChange}
                        value={data}
                    >
                        <option data-qa="how-hear-option-0" value="" style={{ display: "none" }} />
                        <option data-qa="how-hear-option-1" value="Blog">
                            {form_how_blog}
                        </option>
                        <option data-qa="how-hear-option-2" value="Email">
                            {form_how_email}
                        </option>
                        <option data-qa="how-hear-option-3" value="Event">
                            {form_how_event}
                        </option>
                        <option data-qa="how-hear-option-4" value="Online Ad">
                            {form_how_online}
                        </option>
                        <option data-qa="how-hear-option-5" value="Referral">
                            {form_how_referral}
                        </option>
                        <option data-qa="how-hear-option-6" value="Search Engine">
                            {labels.form_how_search}
                        </option>
                        <option data-qa="how-hear-option-7" value="Social Media">
                            {labels.form_how_social}
                        </option>
                        <option data-qa="how-hear-option-8" value="Webinar">
                            {labels.form_how_webinar}
                        </option>
                        <option data-qa="how-hear-option-9" value="Other">
                            {labels.form_how_other}
                        </option>
                    </select>
                    <label data-qa={`${dataQa}-label`} htmlFor="howDidYouHearAboutUs1" aria-labelledby="howHear">
                        {labels.form_how_title}
                    </label>
                </div>
                {isOther && (
                    <div className={`${componentServices.createFieldClasses(error, data)}`}>
                        <input
                            id="howHearOther"
                            onChange={handleInputChange}
                            value={data}
                            type="text"
                            name="howDidYouHearAboutUs1"
                            maxLength="255"
                        />
                        <label htmlFor="howHearOther">{labels.more_specify}</label>
                    </div>
                )}
            </>
        );
    }
}

HowHear.propTypes = {
    ...fieldTypes
};

export default HowHear;
