import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import Swiper from "swiper/bundle";

// Styles
import TitleWrapper from "../component-styles/TitleWrapper";
import PageSectionWrapper, { PageSectionWrapperPropTypes } from "../component-styles/PageSectionWrapper";
import ChildContentWrapper from "../component-styles/ChildContentWrapper";

// Components
import ImageText, { ImageTextPropTypes } from "../components/Images/ImageText";

// Carousel
const SwiperContainer = styled.div`
    label: SwiperWrapper;
`;
const SwiperWrapper = styled.div`
    label: SwiperWrapper;
`;
const CarouselContainer = styled.div`
    label: CarouselContainer;

    width: 100%;
    > div {
        margin-left: 2em;
        margin-right: 2em;
    }
`;
const CarouselNavigationBullets = styled.div`
    label: CarouselNavigationBullets;

    .swiper-pagination-bullet {
        background-color: inherit !important;
        border: 2px solid #666666;
        height: 10px;
        margin-left: 13px !important;
        margin-right: 13px !important;
        width: 10px;
    }

    .swiper-pagination-bullet-active {
        background-color: #666666 !important;
    }
`;
const CarouselNavigationNext = styled.div`
    label: CarouselNavigationNext;

    --swiper-navigation-color: #666666;
`;
const CarouselNavigationPrevious = styled.div`
    label: CarouselNavigationPrevious;

    --swiper-navigation-color: #666666;
`;
class ImageTextCarousel extends Component {
    state = {
        activeCard: 1,
        maxCardsBeforePaging: 4
    };
    componentDidMount() {
        const carouselOptions = {
            centeredSlides: true,
            cssMode: true,
            keyboard: true,
            loop: true,
            mousewheel: true,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev"
            },
            pagination: {
                el: ".swiper-pagination"
            }
        };

        new Swiper(".swiper-container", carouselOptions);
    }

    render() {
        const { dataQa, panels, title } = this.props;
        if (!panels) return null;
        const { activeCard } = this.state;
        return (
            <CarouselContainer>
                {title && <TitleWrapper {...title} />}
                {panels && (
                    <SwiperContainer className="swiper-container">
                        <SwiperWrapper className="swiper-wrapper">
                            {panels.map((panel, index) => (
                                <ImageText
                                    className="swiper-slide"
                                    data-qa={`image-text-carousel-slide-${dataQa}-${index}`}
                                    key={`image-text-carousel-slide-${dataQa}-${index}`}
                                    {...panel}
                                    active={activeCard === index + 1}
                                />
                            ))}
                            {/* Add Arrows */}

                            <CarouselNavigationBullets className="swiper-pagination" />
                            <CarouselNavigationNext
                                className="swiper-button-next"
                                visible={panels.length > this.state.maxCardsBeforePaging}
                            />
                            <CarouselNavigationPrevious
                                className="swiper-button-prev"
                                visible={panels.length > this.state.maxCardsBeforePaging}
                            />
                        </SwiperWrapper>
                    </SwiperContainer>
                )}
            </CarouselContainer>
        );
    }
}

// List
const ImageTextListContainer = styled.div`
    label: ImageTextListContainer;

    display: flex;
    flex-direction: column;
    width: 100%;
`;

const ImageTextList = ({ dataQa, panels, wrapper, children }) => {
    if (!panels) return null;
    return (
        <PageSectionWrapper {...wrapper}>
            {panels && (
                <ImageTextListContainer>
                    {panels.map((panel, i) => (
                        <ImageText dataQa={dataQa} key={`image-text-${i}`} {...panel} />
                    ))}
                </ImageTextListContainer>
            )}
            {children && <ChildContentWrapper>{children}</ChildContentWrapper>}
        </PageSectionWrapper>
    );
};

// Root Component
const PageSectionImageText = (props) => {
    switch (props.layout) {
        case "carousel":
            return <ImageTextCarousel {...props} />;
        case "list":
            return <ImageTextList {...props} />;
    }
};
const PageSectionImageTextPropTypes = {
    dataQa: PropTypes.string,
    layout: PropTypes.oneOf(["carousel", "list"]),

    // Carousel Options
    autoplay: PropTypes.bool,
    autoplayDelay: PropTypes.number,

    panels: PropTypes.arrayOf(PropTypes.shape(ImageTextPropTypes)).isRequired,

    // Page Section Wrapper
    wrapper: PropTypes.shape(PageSectionWrapperPropTypes)
};
PageSectionImageText.defaultProps = {
    autoplay: false,
    autoplayDelay: 2500,
    dataQa: "page-section-image-text",

    layout: "list",
    justifyContent: "center"
};
PageSectionImageText.propTypes = PageSectionImageTextPropTypes;
export default PageSectionImageText;
