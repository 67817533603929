import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { MOBILE_BREAKPOINT } from "../../styles/style-constants";
import FormModalCTA from "../../components-obsolete/Modal/FormModalCTA";
import ButtonStyled from "../Buttons/ButtonStyled";

const FooterSection = styled.section`
    label: FooterSection;

    background-color: #0098db;

    font-family: effra, sans-serif;
    font-size: 0.8em;
    font-weight: 100;

    color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto;
    padding: 1em 0;
`;
const ContentDiv = styled.div`
    label: ContentDiv;

    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    h2 {
        font-size: 1.5rem;
        font-family: helvetica_bold, sans-serif;
        margin-right: 1em;
        margin-top: 0.5em;
        margin-bottom: 0.5em;
    }

    h3 {
        font-size: 1rem;
        font-family: helvetica_bold, sans-serif;
        margin-right: 1em;
        line-height: 35px;
    }
    button,
    input[type="submit"] {
        background-color: #ecc200;
        border-radius: 8px;
        box-shadow: none;
        box-sizing: border-box;
        font-family: effra_medium, sans-serif;
        font-size: 14px;
        justify-content: center;
        letter-spacing: 1.47px;
        margin-left: 1em;
        padding: 1em 5em;
        position: relative;
        text-align: center;
        text-transform: uppercase;
        transition: all 0.1s ease-in-out;
    }
    input[type="text"],
    input[type="tel"],
    input[type="email"],
    select {
        border-radius: 8px;
        border: 1px solid #999;
        font-size: 16px;
        min-width: 280px;
        padding: 12px 12px 12px 20px;
        width: 100%;
    }

    @media (min-width: ${MOBILE_BREAKPOINT}) {
        padding: 0 1.5em;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
    }
    @media (max-width: ${MOBILE_BREAKPOINT}) {
        button {
            margin-left: 0.5em;
        }
        h2 {
            font-size: 1.5em;
            font-family: helvetica_bold, sans-serif;
            margin-left: 1em;
            margin-bottom: 1em;
            text-align: center;
        }
        padding: 0 1.5em;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
    }
`;

class CallToActionBar extends React.Component {
    state = {
        email: ""
    };

    emailChange = (e) => {
        this.setState({
            email: e.value
        });
    };

    render() {
        const { config, eloquaPage } = this.props;
        return (
            <FooterSection data-qa={"footer-call-to-action-bar"}>
                <ContentDiv>
                    <div>
                        <h2 data-qa={"footer-call-to-action-bar-title"}>{config.main_label}</h2>
                    </div>
                    {config.destination_url ? (
                        <ButtonStyled
                            text={config.button_label}
                            size="regular"
                            theme="yellow_black"
                            dataQa={"call-to-action-bar-request-pricing-button"}
                            destination={config.destination_url}
                            new_window={false}
                            internal={config.internal}
                        />
                    ) : (
                        config.contact_modal && (
                            <div>
                                <FormModalCTA
                                    dataQa={"call-to-action-bar-request-pricing-button"}
                                    btn_classes="btn btn-bg"
                                    btn_title={config.button_label}
                                    pricing_title={config.form_title}
                                    pricing_subtitle={config.form_subtitle}
                                    domain={config.domain}
                                    vertical={config.vertical}
                                    market={config.market}
                                    page={eloquaPage}
                                    copyData={{
                                        market: config.market,
                                        domain: config.domain,
                                        vertical: config.vertical,

                                        form_firstname: config.form_firstname,
                                        form_lastname: config.form_lastname,
                                        form_email: config.form_email,
                                        form_phone: config.form_phone,
                                        country: config.form_country,

                                        form_organization: config.form_organization,
                                        form_100: config.form_organizationsize_100,
                                        form_500: config.form_organizationsize_500,
                                        form_1000: config.form_organizationsize_1000,
                                        form_5000: config.form_organizationsize_5000,
                                        form_10000: config.form_organizationsize_10000,
                                        form_25000: config.form_organizationsize_25000,
                                        form_50000: config.form_organizationsize_50000,
                                        form_more: config.form_organizationsize_more,
                                        form_organizationsize: config.form_organizationsize,

                                        form_role: config.form_role,
                                        form_individualcontributor: config.form_role_individualcontributor,
                                        form_manager: config.form_role_manager,
                                        form_executive: config.form_role_executive,
                                        form_vicepresident: config.form_role_vicepresident,
                                        form_director: config.form_role_director,
                                        form_teacher: config.form_role_teacher,
                                        form_techcoordinator: config.form_role_techcoordinator,
                                        form_curriculumdirector: config.form_role_curriculumdirector,
                                        form_principal: config.form_role_principal,
                                        form_boardmember: config.form_role_boardmember,
                                        form_superintendent: config.form_role_superintendent,
                                        comments: config.form_comments,
                                        form_updateagreement1: config.footer_opt_in_text_before_button,
                                        form_updateagreement2: config.footer_opt_in_learn_more_button_text,
                                        form_updateagreement3: config.footer_opt_in_text_after_button,
                                        form_privacy1: config.footer_opt_in_learn_more_text_1,
                                        form_privacy2: config.footer_opt_in_learn_more_link_text_1,
                                        form_privacy3: config.footer_opt_in_learn_more_text_2,
                                        form_privacy4: config.footer_opt_in_learn_more_text_3,
                                        form_privacyemail: config.footer_opt_in_learn_more_privacyemail,
                                        form_privacyUrl: config.footer_opt_in_learn_more_privacyUrl,
                                        form_how_blog: config.footer_how_hear_blog,
                                        form_how_email: config.footer_how_hear_email,
                                        form_how_event: config.footer_how_hear_event,
                                        form_how_online: config.footer_how_hear_online,
                                        form_how_referral: config.footer_how_hear_referral,
                                        form_how_title: config.footer_how_hear_title,
                                        form_how_search: config.footer_how_hear_search,
                                        form_how_social: config.footer_how_hear_social,
                                        form_how_webinar: config.footer_how_hear_webinar,
                                        form_how_other: config.footer_how_hear_other,
                                        form_submit: config.form_submit,
                                        pricing_thanks: config.form_pricing_thanks
                                    }}
                                />
                            </div>
                        )
                    )}
                </ContentDiv>
            </FooterSection>
        );
    }
}

CallToActionBar.propTypes = { config: PropTypes.any, eloquaPage: PropTypes.string };

export default CallToActionBar;
