import React from "react";
import FeatureButton from "./FeatureButton";
import Phone from "./Phone";
import Rating from "./Rating";
import { services } from "@resourcehub/resourcehub-assets";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useFeatureSelect } from "./useFeatureSelect";

const { iconSelector } = services;

const AppSectionRight = styled.section`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 50px;
    margin-bottom: 100px;
    padding: 0 4em;
    position: relative;

    @media (max-width: 1024px) {
        flex-direction: column;
        justify-content: center;
        margin: 0 auto;
        padding: 0;
    }
`;

const SectionImage = styled.div`
    position: relative;
    width: 50vw;

    @media (max-width: 1024px) {
        width: 100%;
    }

    @media (max-width: 425px) {
        margin: 0;
    }
`;

const AppTopContent = styled.div`
    text-align: center;
`;

const SectionTitle = styled.div`
    font-family: helvetica_bold, sans-serif;
    font-size: 36px;
    margin: 0;
    max-width: 17em;
    padding: 1em 0;

    p {
        text-align: center;
    }

    @media (max-width: 1024px) {
        text-align: center;
        max-width: initial;
        margin: 40px 0;
    }

    @media (max-width: 600px) {
        text-align: center;
        margin: 17px 0;
        font-size: 30px;
    }
`;

const SectionContentContainer = styled.div`
    z-index: 1;
    display: flex;
    width: 70%;

    @media (max-width: 1024px) {
        width: 100%;
        flex-direction: column;
    }

    @media (max-width: 660px) {
        min-width: 0;
    }
`;

const AppContent = styled.div`
    text-align: center;
    box-sizing: border-box;
    display: grid;
    max-width: 900px;
    margin: auto;

    @media (max-width: 1024px) {
        grid-template-columns: repeat(3, 1fr);
        padding: 0;
        margin: 0;
        padding: 0;
    }

    @media (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
        width: 100%;
    }

    @media (max-width: 490px) {
        grid-template-columns: repeat(1, 1fr);
        justify-items: center;
    }
`;

const LearnerFeatures = ({ data, pageData, isHomePage }) => {
    const { selected, changeSelected } = useFeatureSelect();

    return (
        <AppSectionRight isHomePage={isHomePage}>
            <SectionImage>
                <AppTopContent>
                    <SectionTitle isHomePage={isHomePage}>{pageData.learner_feature_title}</SectionTitle>
                    <Phone data={data} selected={selected} isAdminFeatures={false} />
                    <Rating isDesktopView={true} />
                </AppTopContent>
            </SectionImage>
            <SectionContentContainer>
                <AppContent>
                    <FeatureButton
                        index="1"
                        title={pageData.learner_feature_1_title}
                        subtitle={pageData.learner_feature_1_subtitle}
                        icon={iconSelector(pageData.learner_feature_1_icon_name)}
                        selected={selected}
                        changeSelected={(index) => changeSelected(index)}
                    />
                    <FeatureButton
                        index="2"
                        title={pageData.learner_feature_2_title}
                        subtitle={pageData.learner_feature_2_subtitle}
                        icon={iconSelector(pageData.learner_feature_2_icon_name)}
                        selected={selected}
                        changeSelected={(index) => changeSelected(index)}
                    />
                    <FeatureButton
                        index="3"
                        title={pageData.learner_feature_3_title}
                        subtitle={pageData.learner_feature_3_subtitle}
                        icon={iconSelector(pageData.learner_feature_3_icon_name)}
                        selected={selected}
                        changeSelected={(index) => changeSelected(index)}
                    />
                    <FeatureButton
                        index="4"
                        title={pageData.learner_feature_4_title}
                        subtitle={pageData.learner_feature_4_subtitle}
                        icon={iconSelector(pageData.learner_feature_4_icon_name)}
                        selected={selected}
                        changeSelected={(index) => changeSelected(index)}
                    />
                    <FeatureButton
                        index="5"
                        title={pageData.learner_feature_5_title}
                        subtitle={pageData.learner_feature_5_subtitle}
                        icon={iconSelector(pageData.learner_feature_5_icon_name)}
                        selected={selected}
                        changeSelected={(index) => changeSelected(index)}
                    />
                    <FeatureButton
                        index="6"
                        title={pageData.learner_feature_6_title}
                        subtitle={pageData.learner_feature_6_subtitle}
                        icon={iconSelector(pageData.learner_feature_6_icon_name)}
                        selected={selected}
                        changeSelected={(index) => changeSelected(index)}
                    />
                </AppContent>
                <Rating isDesktopView={false} />
            </SectionContentContainer>
        </AppSectionRight>
    );
};

export const LearnerFeaturesPropTypes = {
    pageData: PropTypes.shape({
        learner_feature_title: PropTypes.string.isRequired,
        learner_feature_1_title: PropTypes.string.isRequired,
        learner_feature_1_subtitle: PropTypes.string.isRequired,
        learner_feature_1_icon_name: PropTypes.string.isRequired,
        learner_feature_2_title: PropTypes.string.isRequired,
        learner_feature_2_subtitle: PropTypes.string.isRequired,
        learner_feature_2_icon_name: PropTypes.string.isRequired,
        learner_feature_3_title: PropTypes.string.isRequired,
        learner_feature_3_subtitle: PropTypes.string.isRequired,
        learner_feature_3_icon_name: PropTypes.string.isRequired,
        learner_feature_4_title: PropTypes.string.isRequired,
        learner_feature_4_subtitle: PropTypes.string.isRequired,
        learner_feature_4_icon_name: PropTypes.string.isRequired,
        learner_feature_5_title: PropTypes.string.isRequired,
        learner_feature_5_subtitle: PropTypes.string.isRequired,
        learner_feature_5_icon_name: PropTypes.string.isRequired,
        learner_feature_6_title: PropTypes.string.isRequired,
        learner_feature_6_subtitle: PropTypes.string.isRequired,
        learner_feature_6_icon_name: PropTypes.string.isRequired
    }).isRequired,
    data: PropTypes.object.isRequired,
    selected: PropTypes.string,
    handleMouseOut: PropTypes.func,
    changeSelected: PropTypes.func
};

LearnerFeatures.propTypes = LearnerFeaturesPropTypes;

export default LearnerFeatures;
