import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import Animation, { AnimationPropTypes } from "./Animation";
import ChildContentWrapper from "../../component-styles/ChildContentWrapper";
import TitleWrapper, { TitleWrapperPropTypes } from "../../component-styles/TitleWrapper";
import { BodyWrapperPropTypes } from "../../component-styles/BodyWrapper";

const AnimationTextWrapperDiv = styled.div`
    label: AnimationTextWrapperDiv;

    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;

    //Mobile-landscape (and larger)
    @media (min-width: 320px) {
        flex-direction: column;
    }
    //Mobile-landscape (and larger)
    @media (min-width: 480px) {
        flex-direction: column;
    }
    // Tablet-portrait (and larger)
    @media (min-width: 768px) {
        flex-direction: column;
    }
    // Tablet-landscape (and larger)
    @media (min-width: 992px) {
        flex-direction: column;
    }
    // Laptops (and longer)
    @media (min-width: 1200px) {
        flex-direction: row;
        ${({ direction }) => (direction === "image-left" ? "flex-direction: row;" : "flex-direction: row-reverse;")}
    }
`;
const TextWrapper = styled.div`
    label: ContentWrapper;

    padding: 20px;
    width: 100%;

    //Mobile-landscape (and larger)
    @media (min-width: 320px) {
        text-align: center;
    }
    //Mobile-landscape (and larger)
    @media (min-width: 480px) {
        text-align: center;
    }
    // Tablet-portrait (and larger)
    @media (min-width: 768px) {
        text-align: center;
    }
    // Tablet-landscape (and larger)
    @media (min-width: 992px) {
        text-align: center;
    }
    // Laptops (and longer)
    @media (min-width: 1200px) {
        text-align: left;
    }
`;
const BodyWrapper = styled.div`
    label: BodyWrapper;

    font-family: effra, sans-serif;
    font-size: 16px;

    letter-spacing: 0.144px;
    line-height: 32px;

    margin-bottom: 20px;
    margin-top: 20px;

    strong {
        color: #0099e1;
        font-weight: normal;
        font-size: 18px;
        letter-spacing: 0;
        line-height: 32px;
    }
`;

const AnimationText = ({ body, children, dataQa, direction, animation, title }) => {
    return (
        <AnimationTextWrapperDiv data-qa={`content-section-header-${dataQa}`} direction={direction}>
            <Animation {...animation} />
            <TextWrapper>
                {title && <TitleWrapper {...title}>{title.content}</TitleWrapper>}
                {body && (
                    <BodyWrapper
                        data-qa={`content-section-body-${dataQa}`}
                        dangerouslySetInnerHTML={{ __html: body.content }}
                    />
                )}
                {children && <ChildContentWrapper>{children}</ChildContentWrapper>}
            </TextWrapper>
        </AnimationTextWrapperDiv>
    );
};

export const AnimationTextPropTypes = {
    animation: PropTypes.shape(AnimationPropTypes).isRequired,
    body: PropTypes.shape(BodyWrapperPropTypes),
    dataQa: PropTypes.string,
    direction: PropTypes.oneOf(["image-left", "image-right"]),
    title: PropTypes.shape(TitleWrapperPropTypes)
};
AnimationText.propTypes = AnimationTextPropTypes;
AnimationText.defaultProps = {
    direction: "image-left",
    title: {
        type: "section-title"
    }
};
export default AnimationText;
