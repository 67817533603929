import React from "react";
import PropTypes from "prop-types";
import { services } from "@resourcehub/resourcehub-assets";
import deprecated from "../deprecated";

const { iconSelector } = services;

const Features = ({ data, img_bg_top, img_bg_bottom }) => {
    const features = [];

    for (let i = 1; i <= 15; i += 1) {
        const iconKey = `feature_${i}_img`;
        const iconName = data[iconKey];
        const icon = iconSelector(iconName);
        if (iconName) {
            features.push(
                <div className="feature__container" key={data[`feature_${i}_title`]}>
                    <img className="feature__img" src={icon} alt={`${data[`feature_${i}_title`]} icon`} />
                    <div className="feature__title">{data[`feature_${i}_title`]}</div>
                    <div className="feature__subtitle">{data[`feature_${i}_subtitle`]}</div>
                </div>
            );
        }
    }

    return (
        <div className="features__wrapper">
            <img className="img_bg__features_1" src={img_bg_top} alt="blue background" />
            <img className="img_bg__features_2" src={img_bg_bottom} alt="yellow background" />
            <h2 className="section__title">{data.feature_section_title}</h2>
            <div className="features">{features}</div>
        </div>
    );
};

Features.propTypes = {
    data: PropTypes.shape({
        feature_section_title: PropTypes.string.isRequired,
        feature_1_title: PropTypes.string.isRequired,
        feature_1_subtitle: PropTypes.string.isRequired,
        feature_1_img: PropTypes.string.isRequired
    }).isRequired,
    img_bg_top: PropTypes.string.isRequired
};

export default deprecated(Features);
