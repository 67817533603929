import React from "react";
import { navigate } from "gatsby";

import { images, logos } from "@resourcehub/resourcehub-assets";
import ContentSection from "../ContentSection/ContentSection";
import LearnerFeatures from "../../components/Features/LearnerFeatures";
import AdminFeatures from "../../components-obsolete/Features/AdminFeatures";
import SpeakRightCarousel from "../SpeakRightCarousel/SpeakRightCarousel";
import DemoModalCallToAction from "../Modal/DemoModalCallToAction";
import FormModalCTA from "../Modal/FormModalCTA";
import FormFollowUpModal from "../Modal/FormFollowUpModal";
import PricingSection from "../PricingSection/PricingSection";
import AwardsNew from "../AwardsNew/AwardsNew";
import { domainType, verticalType } from "../../component-proptypes";
import "../../styles/home.less";
import deprecated from "../deprecated";

const AdwordsLanding = ({ pageData, data, domain, vertical, copyData }) => {
    const { section_1_img_1, iphone_blue, tutor_screen, section_5_img_1 } = data;
    return (
        <>
            <main className="main home">
                <ContentSection
                    sectionClasses="section__left section_1"
                    img={section_1_img_1}
                    title={pageData.section_1_title}
                    mainTitle
                    body={pageData.section_1_body}
                    cta_caption={pageData.section_1_cta_caption}
                    resourcesLink={copyData.resources_link}
                    vertical={vertical}
                    hasCombinedResources={copyData.has_combined_resources}
                    market={copyData.market}
                    content_child={
                        <>
                            {pageData.hide_demo ? (
                                <FormFollowUpModal
                                    buttonClasses="btn btn-blue btn-sm"
                                    buttonBody={pageData.section_1_fallback_cta}
                                    copyData={copyData}
                                    vertical={vertical}
                                    domain={domain}
                                    modalBody={
                                        <iframe title="Business Overview" src={pageData.section_1_fallback_video} />
                                    }
                                    afterSubmit={() => navigate("/sp1/")}
                                />
                            ) : (
                                <DemoModalCallToAction
                                    pageData={pageData}
                                    vertical={vertical}
                                    copyData={copyData}
                                    domain={domain}
                                    section_1_fallback_video={pageData.section_1_fallback_video}
                                    afterSubmit={() => navigate("/sp1/")}
                                />
                            )}
                            <FormModalCTA
                                btn_title={pageData.section_1_secondary_cta}
                                pricing_title={copyData.pricing_title}
                                pricing_subtitle={copyData.pricing_subtitle}
                                domain={domain}
                                vertical={vertical}
                                copyData={copyData}
                                afterSubmit={() => navigate("/sp1/")}
                            />
                        </>
                    }
                />
                <ContentSection
                    sectionClasses="section__right section_2"
                    img_bg={images.section_2StoneBlueSvg}
                    img_child={<SpeakRightCarousel data={data} />}
                    title={pageData.section_2_title}
                    body={pageData.section_2_body}
                    quote={pageData.section_2_quote}
                    link={pageData.section_2_link}
                    logo={logos[pageData.section_2_logo]}
                    resourcesLink={copyData.resources_link}
                    vertical={vertical}
                    hasCombinedResources={copyData.has_combined_resources}
                    market={copyData.market}
                />
                <ContentSection
                    sectionClasses="section__left section_3"
                    img_bg={images.section_3StoneGreySvg}
                    img_main={iphone_blue}
                    title={pageData.section_3_title}
                    body={pageData.section_3_body}
                    quote={pageData.section_3_quote}
                    link={pageData.section_3_link}
                    logo={logos[pageData.section_3_logo]}
                    resourcesLink={copyData.resources_link}
                    vertical={vertical}
                    hasCombinedResources={copyData.has_combined_resources}
                    market={copyData.market}
                />
                <ContentSection
                    sectionClasses="section__right section_4"
                    img_bg={images.section_4StoneYellowSvg}
                    img_main={tutor_screen}
                    title={pageData.section_4_title}
                    body={pageData.section_4_body}
                    quote={pageData.section_4_quote}
                    link={pageData.section_4_link}
                    logo={logos[pageData.section_4_logo]}
                    resourcesLink={copyData.resources_link}
                    vertical={vertical}
                    hasCombinedResources={copyData.has_combined_resources}
                    market={copyData.market}
                />
                <ContentSection
                    sectionClasses="section__left section_5"
                    img={section_5_img_1}
                    title={pageData.section_5_title}
                    body={pageData.section_5_body}
                    quote={pageData.section_5_quote}
                    link={pageData.section_5_link}
                    logo={logos[pageData.section_5_logo]}
                    resourcesLink={copyData.resources_link}
                    vertical={vertical}
                    hasCombinedResources={copyData.has_combined_resources}
                    market={copyData.market}
                />
                <PricingSection
                    pricing_title={copyData.pricing_title}
                    pricing_subtitle={copyData.pricing_subtitle}
                    pricing_thanks={copyData.pricing_thanks}
                    domain={domain}
                    vertical={vertical}
                    copyData={copyData}
                    afterSubmit={() => navigate("/sp1/")}
                />
                <LearnerFeatures img_bg={images.section_6StoneGreySvg} pageData={pageData} data={data} />
                {!pageData.hide_admin && (
                    <AdminFeatures
                        sectionClasses="section_7"
                        img_bg={images.section_7StoneBlueSvg}
                        pageData={pageData}
                        data={data}
                    />
                )}
            </main>
            <AwardsNew copyData={copyData} />
        </>
    );
};

AdwordsLanding.propTypes = {
    domain: domainType.isRequired,
    vertical: verticalType.isRequired
};

export default deprecated(AdwordsLanding);
