import React from "react";
import { images, logos } from "@resourcehub/resourcehub-assets";

import ContentSection from "../ContentSection/ContentSection";
import FormModalCTA from "../Modal/FormModalCTA";
import deprecated from "../deprecated";
import LeadGenerationButton from "../../components/LeadGeneration/LeadGenerationButton";
import PageSectionVideoText from "../../page-sections/PageSectionVideoText";
import GutterWrapper from "../../component-styles/GutterWrapper";
import QuoteAttribution from "../../components/Quotes/QuoteAttribution";
import FormFollowUpModal from "../Modal/FormFollowUpModal";
import { Link } from "gatsby";
import Video from "../Modal/Video";
import PageSectionImageText from "../../page-sections/PageSectionImageText";

const SolutionsFirst = ({ data, pageData, domain, ctaIsResource, vertical, copyData, page, config }) => {
    const { sol_section_1_img } = data;
    if (pageData.section_1_video_url && !config) {
        return (
            <ContentSection
                dataQa="1"
                body={pageData.section_1_body}
                cta_caption={pageData.section_1_cta_caption}
                hasCombinedResources={copyData.has_combined_resources}
                img_bg={images.solSection_1StoneBlueSvg}
                link={pageData.section_1_link}
                logo={logos[pageData.section_1_logo]}
                mainTitle
                market={copyData.market}
                quote={pageData.section_1_quote}
                resourcesLink={copyData.resources_link}
                sectionClasses="section__left section_1"
                title={pageData.section_1_title}
                vertical={vertical}
                img_child={
                    ctaIsResource ? (
                        <Video
                            buttonClasses="button__wrapper"
                            copyData={copyData}
                            domain={domain}
                            thumbnailSrc={sol_section_1_img}
                            title="Business Overview"
                            vertical={vertical}
                            videoSrc="https://www.youtube.com/embed/RYBGt3JqrT8"
                        />
                    ) : (
                        <Video
                            buttonClasses="button__wrapper"
                            copyData={copyData}
                            domain={domain}
                            thumbnailSrc={sol_section_1_img}
                            title="Business Overview"
                            vertical={vertical}
                            videoSrc={pageData.section_1_video_url}
                        />
                    )
                }
                content_child={
                    ctaIsResource ? (
                        <>
                            <Link
                                className="btn btn-blue btn-sm"
                                style={{ border: "none" }}
                                title={pageData.section_1_play_text}
                                to={pageData.section_1_video_url}
                            >
                                {pageData.section_1_play_text}
                            </Link>
                            <FormModalCTA
                                btn_title={pageData.section_1_secondary_cta}
                                copyData={copyData}
                                domain={domain}
                                page={page}
                                pricing_subtitle={copyData.pricing_subtitle}
                                pricing_title={copyData.pricing_title}
                                vertical={vertical}
                            />
                        </>
                    ) : (
                        <>
                            <FormFollowUpModal
                                buttonBody={pageData.section_1_play_text}
                                buttonClasses="btn btn-blue btn-sm"
                                copyData={copyData}
                                domain={domain}
                                modalBody={<iframe title="Business Overview" src={pageData.section_1_video_url} />}
                                page={page}
                                vertical={vertical}
                            />
                            <FormModalCTA
                                btn_title={pageData.section_1_secondary_cta}
                                copyData={copyData}
                                domain={domain}
                                page={page}
                                pricing_subtitle={copyData.pricing_subtitle}
                                pricing_title={copyData.pricing_title}
                                vertical={vertical}
                            />
                        </>
                    )
                }
            />
        );
    }

    if (
        config &&
        config.page.content.sections &&
        config.page.content.sections[0] &&
        config.page.content.sections[0].panels[0]
    ) {
        config.page.content.sections[0].panels[0].children = (
            <>
                <div>
                    <LeadGenerationButton
                        {...config.page.content.sections[0].panels[0].custom.lead_generation_button}
                    />
                </div>
                {config.page.content.sections[0].panels[0].quoteAttribution && (
                    <QuoteAttribution {...config.page.content.sections[0].panels[0].quoteAttribution} />
                )}
            </>
        );

        if (config.page.content.sections[0].panels[0].video) {
            return (
                <GutterWrapper>
                    <PageSectionVideoText
                        {...config.page.content.sections[0]}
                        currentVideoUrl={config.page.content.sections[0].panels[0].video.currentVideoUrl}
                    />
                </GutterWrapper>
            );
        }

        config.page.content.sections[0].panels[0].image.asset_fluid = sol_section_1_img;

        return (
            <GutterWrapper>
                <PageSectionImageText {...config.page.content.sections[0]} />
            </GutterWrapper>
        );
    }
    return (
        <ContentSection
            dataQa="1"
            body={pageData.section_1_body}
            cta_caption={pageData.section_1_cta_caption}
            cta_link={pageData.section_1_link}
            hasCombinedResources={copyData.has_combined_resources}
            img_bg={images.solSection_1StoneBlueSvg}
            img_main={sol_section_1_img}
            link={pageData.section_1_link}
            logo={logos[pageData.section_1_logo]}
            mainTitle
            market={copyData.market}
            quote={pageData.section_1_quote}
            resourcesLink={copyData.resources_link}
            sectionClasses="section__left section_1"
            title={pageData.section_1_title}
            vertical={vertical}
            content_child={
                <FormModalCTA
                    btn_title={pageData.section_1_secondary_cta}
                    copyData={copyData}
                    domain={domain}
                    page={page}
                    pricing_subtitle={copyData.pricing_subtitle}
                    pricing_title={copyData.pricing_title}
                    vertical={vertical}
                />
            }
        />
    );
};

export default deprecated(SolutionsFirst);
