import React from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import SecondaryNavLink from "./SecondaryNavLink";
import Solutions from "./Solutions";
import zIndex from "../../styles/z-indexes";

const SecondaryNavWrapper = styled.div`
    label: SecondaryNavWrapper;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    height: 38px;
    margin-top: 8px;
    background: #f9f9f9;
    position: relative;
    z-index: ${zIndex.nav_secondary};

    //Mobile-landscape (and larger)
    @media (min-width: 320px) {
        display: flex;
        flex-direction: row;
    }
    //Mobile-landscape (and larger)
    @media (min-width: 480px) {
        display: flex;
        flex-direction: row;
    }
    // Tablet-portrait (and larger)
    @media (min-width: 768px) {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
    // Tablet-landscape (and larger)
    @media (min-width: 992px) {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
    // Laptops (and longer)
    @media (min-width: 1200px) {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
`;
const SecondaryNavHeaderDiv = styled.div`
    label: SecondaryNavHeaderDiv;

    font-family: effra_bold, sans-serif;
    font-size: 14px;
    letter-spacing: 0.4px;
    font-weight: bold;

    border-right: 2px solid #d1cfcf;
    text-align: center;
    padding: 10px 48px;
    position: relative;

    //Mobile-landscape (and larger)
    @media (min-width: 320px) {
        display: none;
    }
    //Mobile-landscape (and larger)
    @media (min-width: 480px) {
        display: none;
    }
    // Tablet-portrait (and larger)
    @media (min-width: 768px) {
        display: block;
    }
    // Tablet-landscape (and larger)
    @media (min-width: 992px) {
        display: block;
    }
    // Laptops (and longer)
    @media (min-width: 1200px) {
        display: block;
    }
`;

const SecondaryNav = ({ config }) => (
    <SecondaryNavWrapper data-qa="sub-nav-bar">
        {config.vertical_header && config.vertical_header.visible && (
            <SecondaryNavHeaderDiv data-qa="sub-nav-current-vertical-2020">
                {config.vertical_header.label}
            </SecondaryNavHeaderDiv>
        )}
        {config.home && config.home.visible && <SecondaryNavLink dataQa="sub-nav-home-2020" {...config.home} />}
        {config.solutions && config.solutions.visible && (
            <Solutions dataQa="sub-nav-solutions-2020" {...config.solutions} />
        )}
        {config.blog && config.blog.visible && <SecondaryNavLink dataQa="sub-nav-blog-2020" {...config.blog} />}
        {config.resources && config.resources.visible && (
            <SecondaryNavLink dataQa="sub-nav-resources-2020" {...config.resources} />
        )}
        {config.custom_1 && config.custom_1.visible && (
            <SecondaryNavLink dataQa="sub-nav-custom-2020" {...config.custom_1} />
        )}
    </SecondaryNavWrapper>
);

SecondaryNav.propTypes = {
    config: PropTypes.object.isRequired
};

export default SecondaryNav;
