import React from "react";
import "../../styles/features.less";

const wrapFeatureFrenzy = (Component) => {
    return class WrappedFeatureFrenzy extends React.Component {
        state = {
            selected: 1,
            intervalId: "",
            isActive: false
        };

        componentDidMount() {
            this.createInterval();
        }

        componentWillUnmount = () => {
            const { intervalId } = this.state;
            clearInterval(parseInt(intervalId));
        };

        changeSelected = (index) => {
            const { intervalId } = this.state;
            clearInterval(intervalId);
            const indexNum = parseInt(index, 10);
            this.setState({
                selected: indexNum,
                isActive: true
            });
        };

        handleMouseOut = () => {
            this.setState({
                isActive: false
            });
        };

        createInterval = () => {
            if (typeof window !== "undefined") {
                const intervalId = window.setInterval(() => this.incrementCircle(), 4000);
                this.setState({
                    intervalId
                });
            }
        };

        incrementCircle = () => {
            const startNum = 1;
            const endNum = 6;
            this.setState((prevState) => {
                if (prevState.selected === endNum) {
                    return { selected: startNum }; // back to beginning;
                }
                const incremented = prevState.selected + 1;
                return { selected: incremented };
            });
        };

        render = () => {
            const { data, ...rest } = this.props;
            const { selected } = this.state;
            return (
                <Component
                    selected={selected.toString()}
                    changeSelected={this.changeSelected}
                    handleMouseOut={this.handleMouseOut}
                    data={data}
                    {...rest}
                />
            );
        };
    };
};

export default wrapFeatureFrenzy;
