import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
// import Swiper bundle with all modules installed
import Swiper from "swiper/bundle";
import "swiper/swiper-bundle.css";

// Styled
import TitleWrapper, { TitleWrapperPropTypes } from "../component-styles/TitleWrapper";
import PageSectionWrapper from "../component-styles/PageSectionWrapper";
import ChildContentWrapper from "../component-styles/ChildContentWrapper";

// Componentes
import QuoteAttribution, { QuoteAttributionPropTypes } from "../components/Quotes/QuoteAttribution";

// Carousel Layout
const QuoteAttributionCarouselSection = styled.section``;
const SwiperContainer = styled.div`
    label: SwiperContainer;

    display: flex;
    flex-direction: column;
    padding-bottom: 50px;

    .swiper-slide {
        text-align: center;
        font-size: 24px;
        width: 100% !important;

        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;
const SwiperWrapper = styled.div`
    label: SwiperWrapper;
`;
const SwiperPagination = styled.div`
    label: SwiperPagination;
`;
class QuoteAttributionCarousel extends Component {
    // Lifecycle Methods
    componentDidMount() {
        new Swiper(".swiper-container", {
            centeredSlides: true,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false
            },
            pagination: {
                el: ".swiper-pagination",
                clickable: true
            }
        });
    }
    render() {
        const { dataQa, panels, title } = this.props;
        if (!panels) return null;
        return (
            <QuoteAttributionCarouselSection data-qa={`content-section-header-${dataQa}`}>
                {title && <TitleWrapper {...title} />}
                <SwiperContainer className="swiper-container">
                    <SwiperWrapper className="swiper-wrapper">
                        {panels.map((panel, i) => (
                            <div key={`quote-attribution-${i}`} className="swiper-slide">
                                <QuoteAttribution {...panel} />
                            </div>
                        ))}
                    </SwiperWrapper>
                    <SwiperPagination className="swiper-pagination" />
                </SwiperContainer>
            </QuoteAttributionCarouselSection>
        );
    }
}

// List Layout
const QuoteAttributionListLayout = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
`;
const QuoteAttributionListContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;
const QuoteAttributionList = ({ dataQa, panels, title, children }) => {
    if (!panels) return null;
    return (
        <PageSectionWrapper data-qa={`content-section-header-${dataQa}`}>
            <QuoteAttributionListLayout>
                {title && <TitleWrapper {...title} />}
                {panels && (
                    <QuoteAttributionListContainer>
                        {panels.map((panel, i) => (
                            <QuoteAttribution key={`image-${i}`} {...panel} />
                        ))}
                    </QuoteAttributionListContainer>
                )}
            </QuoteAttributionListLayout>
            {children && <ChildContentWrapper>{children}</ChildContentWrapper>}
        </PageSectionWrapper>
    );
};

// Root Component
const PageSectionQuoteAttribution = (props) => {
    switch (props.layout) {
        case "carousel":
            return <QuoteAttributionCarousel {...props} />;
        // Add more layouts here
        case "List":
            return <QuoteAttributionList {...props} />;
    }
};
export const PageSectionQuoteAttributionPropTypes = {
    dataQa: PropTypes.string,
    layout: PropTypes.oneOf(["carousel", "list"]),
    panels: PropTypes.arrayOf(PropTypes.shape(QuoteAttributionPropTypes)).isRequired,
    title: PropTypes.shape(TitleWrapperPropTypes)
};
PageSectionQuoteAttribution.defaultProps = {
    dataQa: "qa",
    layout: "carousel"
};
PageSectionQuoteAttribution.propTypes = PageSectionQuoteAttributionPropTypes;
export default PageSectionQuoteAttribution;
