import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

const LinkContent = styled.div`
    label: LinkContent;

    cursor: pointer;
    display: inline-block;
    font-size: 1rem;
    line-height: 1.5;
    font-family: effra, sans-serif;

    ${({ theme }) => {
        if (theme === "blue") {
            return `
                a {
                    color: #0098DB;
                }
            `;
        }
        if (theme === "yellow") {
            return `
                a {
                    color: #f0c433 !important;
                }
            `;
        }
        if (theme === "black") {
            return `
                a {
                    color: #262626;
                }
            `;
        }
        if (theme === "white") {
            return `
                a {
                    color: #ffffff;
                }
            `;
        }
    }};
    ${({ align }) => {
        if (align === "left") {
            return `
                a {
                    text-align: left;
                }
            `;
        }
        if (align === "center") {
            return `
                a {
                    text-align: center;
                }
            `;
        }
        if (align === "right") {
            return `
                a {
                    text-align: right;
                }
            `;
        }
    }};
    ${({ underline }) => {
        if (underline === "off") {
            return `
                a {
                    text-decoration: none;
                    :hover {
                        text-decoration: none;
                    }
                }
            `;
        }
        if (underline === "hover") {
            return `
                a {
                    text-decoration: none;
                    :hover {
                        text-decoration: underline;
                    }
                }
            `;
        }
        if (underline === "on") {
            return `
                a {
                    text-decoration: underline;
                    :hover {
                        text-decoration: underline;
                    }
                }
            `;
        }
    }};
`;
const Link = React.forwardRef((props, ref) => {
    const { align, dataQa, label, theme, new_window, destination, underline, className, children, onClick } = props;
    if (onClick) {
        return (
            <LinkContent
                data-qa={dataQa}
                className={className}
                align={align}
                underline={underline}
                theme={theme}
                ref={ref}
            >
                <a onClick={onClick} target={new_window ? "_blank" : "_self"} rel="noopener noreferrer" {...props}>
                    {label} {children}
                </a>
            </LinkContent>
        );
    }
    return (
        <LinkContent data-qa={dataQa} className={className} align={align} underline={underline} theme={theme} ref={ref}>
            <a href={destination} target={new_window ? "_blank" : "_self"} rel="noopener noreferrer" {...props}>
                {label} {children}
            </a>
        </LinkContent>
    );
});

export const LinkPropTypes = {
    align: PropTypes.oneOf(["left", "center", "right"]),
    dataQa: PropTypes.string,
    destination: PropTypes.string,
    label: PropTypes.string,
    new_window: PropTypes.bool,
    onClick: PropTypes.func,
    theme: PropTypes.oneOf(["blue", "black", "yellow", "white"]),
    underline: PropTypes.oneOf(["off", "hover", "on"])
};
Link.propTypes = LinkPropTypes;
Link.defaultProps = {
    align: "center",
    theme: "blue",
    underline: "hover",
    new_window: true
};
export default Link;
