import React from "react";
import deprecated from "../deprecated";

const FeatureButton = ({ index, title, icon, subtitle, selected, changeSelected, handleMouseOut }) => {
    let titlesClasses = "app_feature";
    if (selected === index.toString()) {
        titlesClasses = "app_feature app_feature_active";
    }
    return (
        <div
            className={titlesClasses}
            onMouseEnter={() => changeSelected(index)}
            onTouchStart={() => changeSelected(index)}
            onMouseOut={handleMouseOut}
            onBlur={handleMouseOut}
        >
            <img src={icon} alt={`${title} icon`} />
            <div className="app_feature__copy">
                <div className="app_feature_title">{title}</div>
                <div className="app_feature_details">{subtitle}</div>
            </div>
        </div>
    );
};

export default deprecated(FeatureButton);
