import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

const TrustBoxWrapper = styled.div`
    label: TrustBoxWrapper;

    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 350px;
    max-width: 750px;
`;
const TrustBoxDiv = styled.div`
    label: TrustBoxDiv;

    flex: 1;
    background-color: #ffffff;
    padding: 20px;
    .tp-widget-wrapper-layout {
        height: 100%;
    }
`;

const TrustBox = ({ trustBoxRef, pageTitle, skuList, language }) => {
    const skuString = skuList.split("\n");
    return (
        <TrustBoxWrapper>
            <TrustBoxDiv
                ref={trustBoxRef} // We need a reference to this element to load the TrustBox in componentDidMount.
                className="trustpilot-widget" // Renamed this to className.
                //data-template-id="5763bccae0a06d08e809ecbb"
                data-template-id="5763bccae0a06d08e809ecbb"
                data-businessunit-id="542de05500006400057ab1c7"
                data-style-height="700px"
                data-style-width="100%"
                data-theme="light"
                data-locale={language}
                data-sku={skuString}
                data-name={pageTitle}
            />
        </TrustBoxWrapper>
    );
};

class TrustPilotRatings extends Component {
    constructor(props) {
        super(props);
        this.trustBoxRef = React.createRef();
    }

    componentDidMount() {
        // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
        // If it's not, it means the script you pasted into <head /> isn't loaded just yet.
        // When it is, it will automatically load the TrustBox.
        if (window.Trustpilot) {
            window.Trustpilot.loadFromElement(this.trustBoxRef.current, true);
        }
    }

    render() {
        return (
            <>
                <TrustBox
                    trustBoxRef={this.trustBoxRef}
                    pageTitle={this.props.pageTitle}
                    skuList={this.props.skuList}
                    language={this.props.language}
                />
            </>
        );
    }
}

export const TrustPilotRatingsPropTypes = {
    pageTitle: PropTypes.string,
    skuList: PropTypes.string.isRequired,
    language: PropTypes.string
};
TrustPilotRatings.propTypes = TrustPilotRatingsPropTypes;
TrustPilotRatings.defaultProps = {};
export default TrustPilotRatings;
