import React from "react";
import PropTypes from "prop-types";
import { componentServices } from "@resourcehub/resourcehub-services";
import { fieldTypes } from "../../component-proptypes";

const Role = ({ dataQa, vertical, error, data, handleInputChange, labels }) => {
    let optionVals;

    switch (vertical) {
        case "enterprise":
        case "business":
            optionVals = [
                {
                    label: labels.form_individualcontributor,
                    value: "Individual Contributor"
                },
                {
                    label: labels.form_manager,
                    value: "Manager"
                },
                {
                    label: labels.form_executive,
                    value: "General Management/Executive"
                },
                {
                    label: labels.form_vicepresident,
                    value: "Vice President"
                },
                {
                    label: labels.form_director,
                    value: "Director"
                }
            ];
            break;
        case "publicsector":
        case "pubsec":
            optionVals = [
                {
                    label: labels.form_individualcontributor,
                    value: "Individual Contributor"
                },
                {
                    label: labels.form_director,
                    value: "Director"
                },
                {
                    label: labels.form_executive,
                    value: "General Management/Executive"
                },
                {
                    label: labels.form_manager,
                    value: "Manager"
                },
                {
                    label: labels.form_vicepresident,
                    value: "Vice President"
                }
            ];
            break;
        case "education-primary":
        case "k12":
            optionVals = [
                {
                    label: labels.form_teacher,
                    value: "Teacher"
                },
                {
                    label: labels.form_techcoordinator,
                    value: "Technology Coordinator"
                },
                {
                    label: labels.form_curriculumdirector,
                    value: "Curriculum Director/Instruction Coordinator"
                },
                {
                    label: labels.form_principal,
                    value: "Principal/Vice Principal"
                },
                {
                    label: labels.form_boardmember,
                    value: "Board Member"
                },
                {
                    label: labels.form_superintendent,
                    value: "Superintendent/District Administrator"
                }
            ];
            break;
        case "education-secondary":
        case "hed":
            optionVals = [
                {
                    label: labels.form_teacher,
                    value: "Teacher"
                },
                {
                    label: labels.form_techcoordinator,
                    value: "Technology Coordinator"
                },
                {
                    label: labels.form_curriculumdirector,
                    value: "Curriculum Director/Instruction Coordinator"
                },
                {
                    label: labels.form_principal,
                    value: "Principal/Vice Principal"
                },
                {
                    label: labels.form_boardmember,
                    value: "Board Member"
                },
                {
                    label: labels.form_superintendent,
                    value: "Superintendent/District Administrator"
                }
            ];
            break;
    }

    const options = optionVals.map((node, i) => {
        const key = `role-${i}`;
        return (
            <option data-qa={`role-option-${i}`} key={key} value={node.value}>
                {node.label}
            </option>
        );
    });
    return (
        <div className={`roleContainer ${componentServices.createFieldClasses(error, data)}`}>
            <select
                data-qa={dataQa}
                id="role"
                name="role"
                autoComplete="organization-title"
                required
                onChange={handleInputChange}
                value={data}
            >
                {/* <option value="">Role (or title)*</option> */}
                <option value="" style={{ display: "none" }} />
                {options}
            </select>
            <label data-qa={`${dataQa}-label`} htmlFor="role">
                {labels.form_role}
            </label>
        </div>
    );
};

Role.propTypes = {
    vertical: PropTypes.string.isRequired,
    ...fieldTypes
};

export default Role;
