import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

// Styles
import TitleWrapper, { TitleWrapperPropTypes } from "../component-styles/TitleWrapper";
import ChildContentWrapper from "../component-styles/ChildContentWrapper";

// Components
import Vertical, { VerticalPropTypes } from "../components/Verticals/Vertical";

const VerticalsSection = styled.section`
    label: VerticalsSection;
`;

const BodyWrapper = styled.div`
    label: BodyWrapper;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;

    font-family: effra, sans-serif;
    font-size: 16px;

    letter-spacing: 0.144px;
    line-height: 32px;

    margin-bottom: 20px;
    margin-top: 50px;
`;

const PageSectionVertical = ({ title, panels, dataQa, children }) => (
    <VerticalsSection data-qa={`page-section-vertical-${dataQa}`}>
        {title && <TitleWrapper {...title} />}
        <BodyWrapper data-qa={`page-section-vertical-body-${dataQa}`}>
            {panels.map((v, i) => (
                <Vertical {...v} key={`page-section-vertical-${i}`} />
            ))}
        </BodyWrapper>
        {children && <ChildContentWrapper>{children}</ChildContentWrapper>}
    </VerticalsSection>
);
export const PageSectionVerticalPropTypes = {
    dataQa: PropTypes.string,
    panels: PropTypes.arrayOf(PropTypes.shape(VerticalPropTypes)).isRequired,
    title: PropTypes.shape(TitleWrapperPropTypes)
};
PageSectionVertical.defaultProps = {};
PageSectionVertical.propTypes = PageSectionVerticalPropTypes;
export default PageSectionVertical;
