import React from "react";
import Img from "gatsby-image";
import PropTypes from "prop-types";
import styled from "styled-components";

const AppScreenshot = styled(Img)`
    position: ${({ isAdminFeatures }) => (isAdminFeatures ? "relative" : "absolute")};
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-size: contain;
    transition: all 0.3s;
    display: ${({ isSelected }) => (isSelected ? "block" : "none")};
    opacity: ${({ isSelected }) => (isSelected ? 1 : 0)};

    & > div,
    & > button {
        display: ${({ isAdminFeatures }) => (isAdminFeatures ? "block" : "inherit")};
    }
`;

const AppPhoneContainer = styled.div`
    margin: auto;
    position: relative;
    .gatsby-image-wrapper {
        @media (max-width: 1460px) {
            img {
                width: 100% ;
                height: 100%;
            }
        }
`;

const AppScreenContainer = styled.div`
    position: absolute;
    left: 10%;
    top: 14.2%;
    width: 80%;
    height: 64.4%;
    background-color: transparent;
    background-size: contain;
    z-index: 1;
`;

const AppPhoneImage = styled(Img)`
    width: 100%;
    display: block;
    position: relative;
    z-index: 2;
    margin: -20px 0 -20px;
`;

const Phone = ({ data, selected, isAdminFeatures = false }) => {
    const images = [
        data.iphonex_24,
        data.iphonex_immersion,
        data.iphonex_engaging,
        data.iphonex_truaccent,
        data.iphonex_tutoring,
        data.iphonex_mobile
    ];
    const phoneBackgrounds = images.map((image, index) => {
        const adjustedIndex = (index + 1).toString(); // to match numbers on data
        if (!image) return null;

        return (
            <AppScreenshot
                key={image.childImageSharp.fluid.src}
                fluid={image.childImageSharp.fluid}
                style={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                    width: "100%",
                    height: "100%"
                }}
                isSelected={selected === adjustedIndex}
                isAdminFeatures={isAdminFeatures}
            />
        );
    });

    return (
        <AppPhoneContainer>
            <AppScreenContainer>{phoneBackgrounds}</AppScreenContainer>
            <AppPhoneImage fluid={data.empty_iphone.childImageSharp.fluid} alt="" />
        </AppPhoneContainer>
    );
};

export const PhonePropTypes = {
    selected: PropTypes.string.isRequired,
    isAdminFeatures: PropTypes.bool.isRequired
};

Phone.propTypes = PhonePropTypes;

export default Phone;
