import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import ChildContentWrapper from "../../component-styles/ChildContentWrapper";

const AnimationWrapperDiv = styled.div`
    label: BodyWrapper;

    align-items: center;
    display: flex;
    flex-direction: column;

    margin: 0;
    padding: 0;

    > div {
        object-fit: contain;
        ${({ height }) => (height ? `height: ${height};` : "height: 100%;")}
        ${({ width }) => (width ? `width: ${width};` : "width: 100%;")}
    }
    > img,
    video {
        object-fit: contain;
        ${({ height }) => (height ? `height: ${height};` : "height: 100%;")}
        ${({ width }) => (width ? `width: ${width};` : "width: 100%;")}
    }
`;
const Animation = ({ children, dataQa, asset_static, height, width }) => {
    if (!asset_static) return null;
    const animation_path = asset_static;

    const fileType = animation_path.split(".").pop().toUpperCase();
    let animationMarkup;
    switch (fileType) {
        case "MP4":
            animationMarkup = (
                <video autoPlay muted loop>
                    <source src={animation_path} type="video/mp4" />
                </video>
            );
            break;
        case "GIF":
        default:
            animationMarkup = (
                <video autoPlay muted loop>
                    <source src={animation_path} type="video/mp4" />
                </video>
            );
            break;
    }
    return (
        <AnimationWrapperDiv data-qa={`animation-${dataQa}`} height={height} width={width}>
            {animationMarkup}
            {children && <ChildContentWrapper>{children}</ChildContentWrapper>}
        </AnimationWrapperDiv>
    );
};
export const AnimationPropTypes = {
    dataQa: PropTypes.string,

    asset_static: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    alt: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string
};
Animation.propTypes = AnimationPropTypes;
Animation.defaultProps = {};
export default Animation;
