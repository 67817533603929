import React from "react";
import styled from "@emotion/styled";

const QuoteWrapper = styled.div`
    label: QuoteWrapper;

    display: flex;
    flex-direction: column;
    div:nth-of-type(1) {
        font-family: effra, sans-serif;
        font-size: 16pt;
        font-style: italic;
        padding: 10px;
    }
    div:nth-of-type(2) {
        font-family: effra_bold, sans-serif;
        font-size: 12pt;
    }
`;

const QuoteBody = ({ dataQa, logo, quote }) => {
    const [quoteBody, quoteAttribution] = quote.split("--");
    if (quoteAttribution && logo) {
        return (
            <>
                <img className="quote__img" src={logo} alt={`${quoteAttribution} logo`} />
                <p data-qa={`content-section-quote-body-${dataQa}`} className="quote__body">
                    {quoteBody}
                </p>
                <p data-qa={`content-section-quote-attribution-${dataQa}`} className="quote__atrib">
                    —{quoteAttribution}
                </p>
            </>
        );
    }
    if (logo) {
        return (
            <>
                <img className="quote__img" src={logo} alt={`${quote} graphic`} />
                <p data-qa={`content-section-quote-body-${dataQa}`} className="quote__body">
                    {quoteBody}
                </p>
                <p data-qa={`content-section-quote-attribution-${dataQa}`} className="quote__atrib">
                    —{quoteAttribution}
                </p>
            </>
        );
    }
    return (
        <QuoteWrapper>
            <div data-qa={`content-section-quote-body-${dataQa}`}>{quoteBody}</div>
            <div data-qa={`content-section-quote-attribution-${dataQa}`}>—{quoteAttribution}</div>
        </QuoteWrapper>
    );
};

export default QuoteBody;
