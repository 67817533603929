import React from "react";
import styled from "@emotion/styled";
import YellowStone from "./YellowStone";
import BlueStone from "./BlueStone";
import deprecated from "../deprecated";

const ContentBackgroundContainer = styled.div`
    label: ContentBackgroundContainer;

    position: relative;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: space-between;

    * {
        box-sizing: border-box;
        //border: 1px dashed red; // XXX KILL ME
    }
`;

const ColumnContainer = styled.div`
    label: ColumnContainer;

    position: relative;
    height: 100%;
    width: 100%;
    top: 0;
    bottom: 0;
`;

const LeftColumnContainer = styled.div`
    label: LeftColumnContainer;

    position: absolute;
    max-width: 49vw;
    top: -100px;

    .content__BGyellowStone {
        left: -500px;
        height: 778px;
        width: 1118px;
        .SVGYellowFill {
            fill: #fff7dc;
        }
        &:nth-of-type(3n + 2) {
            .SVGYellowFill {
                fill: #96cae2;
            }
        }
        &:nth-of-type(2) {
            transform: rotate(242deg);
        }
        &:nth-of-type(3n + 2) {
            transform: rotate(242deg);
        }
        &:nth-of-type(4) {
            .SVGYellowFill {
                fill: #dadada;
            }
        }
        &:nth-of-type(7) {
            .SVGYellowFill {
                fill: #ecf9ff;
            }
        }
        &:nth-of-type(8) {
            .SVGYellowFill {
                fill: #fff7dc;
            }
        }
    }
`;

const RightColumnContainer = styled.div`
    label: RightColumnContainer;
    position: absolute;
    max-width: 49vw;
    top: 400px;

    .content__BGblueStone {
        right: -20%;
        height: 803px;
        width: 1248px;
        .SVGBlueFill {
            fill: #ecf9ff;
        }
        &:nth-of-type(2) {
            transform: rotate(270deg);
            .SVGBlueFill {
                fill: #dadada;
            }
        }
        &:nth-of-type(4) {
            .SVGBlueFill {
                fill: #fff7dc;
            }
        }
        &:nth-of-type(6) {
            transform: rotate(180deg);
            .SVGBlueFill {
                fill: #dadada;
            }
        }
        &:nth-of-type(7) {
            .SVGBlueFill {
                fill: #ecf9ff;
            }
        }
        &:nth-of-type(4n + 3) {
            .SVGBlueFill {
                fill: #96cae2;
            }
        }
        &:nth-of-type(3n + 2) {
            transform: rotate(-52deg);
        }
    }
`;

const ContentBackground = () => (
    <ContentBackgroundContainer>
        <ColumnContainer>
            <LeftColumnContainer>
                <YellowStone />
                <YellowStone />
                <YellowStone />
                <YellowStone />
                <YellowStone />
                <YellowStone />
                <YellowStone />
                <YellowStone />
            </LeftColumnContainer>
        </ColumnContainer>
        <ColumnContainer>
            <RightColumnContainer>
                <BlueStone />
                <BlueStone />
                <BlueStone />
                <BlueStone />
                <BlueStone />
                <BlueStone />
                <BlueStone />
                <BlueStone />
            </RightColumnContainer>
        </ColumnContainer>
    </ContentBackgroundContainer>
);

export default deprecated(ContentBackground);
