import React from "react";
import Modal from "react-modal";
import PropTypes from "prop-types";
import ThanksModal from "./ThanksModal";
import GateForm from "../ContactFormNew/GateForm";
import { domainType, verticalType } from "../../component-proptypes";

import "../../styles/button.less";
import deprecated from "../deprecated";

function findAppEL() {
    // fallback for handling storybook
    if (typeof window !== "undefined" && window.STORYBOOK_ENV) return "#root";
    return "#___gatsby";
}

/**
 * Form modal component.
 */
class Form extends React.Component {
    componentDidMount() {
        Modal.setAppElement(findAppEL());
    }

    render() {
        const {
            pricing_title,
            pricing_subtitle,
            domain,
            vertical,
            copyData,
            modalIsOpen,
            page,
            closeModal,
            afterSubmit,
            thanksModalIsOpen,
            closeThanksModal
        } = this.props;
        return (
            <>
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    contentLabel="Example Modal"
                    className="modal--scroll"
                    overlayClassName="overlay"
                >
                    <button className="modal__close" type="button" onClick={closeModal}>
                        X
                    </button>
                    <div data-qa="request-pricing-modal" className="pricing pricing__modal">
                        <h2 className="section__title pricing__title">{pricing_title}</h2>
                        <p className="pricing__subtitle">{pricing_subtitle}</p>
                        <div className="pricing__form">
                            <GateForm
                                dataQaLocation="modal"
                                domain={domain}
                                vertical={vertical}
                                page={page || "webpage"}
                                leadSourceMostRecent1="Contact Request"
                                afterSubmit={afterSubmit}
                                copyData={copyData}
                            />
                        </div>
                    </div>
                </Modal>
                <ThanksModal
                    thanksModalIsOpen={thanksModalIsOpen}
                    closeThanksModal={closeThanksModal}
                    pricing_thanks={copyData.pricing_thanks}
                />
            </>
        );
    }
}

Form.propTypes = {
    pricing_title: PropTypes.string.isRequired,
    pricing_subtitle: PropTypes.string.isRequired,
    // pricing_thanks: PropTypes.string.isRequired,
    domain: domainType.isRequired,
    vertical: verticalType.isRequired,
    copyData: PropTypes.shape({}).isRequired
};

export default deprecated(Form);
