import React from "react";
import Modal from "react-modal";
import ButtonStyled from "../../components/Buttons/ButtonStyled";
import "../../styles/modal.less";
import deprecated from "../deprecated";

function findAppEL() {
    // fallback for handling storybook
    if (typeof window !== "undefined" && window.STORYBOOK_ENV) return "#root";
    return "#___gatsby";
}

/**
 * Composable container wrapping react-modal. Takes components for the button
 * body and modal body as props. Will conditionally render a follow up form
 * modal when provided copyData, domain, & vertical.
 */
class FormFollowUpModal extends React.Component {
    constructor() {
        super();

        this.state = {
            initialModalIsOpen: false,
            formModalIsOpen: false,
            thanksModalIsOpen: false
        };
    }

    openModal = () => {
        this.setState({ initialModalIsOpen: true });
    };

    closeModal = () => {
        this.setState({
            initialModalIsOpen: false,
            formModalIsOpen: true
        });
    };

    closeFormModal = () => {
        this.setState({ formModalIsOpen: false });
    };

    afterSubmit = () => {
        const { afterSubmit } = this.props;
        if (afterSubmit) {
            afterSubmit();
        } else {
            this.setState({
                formModalIsOpen: false,
                thanksModalIsOpen: true
            });
        }
    };

    closeThanksModal = () => {
        this.setState({ thanksModalIsOpen: false });
    };

    componentDidMount() {
        Modal.setAppElement(findAppEL());
    }

    render() {
        const { initialModalIsOpen } = this.state;
        const { buttonBody, modalBody } = this.props;
        return (
            <>
                <ButtonStyled onClick={this.openModal} theme="blue_white" size="regular" text={buttonBody} />
                <Modal
                    isOpen={initialModalIsOpen}
                    onRequestClose={this.closeModal}
                    contentLabel="Example Modal"
                    className="modal"
                    overlayClassName="overlay"
                >
                    <button className="modal__close" type="button" onClick={this.closeModal}>
                        X
                    </button>
                    {modalBody}
                </Modal>
            </>
        );
    }
}

export default deprecated(FormFollowUpModal);
