/* eslint react/no-unused-state: "off" */

import React from "react";

export const AppContext = React.createContext({ hasSubmitted: false });

export function withContext(Component) {
    return function ContextComponent(props) {
        return (
            <AppContext.Consumer>
                {(context) => {
                    if (!context) {
                        throw new Error(
                            "Components consuming app context must be wrapped with the AppContext.Provider. See App Component"
                        );
                    }
                    return (
                        <Component
                            {...props}
                            hasSubmitted={context.hasSubmitted}
                            toggleSubmitContext={context.toggleSubmitContext}
                        />
                    );
                }}
            </AppContext.Consumer>
        );
    };
}

class AppProvider extends React.Component {
    constructor(props) {
        super(props);

        this.toggleSubmitContext = () => {
            this.setState({
                hasSubmitted: true
            });
        };

        this.state = {
            hasSubmitted: false,
            toggleSubmitContext: this.toggleSubmitContext
        };
    }

    render() {
        const { children } = this.props;
        return <AppContext.Provider value={this.state}>{children}</AppContext.Provider>;
    }
}

export default AppProvider;
