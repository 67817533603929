import React from "react";
import { images, logos } from "@resourcehub/resourcehub-assets";

import ContentSection from "../ContentSection/ContentSection";

import PricingSection from "../PricingSection/PricingSection";
import Features from "../Features/Features";
import RelatedContent from "../RelatedContent/RelatedContent";
import SolutionsFirst from "./SolutionsFirst";
import deprecated from "../deprecated";

const IndustrySolutions = ({ pageData, data, items, domain, vertical, copyData, page = "webpage" }) => {
    const { sol_section_2_img, sol_section_4_img, tri_screen_lockup } = data;
    return (
        <>
            <main className="main solutions">
                <SolutionsFirst
                    copyData={copyData}
                    data={data}
                    domain={domain}
                    page={page}
                    pageData={pageData}
                    vertical={vertical}
                />
                <ContentSection
                    dataQa="2"
                    body={pageData.section_2_body}
                    hasCombinedResources={copyData.has_combined_resources}
                    img={sol_section_2_img}
                    link={pageData.section_2_link}
                    logo={logos[pageData.section_2_logo]}
                    market={copyData.market}
                    quote={pageData.section_2_quote}
                    resourcesLink={copyData.resources_link}
                    sectionClasses="section__right section_2"
                    title={pageData.section_2_title}
                    vertical={vertical}
                />
                <ContentSection
                    dataQa="3"
                    body={pageData.section_3_body}
                    hasCombinedResources={copyData.has_combined_resources}
                    img_bg={images.solSection_3StoneYellowSvg}
                    img_main={tri_screen_lockup}
                    link={pageData.section_3_link}
                    logo={logos[pageData.section_3_logo]}
                    market={copyData.market}
                    quote={pageData.section_3_quote}
                    resourcesLink={copyData.resources_link}
                    sectionClasses="section__left section_3"
                    title={pageData.section_3_title}
                    vertical={vertical}
                />
                <ContentSection
                    dataQa="4"
                    body={pageData.section_4_body}
                    hasCombinedResources={copyData.has_combined_resources}
                    img={sol_section_4_img}
                    link={pageData.section_4_link}
                    logo={logos[pageData.section_4_logo]}
                    market={copyData.market}
                    quote={pageData.section_4_quote}
                    resourcesLink={copyData.resources_link}
                    sectionClasses="section__right section_4"
                    title={pageData.section_4_title}
                    vertical={vertical}
                />
                <PricingSection
                    copyData={copyData}
                    domain={domain}
                    page={page}
                    pricing_subtitle={data.copy.data.pricing_subtitle}
                    pricing_thanks={data.copy.data.pricing_thanks}
                    pricing_title={data.copy.data.pricing_title}
                    vertical={vertical}
                />
                {pageData.feature_section_title && (
                    <Features
                        data={pageData}
                        img_bg_top={images.solSection_7StoneBlueSvg}
                        img_bg_bottom={images.solSection_7StoneYellowSvg}
                    />
                )}
            </main>
            <RelatedContent
                copyData={copyData}
                items={items}
                title={copyData.keep_exploring}
                siteMetadata={data.site.siteMetadata}
                showBorders
            />
        </>
    );
};

// IndustrySolutions.propTypes = {
//     domain: domainType.isRequired,
//     vertical: verticalType.isRequired
// };

export default deprecated(IndustrySolutions);
