import React from "react";
import Img from "gatsby-image";
import { sliderServices } from "@resourcehub/resourcehub-services";
import "../../styles/speakRightCarousel.less";
import deprecated from "../deprecated";

class SpeakRightCarousel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: 0
        };
    }

    handleLClick = () => {
        const { selected } = this.state;
        const { data } = this.props;
        const amountOfSlideIndexes = sliderServices.buildSliderData(data).length - 1;

        if (selected <= 0) {
            // resetting to end of loop
            this.setState({
                selected: amountOfSlideIndexes
            });
        } else {
            // iterate backwards
            this.setState((prevState) => {
                const decremented = prevState.selected - 1;
                return { selected: decremented };
            });
        }
    };

    handleRClick = () => {
        const { selected } = this.state;
        const { data } = this.props;
        const amountOfSlideIndexes = sliderServices.buildSliderData(data).length - 1;

        if (selected >= amountOfSlideIndexes) {
            // if at the end, reset to zero to loop through forwards again
            this.setState({
                selected: 0
            });
        } else {
            // iterate forwards
            this.setState((prevState) => {
                const incremented = prevState.selected + 1;
                return { selected: incremented };
            });
        }
    };

    handleDotClick = (selected) => this.setState({ selected });

    renderSlide = (index) => {
        const { selected } = this.state;
        const { data } = this.props;
        const sliderData = sliderServices.buildSliderData(data);
        const node = sliderData[index];
        const current = index === selected ? " immersion_slide_current" : "";
        return (
            <div key={index} className={`immersion_slide${current}`}>
                <div className="immersion_slide_img_holder">
                    <Img
                        fixed={node.leftImg_src.childImageSharp.fixed}
                        fluid={node.leftImg_src.childImageSharp.fluid}
                        tabIndex="-1"
                        alt="slide"
                    />
                    {this.renderCorrect(node.leftImg_correct)}
                </div>
                <div className="immersion_slide_img_holder">
                    <Img
                        fixed={node.rightImg_src.childImageSharp.fixed}
                        fluid={node.rightImg_src.childImageSharp.fluid}
                        tabIndex="-1"
                        alt="slide"
                    />
                    {this.renderCorrect(node.rightImg_correct)}
                </div>
            </div>
        );
    };

    renderCorrect = (correct) =>
        correct ? (
            <img
                className="immersion_correct_check"
                src="https://www.rosettastone.com/lp/common-modules/assets/modules_2018/slideshow/correct.svg"
                tabIndex="-1"
                alt="correct"
            />
        ) : null;

    renderPhrase = (index) => {
        const { selected } = this.state;
        const { data } = this.props;
        const sliderData = sliderServices.buildSliderData(data);
        const current = index === selected ? " immersion_phrase_current" : "";

        return (
            <div key={index} className="immersion_slideshow_phrase_holder">
                <div className={`immersion_slideshow_phrase${current}`}>{sliderData[index].phrase}</div>
            </div>
        );
    };

    renderDot = (index) => {
        const { selected } = this.state;
        const current = index === selected ? " immersion_dot_current" : "";
        return (
            <button
                key={index}
                type="button"
                className={`immersion_slideshow_dot${current}`}
                onClick={() => this.handleDotClick(index)}
            />
        );
    };

    render() {
        const { selected } = this.state;
        const { data } = this.props;
        const sliderData = sliderServices.buildSliderData(data);
        const opacity = selected ? "1" : "0";
        const phrases = sliderData.map((item, index) => this.renderPhrase(index));
        const slides = sliderData.map((item, index) => this.renderSlide(index));
        const dots = sliderData.map((item, index) => this.renderDot(index));
        return (
            <div id="immersion_slideshow">
                <div className="immersion_slideshow_width">
                    <div id="immersion_slideshow_phrases_container">{phrases}</div>
                    <button
                        type="button"
                        className="immersion_slide_arrow js_immersion_slide_left"
                        style={{ opacity }}
                        onClick={() => this.handleLClick()}
                    >
                        <img
                            src="https://www.rosettastone.com/lp/common-modules/assets/modules_2018/slideshow/left-arrow.svg"
                            alt="move left arrow"
                        />
                    </button>
                    <div id="immersion_slideshow_img_container">{slides}</div>
                    <button
                        type="button"
                        className="immersion_slide_arrow js_immersion_slide_right"
                        onClick={() => this.handleRClick()}
                    >
                        <img
                            src="https://www.rosettastone.com/lp/common-modules/assets/modules_2018/slideshow/right-arrow.svg"
                            alt="move right arrow"
                        />
                    </button>
                    <div id="immersion_slideshow_dots_container">{dots}</div>
                </div>
            </div>
        );
    }
}

export default deprecated(SpeakRightCarousel);
