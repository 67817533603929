import React from "react";
import { images, logos } from "@resourcehub/resourcehub-assets";

import ContentSection from "../ContentSection/ContentSection";
import Features from "./Features";
import SolutionsFirst from "./SolutionsFirst";

import { domainType, verticalType } from "../../component-proptypes";

import "../../styles/home.less";
import "../../styles/solutions.less";
import deprecated from "../deprecated";

const Solutions = ({ pageData, data, domain, vertical, copyData, page = "webpage", config }) => {
    const {
        sol_section_2_img,
        sol_section_4_img,
        sol_section_5_img,
        sol_section_6_img,
        sol_section_7_img,
        tri_screen_lockup
    } = data;

    return (
        <>
            <main className="main solutions">
                <SolutionsFirst
                    config={config}
                    copyData={copyData}
                    data={data}
                    domain={domain}
                    page={page}
                    pageData={pageData}
                    vertical={vertical}
                    config={config}
                />
                <ContentSection
                    dataQa="2"
                    body={pageData.section_2_body}
                    hasCombinedResources={copyData.has_combined_resources}
                    img={sol_section_2_img}
                    link={pageData.section_2_link}
                    logo={logos[pageData.section_2_logo]}
                    market={copyData.market}
                    quote={pageData.section_2_quote}
                    resourcesLink={copyData.resources_link}
                    sectionClasses="section__right section_2"
                    title={pageData.section_2_title}
                    vertical={vertical}
                />
                <ContentSection
                    dataQa="3"
                    body={pageData.section_3_body}
                    hasCombinedResources={copyData.has_combined_resources}
                    img_bg={images.solSection_3StoneYellowSvg}
                    img_main={tri_screen_lockup}
                    link={pageData.section_3_link}
                    logo={logos[pageData.section_3_logo]}
                    market={copyData.market}
                    quote={pageData.section_3_quote}
                    resourcesLink={copyData.resources_link}
                    sectionClasses="section__left section_3"
                    title={pageData.section_3_title}
                    vertical={vertical}
                />
                <ContentSection
                    dataQa="4"
                    body={pageData.section_4_body}
                    hasCombinedResources={copyData.has_combined_resources}
                    img={sol_section_4_img}
                    link={pageData.section_4_link}
                    logo={logos[pageData.section_4_logo]}
                    market={copyData.market}
                    quote={pageData.section_4_quote}
                    resourcesLink={copyData.resources_link}
                    sectionClasses="section__right section_4"
                    title={pageData.section_4_title}
                    vertical={vertical}
                />
                <ContentSection
                    dataQa="5"
                    body={pageData.section_5_body}
                    hasCombinedResources={copyData.has_combined_resources}
                    img={sol_section_5_img}
                    link={pageData.section_5_link}
                    logo={logos[pageData.section_5_logo]}
                    market={copyData.market}
                    quote={pageData.section_5_quote}
                    resourcesLink={copyData.resources_link}
                    sectionClasses="section__right section_5"
                    title={pageData.section_5_title}
                    vertical={vertical}
                />
                <ContentSection
                    dataQa="6"
                    body={pageData.section_6_body}
                    hasCombinedResources={copyData.has_combined_resources}
                    img={sol_section_6_img}
                    link={pageData.section_6_link}
                    logo={logos[pageData.section_6_logo]}
                    market={copyData.market}
                    quote={pageData.section_6_quote}
                    resourcesLink={copyData.resources_link}
                    sectionClasses="section__right section_6"
                    title={pageData.section_6_title}
                    vertical={vertical}
                />
                <ContentSection
                    dataQa="7"
                    body={pageData.section_7_body}
                    hasCombinedResources={copyData.has_combined_resources}
                    img={sol_section_7_img}
                    link={pageData.section_7_link}
                    logo={logos[pageData.section_7_logo]}
                    market={copyData.market}
                    quote={pageData.section_7_quote}
                    resourcesLink={copyData.resources_link}
                    sectionClasses="section__right section_7"
                    title={pageData.section_7_title}
                    vertical={vertical}
                />
                {pageData.feature_section_title && (
                    <Features
                        data={pageData}
                        img_bg_top={images.solSection_7StoneBlueSvg}
                        img_bg_bottom={images.solSection_7StoneYellowSvg}
                    />
                )}
            </main>
        </>
    );
};

Solutions.propTypes = {
    domain: domainType.isRequired,
    vertical: verticalType.isRequired
};

export default deprecated(Solutions);
