import React from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import GutterWrapper from "./GutterWrapper";
import TitleWrapper, { TitleWrapperPropTypes } from "./TitleWrapper";

const PageSectionWrapperStyled = styled.section`
    label: PageSectionWrapperStyled;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    margin: 0;
    padding: 0;
    ${({ title }) => {
        if (title) {
            return `
                > div:nth-of-type(1) {
                    margin-bottom: 20px;
                }
            `;
        }
        return `
            > div:nth-of-type(1) {
                margin-bottom: 0;
            }
        `;
    }}

    ${({ pageTitle }) => (pageTitle ? "font-size: 3.125rem;" : "font-size: 2.25rem;")}
`;

const Background = styled.div`
    ${({ color }) => `background-color: ${color};`}
`;

const PageSectionWrapper = ({ backgroundColor, children, gutter, pageTitle, title }) => {
    const pageWrapper = (
        <PageSectionWrapperStyled pageTitle={pageTitle} title={title}>
            <div>{title && <TitleWrapper {...title} />}</div>
            <div>{children}</div>
        </PageSectionWrapperStyled>
    );
    if (gutter) {
        return (
            <Background color={backgroundColor}>
                <GutterWrapper gutter={gutter}>{pageWrapper}</GutterWrapper>
            </Background>
        );
    }
    return <Background color={backgroundColor}>{pageWrapper}</Background>;
};

export const PageSectionWrapperPropTypes = {
    backgroundColor: PropTypes.string,
    gutter: PropTypes.oneOf([true, false, "single", "double"]),
    pageTitle: PropTypes.bool,
    title: PropTypes.shape(TitleWrapperPropTypes)
};
PageSectionWrapper.propTypes = PageSectionWrapperPropTypes;
PageSectionWrapper.defaultProps = {
    gutter: false,
    pageTitle: false,
    title: null
};
export default PageSectionWrapper;
