import React from "react";
import Img from "gatsby-image";
import styled from "styled-components";
import PropTypes from "prop-types";

const VideoContainer = styled.div`
    width: 100%;
    height: 100%;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const VideoImg = styled(Img)`
    width: 100%;
    box-shadow: 0 3px 10px 0 rgba(33, 33, 33, 0.5);

    @media (max-width: 1024px) {
        max-width: 628px;
    }
`;

const AppScreenshot = styled.div`
    position: relative;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-size: contain;
    display: ${({ isSelected }) => (isSelected ? "block" : "none")};
    opacity: ${({ isSelected }) => (isSelected ? 1 : 0)};
    transition: all 0.3s;

    & > div,
    & > button {
        display: ${({ isHomePage }) => (isHomePage ? "none" : "block")};
    }
`;

const AdminImage = ({ selected, data = false }) => {
    const {
        screen_assessment,
        screen_implementation,
        screen_placement,
        screen_reporting,
        screen_testing,
        screen_training
    } = data;
    const rawEls = [
        <div key={1}>
            <VideoContainer>
                <VideoImg fluid={screen_placement.childImageSharp.fluid} alt="Personalized Placement" />
            </VideoContainer>
        </div>,
        <div key={2}>
            <VideoContainer>
                <VideoImg fluid={screen_assessment.childImageSharp.fluid} alt="Personalized Assessment" />
            </VideoContainer>
        </div>,
        <div key={3}>
            <VideoContainer>
                <VideoImg fluid={screen_testing.childImageSharp.fluid} alt="English Proficiency Testing" />
            </VideoContainer>
        </div>,
        <div key={4}>
            <VideoContainer>
                <VideoImg fluid={screen_reporting.childImageSharp.fluid} alt="Personalized Reporting" />
            </VideoContainer>
        </div>,
        <div key={1}>
            <VideoContainer>
                <VideoImg fluid={screen_implementation.childImageSharp.fluid} alt="Personalized Implementation" />
            </VideoContainer>
        </div>,
        <div key={6}>
            <VideoContainer>
                <VideoImg fluid={screen_training.childImageSharp.fluid} alt="Personalized Placement" />
            </VideoContainer>
        </div>
    ];
    const els = rawEls.map((el, index) => {
        const adjustedIndex = (index + 1).toString(); // to match numbers on data
        const key = `${index}-key`;
        return (
            <AppScreenshot isSelected={selected === adjustedIndex} key={key}>
                {el}
            </AppScreenshot>
        );
    });
    return <div className="">{els}</div>;
};

const AdminImagePropTypes = {
    isHomePage: PropTypes.bool.isRequired,
    img_bg: PropTypes.string.isRequired,
    selected: PropTypes.string.isRequired,
    data: PropTypes.objectOf(
        PropTypes.shape({
            screen_assessment: PropTypes.object,
            screen_implementation: PropTypes.object,
            screen_placement: PropTypes.object,
            screen_reporting: PropTypes.object,
            screen_testing: PropTypes.object,
            screen_training: PropTypes.object
        })
    )
};

AdminImage.propTypes = AdminImagePropTypes;

export default AdminImage;
