import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import styled from "@emotion/styled";

import SecondaryNavLink from "./SecondaryNavLink";

const SolutionsWrapperDiv = styled.div`
    label: SolutionsWrapperDiv;

    height: 38px;
`;

const DropdownIcon = styled.div`
    label: DropdownIcon;

    content: "";
    display: inline-block;
    border: 4px solid transparent;
    border-top: 5px solid #262626;
    border-bottom: none;
    margin-left: 4px;
    transition: transform 0.3s;
    transform: ${(props) => (props.isOpen ? "rotate(180deg)" : "rotate(0deg)")};
`;

const SolutionsDropdownDiv = styled.div`
    label: SolutionsDropdownDiv;

    // show
    visibility: ${(props) => (props.isOpen ? "visible" : "hidden")};
    a {
        font-family: effra, sans-serif;
        font-size: 14px;
        letter-spacing: 0.4px;
        color: #262626;

        background-color: #f9f9f9;
        padding: 8px 26px;
        transition: all 0.2s;
        text-decoration: none;
        text-align: center;
        display: block;
        // white-space: nowrap;
        :last-child {
            border-bottom-right-radius: 6px;
            border-bottom-left-radius: 6px;
        }
        :hover,
        :active,
        :focus {
            text-decoration: none;
            background-color: #e5e5e5;
        }
    }
`;

class Solutions extends React.PureComponent {
    state = {
        isOpen: false,
        dropdownName: "Solutions"
    };

    togglePane = () => {
        const { isOpen } = this.state;
        if (isOpen) {
            this.closePane();
        } else {
            this.openPane();
        }
    };

    openPane = () => {
        this.setState({
            isOpen: true
        });
    };

    closePane = () => {
        this.setState({
            isOpen: false
        });
    };

    render() {
        const { dataQa, children, destination, external, label, new_window, options } = this.props;
        const { isOpen } = this.state;
        if (options) {
            return (
                <SolutionsWrapperDiv
                    onBlur={this.closePane}
                    onClick={this.togglePane}
                    onFocus={this.openPane}
                    onMouseOut={this.closePane}
                    onMouseOver={this.openPane}
                >
                    <SecondaryNavLink data-qa={dataQa}>
                        {label} {children}
                        <DropdownIcon data-qa={`${dataQa}-dropdown-icon`} isOpen={isOpen} />
                    </SecondaryNavLink>
                    <SolutionsDropdownDiv isOpen={isOpen}>
                        {options.map((solution, idx) => {
                            const key = `link-${idx + 1}`;
                            return (
                                <Link
                                    data-qa={`${dataQa}-solution-${idx}`}
                                    to={solution.link}
                                    key={key}
                                    title={solution.title}
                                >
                                    {solution.label}
                                </Link>
                            );
                        })}
                    </SolutionsDropdownDiv>
                </SolutionsWrapperDiv>
            );
        }
        return (
            <SecondaryNavLink dataQa={dataQa} destination={destination} external={external} new_window={new_window}>
                {label} {children}
            </SecondaryNavLink>
        );
    }
}

Solutions.propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    dataQa: PropTypes.string,
    destination: PropTypes.string,
    external: PropTypes.bool,
    label: PropTypes.string,
    new_window: PropTypes.bool,
    options: PropTypes.array
};

Solutions.defaultProps = {
    dataQa: null,
    destination: null,
    external: false,
    new_window: false
};

export default Solutions;
