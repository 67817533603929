import React from "react";
import FormModalCTA from "../Modal/FormModalCTA";
import "../../styles/cta.less";
import deprecated from "../deprecated";

const CallToActionBar = ({ copyData, domain, vertical, page = "webpage" }) => {
    const { cta_h2, cta_text } = copyData;

    return (
        <section className="cta">
            <h2>{cta_h2}</h2>
            <FormModalCTA
                dataQa="call-to-action-bar-request-pricing-button"
                btn_classes="btn btn-bg"
                btn_title={cta_text}
                pricing_title={copyData.pricing_title}
                pricing_subtitle={copyData.pricing_subtitle}
                domain={domain}
                vertical={vertical}
                copyData={copyData}
                page={page}
            />
        </section>
    );
};

export default deprecated(CallToActionBar);
